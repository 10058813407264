import React, { useState, useEffect } from "react";
import { Pagination,FormControl,InputLabel,Select,MenuItem,TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VuiBox from "components/VuiBox";
import { Link } from 'react-router-dom';
import Table from "examples/Tables/Table";
import Swal from "sweetalert2";

// This only needs to be imported once in your app
import ReactImageLightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app


import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import { Edit, Delete } from "@mui/icons-material";

import { postRequest,deleteRequest } from "../../../lib/utils";

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
});

import authorsTableData from "layouts/followers/data/authorsTableData";

import VuiBadge from "components/VuiBadge";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";

const columnTbl =  [
  { name: "title", align: "left" },
  { name: "description", align: "left" },
  { name: "type", align: "left" },
  { name: "discount", align: "center" },
  { name: "pricePerBrunch", align: "center" },
  { name: "createdAt", align: "center" },  
  { name: "action", align: "center" },
];




 
function SpecialOffer() {
  const { columns, rows } = authorsTableData; 
  const [images, setImages] = useState([]);
  const [venueId, setVenueId] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const [businessData, setBusinessData] = useState(null);  
  const [offers, setOffers] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const rowsPerPage = 10;
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const visibleRows = offers.slice(startIndex, endIndex);
  const [sortOrder, setSortOrder] = useState("desc");
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const fetchOffers = async (venueId = null, searchTerm = "") => {

    if(!venueId) {
      venueId = localStorage.getItem("venueId");  
    }

    const payload = {
      limit: 10000000,
      page: 1,
      venueId: venueId,
      sortField: "createdAt", 
      sortOrder: sortOrder,
      search: searchTerm,
    };  
    const response = await postRequest(`venue/business/venue-spl-offer`, payload);
    const data = response.data; 
    let imgs = [];
    let visibleRows = data.list.slice(startIndex, endIndex);

    visibleRows?.map((row) => {
      imgs.push(row.image);
    });    
    setImages(imgs);
   
    setOffers(data.list);    
  };

  
  useEffect(() => {
    const authUser = localStorage.getItem("authUser");  
    const venueId = localStorage.getItem("venueId");         
    if (authUser) {
      const bData = JSON.parse(authUser);      
      fetchOffers(venueId)
    }
  }, [sortOrder]);

  

  // filter logic


  const handlePageSizeChange = (event) => {
    let value = event.target.value;
    setPageSize(value);
    // You can do more here if needed   
  };



  const handleSortOrderChange = (event) => {
    let value = event.target.value;
    setSortOrder(value);    
    // You can do more here if needed
  };

  useEffect(() => {
    fetchOffers(); // You can do more here if needed
  }, [sortOrder]); 

  // searching

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    let value = event.target.value;    
    // You can do more he
    setSearchTerm(value);
  };



  const Resetvalue = ()=>{
    setPageSize(10) 
    setSearchTerm("")

    setTimeout(() => {
      setSortOrder("asc")
    }, 1000);
   
  }
  const deleteFunction = async (id) => {
    const response = await deleteRequest("venue/business/my-special-offer-delete", { specialOfferId: id });
    if (response.status) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          // User clicked "Yes"
          // Perform the actual delete action


          fetchOffers();   
          Swal.fire("Deleted!", "Your file has been deleted.", "success");




        } else {
          // User clicked "No" or closed the dialog
          Swal.fire("Cancelled", "Your file is safe :)", "info");
        }
      });
    } else {
      Swal.fire("Error!", "Something went wrong", "error");
    }
  };
  

  function HumanReadableDate({ isoDateString }) {
    const date = new Date(isoDateString);
    const humanReadableDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  
    return <div>{humanReadableDate}</div>;
  }

  

  function openPhoto(index) {
    setPhotoIndex(index);
    setIsOpen(true);
  }

  const tableRow = (row, index) => {
    return (
      {
        title: (
          <VuiTypography variant="button" color="white" fontWeight="medium">
            {row.title}
            </VuiTypography> 
        ),
        
        description: (
          <VuiTypography variant="caption" color="white" fontWeight="medium">
            {row.description.length <= 25 ? row.description : `${row.description.substring(0, 25)}...`}
          </VuiTypography>
        ),
        type: (
          <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {row.type}
          </VuiTypography>
        ),
        discount: (
          <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">            
          {row.discount}
          </VuiTypography>
        ),
        pricePerBrunch: (
          <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">            
          {row.pricePerPerson}
          </VuiTypography>
        ),
        createdAt: (
          <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
           <HumanReadableDate isoDateString={row.createdAt}/>
          </VuiTypography>
        ),
        action: (
          <>

              <Link to={{
                pathname: `/update-specialOffer`,
                state: { row },
              }}><Edit sx={{color:"#0075FF", fontSize: "18px", marginRight: "8px"}} fontSize="medium" ></Edit></Link>           
         
          <Delete sx={{color:"#DB2C56",  fontSize: "18px"}} fontSize="medium"  onClick={ () =>  deleteFunction(row._id)}/> {/* Delete icon */}</>
        )
      }
    );
  };
 
  const fetchTable = (rows) => {
    const rowsPerPage = pageSize;
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const visibleRows = rows?.slice(startIndex, endIndex);

    let imgs = [];

    visibleRows?.map((row) => {
      imgs.push(row.image);
    });    
    //setImages(imgs);

    
    return visibleRows?.map((row, index) => tableRow(row, index)); 
  };


  useEffect(() => {
    if(!offers?.length) return;
  }, [offers]);
  const formElementStyle = {
    borderRadius: "14px", // Border radius for form elements
    color: "#FFFFFF", // Text color for the form elements
    "& .MuiOutlinedInput-root": {
      // Targeting the input root of TextField and Select
      borderRadius: "14px", // Border radius for TextField and Select components
      color: "#FFFFFF", // Text color for the input content
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.23)", // Border color
      },
      "&.Mui-focused fieldset": {
        borderColor: "white", // Border color on focus
      },
    },
    "& .MuiInputLabel-root": {
      // Targeting the label of the TextField and Select
      color: "rgba(255, 255, 255, 0.7)", // Label color
    },
    "& .MuiInputLabel-root.Mui-focused": {
      // Label color when the input is focused
      color: "#FFFFFF", // Focused label color
    },
    "& .MuiSelect-select": {
      // Targeting the select input
      color: "#FFFFFF", // Text color for the select input
    },

    // Apply similar styles for Checkbox, Radio, and other components as needed
  };

  return (   
                <>
                  <VuiBox
                    sx={{
                      "& th": {
                        borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                          `${borderWidth[1]} solid ${grey[700]}`,
                      },
                      "& .MuiTableRow-root:not(:last-child)": {
                        "& td": {
                          borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                            `${borderWidth[1]} solid ${grey[700]}`,
                        },
                      },
                      
                    }}
                  >
                        <ThemeProvider theme={theme}>
          <div className="searching-form">
            <div>
              <FormControl className="page_size" sx={formElementStyle}>
                <InputLabel id="demo-simple-select-label">Page Size</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  fullWidth
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>

              <FormControl className="page_size" sx={formElementStyle}>
                <InputLabel id="demo-simple-select-label">Sort Order:</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  fullWidth
                  value={sortOrder}
                  onChange={handleSortOrderChange}
                >
                  <MenuItem value={"asc"}>Ascending</MenuItem>
                  <MenuItem value={"desc"}>Descending</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="d-flex flex-wrap">
              <FormControl className="page_size" sx={formElementStyle}>
                <TextField
                  label="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
              <div className="reset-card">
                <button className="searchbtn " style={{ width: '600px' }} onClick={() =>  fetchOffers(venueId, searchTerm) }> Search</button>
                <button className="searchbtn reset-btn mx-2"  style={{ width: '600px' }} onClick={()=>Resetvalue()}> Reset</button>                
                <Link to="/create-specialOffer"><button className="searchbtn"  style={{ width: '110px' }}>
                    Add Discount
                  </button>
                </Link>
              
              </div>
            </div>
          </div>
        </ThemeProvider>
                    <Table columns={columnTbl} rows={ offers ? fetchTable(offers) : [] } />                  
                  </VuiBox>
                  <div style={{ alignSelf: "flex-end", color: "white", margin: "7px 10px 0px auto" }}>
                    <ThemeProvider theme={theme}>
                      <Pagination
                        count={Math.ceil(offers.length / rowsPerPage)}
                        color="primary"
                        page={currentPage}
                        onChange={handlePageChange}
                      />
                    </ThemeProvider>
                  </div>

                  
                {isOpen && (
                  <ReactImageLightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex((photoIndex + 1) % images.length)
                    }
                  />
                )}
                </>    
  );
}

export default SpecialOffer;

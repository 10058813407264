import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  TextField,
  Box,
  Button,
  createTheme,
  ThemeProvider,  
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery  
} from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

const formElementStyle = {
  borderRadius: "14px", // Border radius for form elements
  color: "#FFFFFF", // Text color for the form elements
  "& .MuiOutlinedInput-root": {
    // Targeting the input root of TextField and Select
    borderRadius: "14px", // Border radius for TextField and Select components
    color: "#FFFFFF", // Text color for the input content
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.23)", // Border color
    },
    "&.Mui-focused fieldset": {
      borderColor: "white", // Border color on focus
    },
  },
  "& .MuiInputLabel-root": {
    // Targeting the label of the TextField and Select
    color: "rgba(255, 255, 255, 0.7)", // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    // Label color when the input is focused
    color: "#FFFFFF", // Focused label color
  },
  "& .MuiSelect-select": {
    // Targeting the select input
    color: "#FFFFFF", // Text color for the select input
  },

  // Apply similar styles for Checkbox, Radio, and other components as needed
};






function VenueCreateForm({onSubmit,updateValue,venueData}) {

  const isDesktop = useMediaQuery("(min-width:600px)");
  


  // Check all fields are filled


  


  const checkAllFieldsFilled = () => {
   

    if (!venueData?.name) {

      // scroll to top
      window.scrollTo(0, 0);
      toast.error("Please enter name");
      return false;
    }

    // check phoneCOde is valid

    if (!venueData?.phoneCode) {
      window.scrollTo(0, 0);
      toast.error("Please enter phone code");
      return false;
    }

    if (!venueData?.phone) {
      window.scrollTo(0, 0);
      toast.error("Please enter phone");
      return false;
    }

    // const phoneRegex = /^(\+\d{1,3}[\s-]?)?\d{10}$/;
    // if (!phoneRegex.test(`${venueData.phoneCode} ${venueData.phone}`)) {
    //   window.scrollTo(0, 0);
    //   toast.error("Please enter a valid phone number");
    //   return;
    // }

    const phoneRegex = /^\d{6,10}$/;
    if (!phoneRegex.test(venueData.phone)) {
      window.scrollTo(0, 0);
      toast.error("Please enter a valid phone number with 6 to 10 digits");
      return;
    }
    


    if (!venueData?.address) {
      window.scrollTo(0, 0);
      toast.error("Please enter address");
      return false;
    }
    if (!venueData?.about) {
      window.scrollTo(0, 0);
      toast.error("Please enter about");
      return false;
    }
    if(venueData?.email){
      const emailRegex = /\S+@\S+\.\S+/;
      if (!emailRegex.test(venueData.email)) {
        window.scrollTo(0, 0);
        toast.error("Please enter a valid email address");
        return false;
      }
    }    
    onSubmit();
  }; 


  return (
    <ThemeProvider theme={theme}>      
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "grid",
            gridTemplateColumns: isDesktop ? "1fr 1fr" : "1fr",
            gap: 2,
            "& .MuiFormControl-root": {
              m: 1,
              ...formElementStyle, // Applying the custom styles to each form control
            },
          }}
        >          

          <TextField
            label="Name"
            value={venueData?.name}
            onChange={(e) => updateValue("name", e.target.value)}
            name="name"
            variant="outlined"
            fullWidth
          />

          <TextField
            label="Email"
            value={venueData?.email}
            onChange={(e) => updateValue("email", e.target.value)}
            name="email"
            variant="outlined"
            fullWidth
          />

          <TextField
            label="Phone Country Code"
            onChange={(e) => updateValue("phoneCode", e.target.value)}
            name="phoneCode"
            value={venueData?.phoneCode}
            variant="outlined"
            fullWidth
          />

          <TextField
            label="Phone"
            onChange={(e) => updateValue("phone", e.target.value)}
            value={venueData?.phone}
            name="phone"
            variant="outlined"
            fullWidth
          />

          <TextField
            label="Address"
            variant="outlined"
            value={venueData?.address}
            fullWidth
            multiline
            onChange={(e) => updateValue("address", e.target.value)}
            rows={4} // You can set the number of rows to suit your design needs
          />

          <TextField
            label="About"
            variant="outlined"
            value={venueData?.about}
            fullWidth
            multiline
            onChange={(e) => updateValue("about", e.target.value)}
            name="about"
            rows={4} // You can set the number of rows to suit your design needs
          />
        
          <TextField
            label="Website"
            value={venueData?.website}
            onChange={(e) => updateValue("website", e.target.value)}
            name="website"
            variant="outlined"
            fullWidth
          />
          <TextField
            label="Booking Url"
            value={venueData?.booking_url}
            onChange={(e) => updateValue("booking_url", e.target.value)}
            name="booking_url"
            variant="outlined"
            fullWidth
          />
          <TextField
            label="Menu Url"
            value={venueData?.menu_url}
            onChange={(e) => updateValue("menu_url", e.target.value)}
            name="menu_url"
            variant="outlined"
            fullWidth
          />

          <TextField
            label=" Dress Code"
            value={venueData?.dress_code}
            name="dress_code"
            onChange={(e) => updateValue("dress_code", e.target.value)}
            variant="outlined"
            fullWidth
          />

          <TextField
            type="number"
            label="Claim Code"
            value={venueData?.claimCode}
            name="claimCode"
            onChange={(e) => {
              const inputClaimCode = e.target.value;
              // Check if the input is a numeric 4-digit number or empty
              if (/^\d{0,4}$/.test(inputClaimCode)) {
                // If it's a 4-digit number or empty, update the value
                updateValue("claimCode", inputClaimCode);
              }
            }}
            variant="outlined"
            fullWidth
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              maxLength: 4,
            }}
          />

          <TextField
            label=" Discount Tag"
            value={venueData?.discountText}
            name="discountText"
            onChange={(e) => updateValue("discountText", e.target.value)}
            variant="outlined"
            fullWidth
          /> 

          <FormControl fullWidth>
            <InputLabel>Allow Reviews</InputLabel>
            <Select
              labelId="category-label"
              onChange={(e) => updateValue("isAllowReview", e.target.value)}
              label="Select Category"
              value={venueData?.isAllowReview}
              name="isAllowReview"
              fullWidth
            >
              <MenuItem value="">Allow Review</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Allow Ratings</InputLabel>
            <Select
              labelId="category-label"
              label="Select Category"
              name="isAllowRatting"
              value={venueData?.isAllowRatting}
              fullWidth
              onChange={(e) => updateValue("isAllowRatting", e.target.value)}
            >
              <MenuItem>Allow Ratings</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl> 
        </Box>     
        
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "flex-end"          
          }}
        >
          <Button
            type="button"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
              borderRadius:"10px"        
            }}
            fullWidth
            onClick={(e) => checkAllFieldsFilled()}           
          >
            Next
          </Button>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default VenueCreateForm;

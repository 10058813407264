
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Card, LinearProgress, Stack } from "@mui/material";

import { postRequest, deleteRequest } from "../../lib/utils";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import backImage from "assets/images/backimage.png";
import Welcome from "./components/Welcome";
import Header from "./components/Header";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import linearGradient from "assets/theme/functions/linearGradient";

// Vision UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// Dashboard layout components
import WelcomeMark from "layouts/dashboard/components/WelcomeMark";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";
import SatisfactionRate from "layouts/dashboard/components/SatisfactionRate";
import ReferralTracking from "layouts/dashboard/components/ReferralTracking";
import Header2 from "./components/Header2";


// React icons
import { IoIosRocket } from "react-icons/io";
import { IoGlobe } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoWallet } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";

// Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import BarChart from "examples/Charts/BarCharts/BarChart";
import { lineChartDataDashboard } from "layouts/dashboard/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/dashboard/data/lineChartOptions";
import { barChartDataDashboard } from "layouts/dashboard/data/barChartData";
import { barChartOptionsDashboard } from "layouts/dashboard/data/barChartOptions";
import "assets/css/main.css";
import Hotelinfo from "./components/hotelinfo";
import { NavItem } from "reactstrap";
import Swal from "sweetalert2";
function Dashboard() {
  let history = useHistory();
  const [businessData, setBusinessData] = useState(null);  
  const [venue, setVenue] = useState([]);
  const [images, setImages] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [displayCount, setDisplayCount] = useState(8);

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    
    if (authUser) {
      const bData = JSON.parse(authUser);

      const venueLogo =  localStorage.getItem("venueLogo");
      const venueName =  localStorage.getItem("venueName");

      
      setBusinessData({
        name: venueName,
        logo: venueLogo        
      });
    }
  }, []);

  useEffect(() => {   
    const venueId = localStorage.getItem("venueId");
    fetchVenue(venueId);    
  }, []);

  const openSlideShow = (index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const fetchVenue = async (venueId) => {

    if(!venueId){
      var venueId = localStorage.getItem("venueId");
    }

    const payload = {     
      venueId: venueId     
    };

    const response = await postRequest(`venue/business/my-venue-detail`, payload);
    let data = response.data;  
    data = data?.venue ? data?.venue : {};  
    const galleryList = data?.galleries ? data?.galleries : [];   
    setImages(galleryList);     
  }


  const { gradients } = colors;
  const { cardContent } = gradients;

  const venue1 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1698398493097salmon_guru_cover.jpg";
  const venue2 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344268462file-x5klq41c73fewjhf.jpg";
  const venue3 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344512535file-wffm0qfttkbexv2w.jpg";
  const venue4 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344397552file-nm3hzzfy2lnfhxdl.jpg";
  const venuename1 = "Salmon Guru";
  const venuename2 = "Sparkle";
  const venuename3 = "Sky Dive";
  const venuename4 = "Street Division";
  const venueaddress1 =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";

  

  const deleteGallery = async img  => {

    const middleOfPage = document.documentElement.scrollHeight / 2 - window.innerHeight / 2;

    // Scroll to the middle of the page
    window.scrollTo({
      top: middleOfPage,
      behavior: 'smooth', // Smooth scroll
    });

    // Wait a bit for the scrolling to finish, then open SweetAlert
    setTimeout(async() => {
      await deleteGallerySwal(img);
    }, 500); // Adjust the delay as needed

  }
  
  const deleteGallerySwal = async img  => {    // swal confirm

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this image!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',

    }).then((result) => {
      if (result.isConfirmed) {
        deleteGalleryImage(img);
      }
    }
    )
  }


  const deleteGalleryImage = async img => {
    const vnueId = localStorage.getItem("venueId");
    const payload = {
      file: img,
      venueId: vnueId
    };
    const response = await deleteRequest(`venue/gallery/delete`, payload);
    if(response?.status ===  1){
      fetchVenue(vnueId);      
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: response?.message || 'Something went wrong!',
      })
    }      
  };    

  const buttonStyle = {
    display: 'block',
    margin: '0 auto', // Center-align the button horizontally
    padding: '10px 20px',
    backgroundColor: '#d63384', // Customize button background color
    color: 'white', // Customize button text color
    border: 'none',
    borderRadius: '10px',
    marginTop: '20px', // Margin at the top
    cursor: 'pointer',
  };

  const loadMoreImages = () => {
    // Increase the number of images to display by 4 when the button is clicked
    setDisplayCount(displayCount + 4);
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        {/*  */}
        {/* <Header venueCount={venue?.length || 0} /> */}
        <Header2  name={businessData?.name || "Whosin User"} email={businessData?.email || "test@mail.com"} logo={businessData?.logo || ""}/>
        <br></br>
        <VuiBox mb={3}>
          {/* satic venue data */}
         

          {/* <Grid container spacing={3}>
            {images?.map((item, index) => (
              <Grid
                item
                xs={12}
                md={6}
                xl={3}
                sx={({ breakpoints }) => ({
                  minHeight: "400px",
                })}
              >
                <Welcome img={item} index={index} title={""} desc={""} id={item} onSelect={(id) => deleteGallery(id)} onSlide={ (index) =>  openSlideShow(index) }  />
              </Grid>
            ))}

           
          </Grid> */}
          <Grid container spacing={3}>
            {images?.slice(0, displayCount).map((item, index) => (
              <Grid
                item
                xs={12}
                md={6}
                xl={3}
                sx={({ breakpoints }) => ({
                  minHeight: "400px",
                })}
              >
                <Welcome img={item} index={index} title={""} desc={""} id={item} onSelect={(id) => deleteGallery(id)} onSlide={ (index) =>  openSlideShow(index) }  />
              </Grid>
            ))}

           
          </Grid>

          {displayCount < venue?.length && (
        <button style={buttonStyle} onClick={loadMoreImages}>Load More</button>
      )}
        </VuiBox>

       
        
      </VuiBox>
      <Footer />

      {isOpen && (
      <Lightbox
        mainSrc={images[photoIndex]}
        nextSrc={images[(photoIndex + 1) % images.length]}
        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        onCloseRequest={() => setIsOpen(false)}
        onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
      />
    )}


    </DashboardLayout>

  );
}

export default Dashboard;

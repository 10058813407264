import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import VuiBox from "components/VuiBox";

import LineChart from "examples/Charts/LineCharts/LineChart";
import BarChart from "examples/Charts/BarCharts/BarChart";
import { lineChartDataDashboard } from "layouts/dashboard/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/dashboard/data/lineChartOptions";

import { postRequest } from "../../../lib/utils";


const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
    },
    mode: 'dark',
  },
});

const options = {
  // Customize chart options here
  maintainAspectRatio: false,
};

const staticChartData = [
  {
    name: "Total Views",
    data: [500, 250, 300, 220, 500, 250, 300, 230, 300, 350, 250, 400],
  } 
];

const staticChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "datetime",
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    labels: {
      style: {
        colors: "#c8cfca",
        fontSize: "10px",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#c8cfca",
        fontSize: "10px",
      },
    },
  },
  legend: {
    show: false,
  },
  grid: {
    strokeDashArray: 5,
    borderColor: "#56577A",
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "vertical",
      shadeIntensity: 0,
      gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0,
      stops: [],
    },
    colors: ["#0075FF", "#2CD9FF"],
  },
  colors: ["#0075FF", "#2CD9FF"],
};




import authorsTableData from "layouts/followers/data/authorsTableData";
 
function notificationView() {
  const muiTheme = useTheme();
  const { columns, rows } = authorsTableData; 
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const visibleRows = rows.slice(startIndex, endIndex);

  const [chartData, setChartData] = useState(staticChartData);
  const [chartOptions, setChartOptions] = useState(staticChartOptions);
  const [apiLoaded, setApiLoaded] = useState(false);


  useEffect(() => {
    fetchChartData();
  }, []);

  const fetchChartData = async () => {


    const venueId =  localStorage.getItem("venueId");

    const response = await postRequest("user/notification/venue/analytics", { "venueId": venueId });
    
    const tempChartOptions = { ...chartOptions };
    const tempChartData = [...chartData];

    tempChartOptions.xaxis.categories = response.labels;

    tempChartData[0].data = response?.pageViewsData;    

    setChartOptions(tempChartOptions);
    setChartData(tempChartData);
    setApiLoaded(true);
  }


  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [65, 59, 80, 81, 56, 55],
        backgroundColor: muiTheme.palette.primary.main,
      },
      // Include more datasets if needed
    ],
  };




  return (   
                <>
                  <VuiBox
                    sx={{
                      "& th": {
                        borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                          `${borderWidth[1]} solid ${grey[700]}`,
                      },
                      "& .MuiTableRow-root:not(:last-child)": {
                        "& td": {
                          borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                            `${borderWidth[1]} solid ${grey[700]}`,
                        },
                      },

                      height: "600px"
                      
                    }}
                  >

                    {apiLoaded && ( <LineChart
                      lineChartData={chartData}
                      lineChartOptions={chartOptions}
                    />)}


                  
                    
                  </VuiBox>                
                </>    
  );
}
export default notificationView;

import React, { useState, useEffect } from "react";
import { Pagination } from "@mui/material";

import {
  Container,
  TextField,
  TextareaAutosize,
  Box,
  Button,
  createTheme,
  ThemeProvider,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  Modal,
  Typography,
  IconButton,
} from "@mui/material";
import "assets/css/main.css";
import { makeStyles } from "@mui/styles";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import { Edit, Delete } from "@mui/icons-material";

import Table from "examples/Tables/Table";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import Swal from "sweetalert2";
import VuiBadge from "components/VuiBadge";
import Rating from '@mui/material/Rating';

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});
import authorsTableData from "layouts/followers/data/authorsTableData";
import { postRequest,deleteRequest } from "lib/utils";

function Review() {
  // const { columns, rows } = authorsTableData;
  const [currentPage, setCurrentPage] = useState(1);
  
  const [sortOrder, setSortOrder] = useState("asc");
  const [pageSize, setPageSize] = useState(10);
  
  const [searchTerm, setSearchTerm] = useState("");
  const rowsPerPage = 10;

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  // const visibleRows = rows.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  function HumanReadableDate({ isoDateString }) {
    const date = new Date(isoDateString);
    const humanReadableDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    return <div>{humanReadableDate}</div>;
  }
  const [review, setreview] = useState([]);

  const offerlistreview = async (searchTerm) => {
    let venueId = localStorage.getItem("venueId");
    // const payload = {
    //   venueId,
    //   type: "review",
    // };

    const payload = {
      limit: 10000000,
      page: 1,
      venueId: venueId,
      sortField: "createdAt", 
      sortOrder: sortOrder,
      search: searchTerm,
      type: "review",
    }; 

    const response = await postRequest(`venue/business/venue-audience`, payload);
    const data = response.data;
    let list = data.data.list;    
    setreview(list);
  };

  
  useEffect(() => {
    offerlistreview();
   
  }, [sortOrder]);
  // filter logic


  const handlePageSizeChange = (event) => {
    let value = event.target.value;
    setPageSize(value);  
  };


  const handleSortOrderChange = (event) => {
    let value = event.target.value;
    setSortOrder(value);    
  };

  // searching


  const handleSearchChange = (event) => {
    let value = event.target.value;    
    setSearchTerm(value);
  };






  const deleteFunction = async (id) => {
    const response = await deleteRequest("venue/business/my-review-delete", { id: id });
    if (response.status) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
      }).then((result) => {
        if (result.isConfirmed) {  
          offerlistreview();   
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        } else {          
          Swal.fire("Cancelled", "Your file is safe :)", "info");
        }
      });
    } else {
      Swal.fire("Error!", "Something went wrong", "error");
    }
  };

  let columns = [
    { name: "userName", align: "left" },
     { name: "review", align: "left" },
    { name: "stars", align: "center" },
    { name: "createdAt", align: "center" },
    { name: "action", align: "center" },
  ];

  function Author({ image, name, review }) {
    return (
      <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
        <VuiBox mr={2}>
          <VuiAvatar src={image} alt={name} size="sm" variant="rounded" />
        </VuiBox>
        <VuiBox display="flex" flexDirection="column">
          <VuiTypography variant="button" color="white" fontWeight="medium">
            {name}
          </VuiTypography>
          <VuiTypography variant="caption" color="text">
            {review}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    );
  }

  let reviewdata = review.map((item) => {
    return {
      userName: <Author image={item.userImage} name={item.userName} />,
      review: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {item.review}
          </VuiTypography> 
      ),
    
      stars: (
        <Rating
          name="stars"
          value={item.stars}
          readOnly
          precision={0.5} // Adjust precision as needed
          size="small" // Adjust size as needed
        />
      ),
      createdAt: (
        <VuiTypography  component="a" href="#" variant="caption" color="text" fontWeight="medium">
          <HumanReadableDate isoDateString={item.createdAt} />
        </VuiTypography>
      ),

   
      action: (
        <>
          <Delete sx={{color:"#DB2C56",  fontSize: "18px"}} fontSize="medium"  onClick={ () =>  deleteFunction(item._id)}/> {/* Delete icon */}</>
      ),
    };
  });


  const formElementStyle = {
    borderRadius: "14px", // Border radius for form elements
    color: "#FFFFFF", // Text color for the form elements
    "& .MuiOutlinedInput-root": {
      // Targeting the input root of TextField and Select
      borderRadius: "14px", // Border radius for TextField and Select components
      color: "#FFFFFF", // Text color for the input content
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.23)", // Border color
      },
      "&.Mui-focused fieldset": {
        borderColor: "white", // Border color on focus
      },
    },
    "& .MuiInputLabel-root": {
      // Targeting the label of the TextField and Select
      color: "rgba(255, 255, 255, 0.7)", // Label color
    },
    "& .MuiInputLabel-root.Mui-focused": {
      // Label color when the input is focused
      color: "#FFFFFF", // Focused label color
    },
    "& .MuiSelect-select": {
      // Targeting the select input
      color: "#FFFFFF", // Text color for the select input
    },

    // Apply similar styles for Checkbox, Radio, and other components as needed
  };

  const Resetvalue = ()=>{
    setPageSize(10)    
    setSearchTerm("")
    if(sortOrder === "asc"){      
      offerlistreview();
    }
    else{
      setTimeout(() => {
        setSortOrder("asc")
        
      }, 1000);
    }
   
  }

  return (
    <>
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
      >
          <ThemeProvider theme={theme}>
          <div className="searching-form">
            <div>
              <FormControl className="page_size" sx={formElementStyle}>
                <InputLabel id="demo-simple-select-label">Page Size</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  fullWidth
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>

              <FormControl className="page_size" sx={formElementStyle}>
                <InputLabel id="demo-simple-select-label">Sort Order:</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  fullWidth
                  value={sortOrder}
                  onChange={handleSortOrderChange}
                >
                  <MenuItem value={"asc"}>Ascending</MenuItem>
                  <MenuItem value={"desc"}>Descending</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="d-flex flex-wrap">
              <FormControl className="page_size" sx={formElementStyle}>
                <TextField
                  label="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
              <div className="reset-card">
                <button className="searchbtn " onClick={() =>  offerlistreview(searchTerm) }> Search</button>
                <button className="searchbtn reset-btn mx-2" onClick={()=>Resetvalue()}> Reset</button>
                {/* <button className="searchbtn "> Add</button> */}
              </div>
            </div>
          </div>
        </ThemeProvider>

        <Table columns={columns} rows={reviewdata} />
      </VuiBox>
      <div style={{ alignSelf: "flex-end", color: "white", margin: "7px 10px 0px auto" }}>
        <ThemeProvider theme={theme}>
          <Pagination
            count={Math.ceil(review.length / rowsPerPage)}
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
          />
        </ThemeProvider>
      </div>
    </>
  );
}

export default Review;

import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import LocationPicker from "react-location-picker";
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from "react-google-maps";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  TextField,
  Box,
  Button,
  createTheme,
  ThemeProvider,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  Modal,
  Typography,
  IconButton,
  TextareaAutosize,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import UnstyledTextareaIntroduction from "components/MuiTextArea";
import Multiselect from "components/Multiselect/MultiselectCommon";
import { postRequest } from "lib/utils";
import { postRequestForm } from "lib/utils";
import { set } from "date-fns";

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

const formElementStyle = {
  borderRadius: "14px",
  color: "#FFFFFF",
  "& .MuiOutlinedInput-root": {
    borderRadius: "14px",
    color: "#FFFFFF",
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.23)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  "& .MuiInputLabel-root": {
    color: "rgba(255, 255, 255, 0.7)",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#FFFFFF",
  },
  "& .MuiSelect-select": {
    color: "#FFFFFF",
  },
};

const CustomFileDropArea = styled("div")(({ theme }) => ({
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.primary.main}`,
  color: theme.palette.text.primary,
  textAlign: "center",
  cursor: "pointer",
  marginBottom: theme.spacing(2),
  maxWidth: "628px",
  marginLeft: "10px",
}));

const useStyles = makeStyles({
  formContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    gridColumn: "1 / -1",
    justifySelf: "center",
    marginTop: theme.spacing(2),
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

const MapComponent = withScriptjs(
  withGoogleMap(({ latitude, longitude, onMapClick }) => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: latitude, lng: longitude }}
      onClick={onMapClick}
    >
      <Marker position={{ lat: latitude, lng: longitude }} />
    </GoogleMap>
  ))
);

function VenueCreateForm({
  setselectedCover,
  setremoteLogo,
  Createvenue,
  onPrev,
  selectedCover,
  remoteLogo,
}) {
  let business_id = localStorage.getItem("business_id");
  const [open, setOpen] = useState(false);
  const [logoPreview, setLogoPreview] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);
  const [isLoadingLogo, setIsLoadingLogo] = useState(false);
  const [isLoadingCover, setIsLoadingCover] = useState(false);

  const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setIsLoadingLogo(true);
      setremoteLogo(acceptedFiles[0]);      
    },
    accept: "image/*",
  });

  const { getRootProps: getCoverRootProps, getInputProps: getCoverInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setIsLoadingCover(true);
      setselectedCover(acceptedFiles[0]);      
    },
    accept: "image/*",
  });

  const isDesktop = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  useEffect(() => {
    setIsLoadingLogo(false);   
    if (remoteLogo) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(remoteLogo);
    }
  }, [remoteLogo]);

  useEffect(() => {
    setIsLoadingCover(false);    
    if (selectedCover) {
      const reader = new FileReader();
      reader.onloadend = () => {        
        setCoverPreview(reader.result);
      };
      reader.readAsDataURL(selectedCover);
    }
  }, [selectedCover]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <Box
          noValidate
          autoComplete="off"
          sx={{
            display: "grid",
            marginTop: "20px",
            gridTemplateColumns: isDesktop ? "1fr 1fr" : "1fr",
            gap: 2,
            "& .MuiFormControl-root": {
              m: 1,
              ...formElementStyle,
            },
          }}
        >
          <CustomFileDropArea
            {...getLogoRootProps()}
            style={{
              height: 250,
              marginTop: "12px",
              backgroundColor: "#2D3547",
              borderStyle: "dashed",
              borderWidth: "2px",
              borderColor: "grey",
              borderRadius: "12px",
            }}
          >
            {isLoadingLogo ? (
              <CircularProgress style={{ color: "#FFFFFF" }} />
            ) : logoPreview ? (
              <img src={logoPreview} style={{ height: "200px" }} />
            ) : (
              <>
                <p style={{ marginTop: "80px", color: "#fff" }}>
                  Drag 'n' drop Logo Image here ...
                </p>
                <i className="far fa-file-image fa-lg" style={{ color: "#ffff", fontSize: "3rem" }}></i>
              </>
            )}
            <input {...getLogoInputProps()} />
          </CustomFileDropArea>

          <CustomFileDropArea
            {...getCoverRootProps()}
            style={{
              height: 250,
              marginTop: "12px",
              backgroundColor: "#2D3547",
              borderStyle: "dashed",
              borderWidth: "2px",
              borderColor: "grey",
              borderRadius: "12px",
            }}
          >
            {isLoadingCover ? (
              <CircularProgress style={{ color: "#FFFFFF" }} />
            ) : coverPreview ? (
              <img src={coverPreview} style={{ height: "200px" }} />
            ) : (
              <>
                <p style={{ marginTop: "80px", color: "#fff" }}>Drag 'n' drop Cover Image here...</p>{" "}
                <i className="far fa-file-image fa-lg" style={{ color: "#ffff", fontSize: "3rem" }}></i>
              </>
            )}
            <input {...getCoverInputProps()} />
          </CustomFileDropArea>
        </Box>

        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="button"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
              alignItems: "flex-end",
              borderRadius: "10px",
            }}
            fullWidth
            onClick={(e) => onPrev()}
          >
            Previous
          </Button>

          <Button
            type="button"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
              alignItems: "flex-end",
              borderRadius: "10px",
            }}
            fullWidth
            onClick={(e) => Createvenue(e)}
          >
            Submit
          </Button>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default VenueCreateForm;

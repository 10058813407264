import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import LocationPicker from "react-location-picker";
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from "react-google-maps";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  TextField,
  Box,
  Button,
  createTheme,
  ThemeProvider,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  Modal,
  Typography,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import UnstyledTextareaIntroduction from "components/MuiTextArea";

import Multiselect from "components/Multiselect/MultiselectCommon";
import { postRequest } from "lib/utils";
import { postRequestForm } from "lib/utils";


import DayTimePicker from "./component/Daypicker";

const defaultPosition = {
  lat: 25.2048345,
  lng: 55.2708345,
};

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

const formElementStyle = {
  borderRadius: "14px", // Border radius for form elements
  color: "#FFFFFF", // Text color for the form elements
  "& .MuiOutlinedInput-root": {
    // Targeting the input root of TextField and Select
    borderRadius: "14px", // Border radius for TextField and Select components
    color: "#FFFFFF", // Text color for the input content
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.23)", // Border color
    },
    "&.Mui-focused fieldset": {
      borderColor: "white", // Border color on focus
    },
  },
  "& .MuiInputLabel-root": {
    // Targeting the label of the TextField and Select
    color: "rgba(255, 255, 255, 0.7)", // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    // Label color when the input is focused
    color: "#FFFFFF", // Focused label color
  },
  "& .MuiSelect-select": {
    // Targeting the select input
    color: "#FFFFFF", // Text color for the select input
  },

  // Apply similar styles for Checkbox, Radio, and other components as needed
};

const CustomFileDropArea = styled("div")(({ theme }) => ({
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.primary.main}`,
  color: theme.palette.text.primary,
  textAlign: "center",
  cursor: "pointer",
  marginBottom: theme.spacing(2),
  maxWidth: "628px",
  marginLeft: "10px",
}));

const useStyles = makeStyles({
  formContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    gridColumn: "1 / -1",
    justifySelf: "center",
    marginTop: theme.spacing(2),
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};



function VenueCreateForm({updateTiming,setTimeOptions,onSubmit,location,defaultLocation,setLocation,days,timeOptions,onPrev}) {

  const [currentLocation, setCurrentLocation] = useState(defaultPosition); 


  let business_id = localStorage.getItem("business_id");
  const isDesktop = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  const handleLocationChange = ({ position, address, places }) => {

    const lng = position.lng;
    const lat = position.lat;
    const newLocation = {
      longitude: lng,
      latitude: lat,
    }
    setLocation(newLocation);   
  };

  const updateLocation = (value, type= 'lat') => {
    let newLocation = {
      lat: location.latitude,
      lng: location.longitude,
    }

    if (type === 'lat') {
      newLocation.lat = parseFloat(value);
    } else {
      newLocation.lng = parseFloat(value);
    }
    setCurrentLocation(newLocation);
    setLocation(newLocation);
  };

  useEffect(() => {

    console.log("location", location)

    if (defaultLocation) {

      if (defaultLocation.latitude && defaultLocation.longitude) {
        setCurrentLocation({
          lat: defaultLocation.latitude,
          lng: defaultLocation.longitude,
        });
      }     


      setLocation(defaultLocation);
    }

  }, [defaultLocation]);







  return (
    <ThemeProvider theme={theme}>
      {/* <ToastContainer /> */}
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <Box
          noValidate
          autoComplete="off"
          sx={{
            display: "grid",
            gridTemplateColumns: isDesktop ? "1fr" : "1fr",
            gap: 2,
            marginBottom: "2rem",
            "& .MuiFormControl-root": {
              m: 1,
              ...formElementStyle, // Applying the custom styles to each form control
            },
          }}
        >




                          <LocationPicker
                              containerElement={
                                <div style={{ height: "100%" }} />
                              }
                              mapElement={<div style={{ height: "600px" }} />}
                              zoom={14}
                              defaultPosition={currentLocation}
                              onChange={handleLocationChange}
                            />

        <TextField
            label="Latitude"
            name="latitude"
            variant="outlined"
            fullWidth
            value={location.latitude}
            onChange={(e) => updateLocation(e.target.value, 'lat') }
          />
          <TextField
            label="Longitude"
            name="longitude"
            variant="outlined"
            fullWidth
            value={location.longitude}
            onChange={(e) => updateLocation(e.target.value, 'lng') }
          />

          <ThemeProvider theme={theme}>
            <InputLabel style={{ color: "rgb(255 255 255 / 60%)" }}> Select Time</InputLabel>

            {days.map((day, index) => (
              <DayTimePicker
                updateTiming={updateTiming}
                currentValue={timeOptions[index]}
                index={index}
                key={index}
                day={day}
              />
            ))}
          </ThemeProvider>
        </Box>
   
    

        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "flex-end"           
          }}
        >     

          <Button
            type="button"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
              alignItems: "flex-end",
              borderRadius:"10px"
            }}
            fullWidth
            onClick={(e) => onSubmit(e)}
            // onClick={(e) => handlerimage(e)}
          >
            Submit
          </Button>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default VenueCreateForm;

import React, { useState, useEffect, useCallback } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { 
  Container, TextField, Box, Button, createTheme, ThemeProvider, 
  Checkbox, FormControlLabel, RadioGroup, Radio, FormControl, 
  InputLabel, Select, MenuItem, useMediaQuery , Modal, Typography, IconButton, TextareaAutosize,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import OfferModal from "./OfferModal";

import DeleteIcon from '@mui/icons-material/Delete';

import Swal from "sweetalert2";

import { useHistory, useParams, useLocation } from "react-router-dom";

import { postRequest, postRequestForm } from "../../../lib/utils";

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: 'dark',
    },
  },
  action: {
    hover: 'rgba(255, 255, 255, 0.08)',
  },
});

const formElementStyle = {
  borderRadius: '14px',
  color: '#FFFFFF',
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px',
    color: '#FFFFFF',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiSelect-select': {
    color: '#FFFFFF',
  },
};

const CustomFileDropArea = styled('div')(({ theme }) => ({
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.primary.main}`,
  color: theme.palette.text.primary,
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: theme.spacing(2),
}));

const useStyles = makeStyles({
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    gridColumn: '1 / -1',
    justifySelf: 'center',
    marginTop: theme.spacing(2),
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

function CreateOffer() {
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;
  const [open, setOpen] = useState(false);
  const [brunches, setBrunches] = useState([ ]);
  const [eventData, setEventData] = useState(null); 
  const [currentBrunch, setCurrentBrunch] = useState({ item: "", amount: "", discount: "", qty: "" });
  const [offerData, setOfferData] = useState({
    title: "",
    description: "",
    type: "total",
    discount: "",
    maxPersonAllowed: "",
    pricePerPerson: "",
    pricePerBrunch: "",
    claimCode: "" 
  });
  const [selectedDay, setSelectedDay] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedValues, setSelectedValues] = React.useState([]);
  const [packages, setPackages] = useState([  { title: "", amount: "", discount: "", qty: "", isFeatured: "", packages_description: "" } ]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imgPreview, setImgPreview] = useState(null);
  const [venueList, setVenueList] = useState([]);
  const [openModal, setOpenModal] = useState(false);   
  const [selectedVenue, setSelectedVenue] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

   // Function to open the modal
   const handleOpenModal = () => {
    setOpenModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Function to handle venue selection from the modal  

  const onDrop = useCallback(acceptedFiles => {
    // Select the first image file from the array of accepted files
    const file = acceptedFiles[0];
    setSelectedImage(file);

    // Optional: Create a preview URL for the selected image
    const reader = new FileReader();
    reader.onload = () => {
      // This will set the preview image URL
      setImgPreview(reader.result);
    };
    reader.readAsDataURL(file);
  }, []); 


  const isDesktop = useMediaQuery('(min-width:600px)');
  const updateValue = (index, value) => {
    const list = { ...eventData };
    list[index] = value;
    setEventData(list);   
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*' // Accept only image files
  });

  const pushBrunch = () => {

    if(!currentBrunch.item){
      alert("Please enter item")
      return;
    }

    if(!currentBrunch.amount){
      alert("Please enter amount")
      return;
    }

    // check amount is valid and none negative

    if(currentBrunch.amount < 0){
      alert("Please enter valid amount")
      return;
    }
    if(currentBrunch.discount){
      // check discount 0 - 100
      if(currentBrunch.discount < 0 || currentBrunch.discount > 100){
        alert("Please enter discount between 0 - 100")
        return;
      }      
    }

    if(!currentBrunch.qty){
      alert("Please enter quantity")
      return;
    }

    // check qty is valid and none negative

    if(currentBrunch.qty < 0){
      alert("Please enter valid quantity")
      return;
    }
    setBrunches([...brunches, currentBrunch]);
    setCurrentBrunch({ item: "", amount: "", discount: "", qty: "" });
    handleClose();

    Swal.fire({
      icon: "success",
      title: "Brunch added successfully",
      showConfirmButton: false,
      timer: 3000,
    });
  };

  const handleChange = (event) => {
    setSelectedValues(event.target.value);
    setSelectedDay(event.target.value);
  };

  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
  };

  const fetchCategories = async () => {
    // Fetch categories from API

    const payload = {
      limit: 10000
    };  

    const response = await postRequest(`venue/category/list/admin`, payload);
    const data = response.data; 
    setCategories(data.list); 
  }

  const eventManagment = async (filterArgs = []) => {
    const payload = {
      limit: 10000,
      page: 1,
    };
    const response = await postRequest(`venue/offer/list/admin`, payload);
    const data = response.data;
    //  setVenue(data.list);
    setVenueList(data.list);
  };

  


  useEffect(() => {
    
    eventManagment();
    fetchCategories();
  }, []);

  useEffect(() => {
    state.pricePerBrunch = state.pricePerBrunch ? state.pricePerBrunch : state.pricePerPerson;
    console.log("state", state)
    setOfferData(state);
    setSelectedVenue(state?.offer || null);
    if(state?.discount?._id){
      state.discount = state.discount._id;
    }    


    if (state?.brunch) { 
      
      setBrunches(state?.brunch);
    }    


  }, [state]);


  const createOffer = async (e) => {
    e.preventDefault();
    //setLoading(true);

    const specialOfferData = offerData;

    const venueId = localStorage.getItem("venueId");

    if(!specialOfferData.title){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter title",
      });
      return;
    }

    if(!specialOfferData.description){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter description",
      });
      retn;
    }    

    
   
    if(!specialOfferData.type){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Select type",
      });
      return;
    }

    if(!specialOfferData.discount){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter discount",
      });
      return;
    }

    // check discount must be greater than 0
    if(specialOfferData.discount <= 0 || specialOfferData.discount >= 100 ){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Discount must be greater than 0 and less than 100",
      });
      return;
    }
    

    if(!specialOfferData.maxPersonAllowed && specialOfferData.type === "total"){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter max person allowed",
      });
      return;
    }

    // check maxPersonAllowed must be greater than 0
    if(specialOfferData.maxPersonAllowed <= 0 && specialOfferData.type === "total"){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Max person allowed must be greater than 0",
      });
      return;
    }

    if(!specialOfferData.pricePerPerson && specialOfferData.type === "total"){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter price per person",
      });
      return;
    }

    if(!selectedVenue?._id && specialOfferData.type === "brunch"){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select offer",
      });
      return;
    }

    // max person allowed will be 1 - 6 

    if(specialOfferData.maxPersonAllowed > 6 && specialOfferData.type === "total"){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Max person allowed must be less than 6",
      });
      return;
    }

    const payload = {
      state_id:specialOfferData.state_id,
      title: specialOfferData.title,
      venueId: venueId,     
      maxPersonAllowed: specialOfferData?.maxPersonAllowed ? specialOfferData?.maxPersonAllowed : 0 ,
      // maxBrunchAllowed: specialOfferData?.maxPersonAllowed ? specialOfferData?.maxPersonAllowed : 0 , 
      pricePerPerson: specialOfferData?.pricePerPerson ? specialOfferData?.pricePerPerson : 0 ,
      pricePerBrunch: specialOfferData?.pricePerBrunch ? specialOfferData?.pricePerBrunch : 0 ,
      description: specialOfferData.description, 
      type: specialOfferData.type,
      offerId : selectedVenue?._id ? selectedVenue._id : "",
      discount : specialOfferData.discount ? specialOfferData.discount : "" ,
      specialOfferId: specialOfferData._id,
    }; 
    

    const response = await postRequest("venue/business/my-special-offer-update", payload);
    //setLoading(false);

    if (response.status == 1) {
      // If the update is successful, call the pushUpdates function      
      Swal.fire({
        title: 'Success!',
        text: response.message,
        icon: 'success',
        confirmButtonText: 'OK',
        timer: 1500, // closes after 1500ms
        showConfirmButton: false,
        timerProgressBar: true,
      });  

      setTimeout(() => {        
        history.push("/offer");
      }, 1000);
    } else {

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response.message,
      });

    }
  };
  
  

  const handleDelete = (index) => {
    setBrunches(brunches.filter((_, i )=> i !== index));
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Brunch deleted successfully",
    });
  };

  return (  
    <> 
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <Box 
          component="form"          
          noValidate 
          autoComplete="off"
          sx={{
            display: 'grid',
            gridTemplateColumns: isDesktop ? '1fr' : '1fr',
            gap: 2,
            '& .MuiFormControl-root': {
              m: 1,
              ...formElementStyle,
            },
          }}
        >
          <TextField  onChange={ (e) => setOfferData({...offerData, title: e.target.value})}  value={offerData?.title}  label="Title" variant="outlined" fullWidth />

          </Box>

          <Box 
          component="form"          
          noValidate 
          autoComplete="off"
          sx={{
            display: 'grid',
            gridTemplateColumns: isDesktop ? '1fr' : '1fr',
            gap: 2,
            '& .MuiFormControl-root': {
              m: 1,
              ...formElementStyle,
            },
          }}
        >     

          <TextField label="Description" variant="outlined"  value={offerData?.description} fullWidth multiline rows={4} onChange={ (e) => setOfferData( {...offerData, description: e.target.value})} />

         
          </Box>

          <Box 
          component="form"       
          noValidate 
          autoComplete="off"
          sx={{
            display: 'grid',
            gridTemplateColumns: isDesktop ? '1fr' : '1fr',
            gap: 2,
            '& .MuiFormControl-root': {
              m: 1,
              ...formElementStyle,
            },
          }}
        >         
         
         
         <>  
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select labelId="whosin-label" value={offerData?.type} label="Allow Whosin" onChange={(e) => setOfferData({...offerData, type: e.target.value})} fullWidth>
              <MenuItem value="total">Total Bill</MenuItem>
              <MenuItem value="brunch">Brunch</MenuItem>
            </Select>
          </FormControl>  
          {offerData.type === "brunch" && (
        <FormControl fullWidth>        
        <TextField
          style={{ marginLeft: 0, marginRight: 0 }}
          id="venue-textfield"
          onChange={(e) => updateValue("venue", e.target.value)}
          value={selectedVenue?.title ? selectedVenue.title : "Select Offer"}
          fullWidth
          readOnly
          onClick={handleOpenModal}
          
        />
      </FormControl>
      )}
      </>      
      <OfferModal
          open={openModal}
          onClose={handleCloseModal}
          venueList={venueList} // Pass venue list data to VenueModal
          onSelectVenue={(venueId) => {
            const selectedVenue = venueList.find((venue) => venue._id === venueId);  
            setEventData((prevData) => ({ ...prevData, venue: selectedVenue })); // Update selected venue in the form data
            setSelectedVenue(selectedVenue);
            handleCloseModal(); // Close the modal after selecting a venue
          }}
          selectedVenue={selectedVenue}
        />         
          {/* Enter Packages Discounts */}
          <TextField  type="number" value={offerData?.discount } onChange={ (e) => setOfferData({...offerData, discount: e.target.value})}  label="Discount" variant="outlined" fullWidth />  
          </Box>

          { offerData.type == "total" ? (
             <Box 
             component="form"       
             noValidate 
             autoComplete="off"
             sx={{
               display: 'grid',
               gridTemplateColumns: isDesktop ? '1fr 1fr' : '1fr',
               gap: 2,
               '& .MuiFormControl-root': {
                 m: 1,
                 ...formElementStyle,
               },
             }}
           >
                {/* <TextField type="number" value={offerData?.discount} onChange={ (e) => setOfferData({...offerData, discount: e.target.value})}  label="Discount" variant="outlined" fullWidth />   */}
                <TextField type="number" value={offerData?.maxPersonAllowed} onChange={ (e) => setOfferData({...offerData, maxPersonAllowed: e.target.value})}   label="Maximum person Allowed" variant="outlined" fullWidth />
                <TextField type="number" value={offerData?.pricePerPerson} onChange={ (e) => setOfferData({...offerData, pricePerPerson: e.target.value})}  label="Price per person" variant="outlined" fullWidth />
            </Box>

          ) : (
            <>
            {/* <br/><br/> */}
            {/* <Button onClick={handleOpen} type="button" variant="contained"> Add Brunch </Button>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: "20px", marginBottom: "20px" }}>
                
            <TextField type="number" value={offerData?.discount} onChange={ (e) => setOfferData({...offerData, discount: e.target.value})}  label="Discount" variant="outlined" fullWidth />  
            <TextField type="number" value={offerData?.pricePerBrunch} onChange={ (e) => setOfferData({...offerData, pricePerBrunch: e.target.value})}  label="Price per brunch" variant="outlined" fullWidth />
                

            </Box> */}

              <Box 
             component="form"       
             noValidate 
             autoComplete="off"
             sx={{
               display: 'grid',
               gridTemplateColumns: isDesktop ? '1fr' : '1fr',
               gap: 2,
               '& .MuiFormControl-root': {
                 m: 1,
                 ...formElementStyle,
               },
             }}
           >




               
            </Box>
            <Box 
             component="form"       
             noValidate 
             autoComplete="off"
             sx={{
               display: 'grid',
               gridTemplateColumns: isDesktop ? '1fr' : '1fr',
               gap: 2,
               '& .MuiFormControl-root': {
                 m: 1,
                 ...formElementStyle,
               },
             }}
           >  
            </Box>

            </>

          ) }

              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

          <Button onClick={createOffer} type="button" variant="contained" style={{ background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)" }}>
            Submit
          </Button>       

          </Box> 
        
      </Container>
    </ThemeProvider> 

          </>


  );
}

export default CreateOffer;

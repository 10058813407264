import React, { useState, useEffect, useCallback } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { 
  Container, TextField, Box, Button, createTheme, ThemeProvider, 
  Checkbox, FormControlLabel, RadioGroup, Radio, FormControl, 
  InputLabel, Select, MenuItem, useMediaQuery , Modal, Typography, IconButton, TextareaAutosize,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from "sweetalert2";
import { parseISO, format } from 'date-fns';
import { useHistory, useParams, useLocation } from "react-router-dom";
import VenueModal from "./VenueModal";
import CustomSelect from "./CustomSelect";



import { postRequest, postRequestForm, putRequestForm } from "../../../lib/utils";

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: 'dark',
    },
  },
  action: {
    hover: 'rgba(255, 255, 255, 0.08)',
  },
});

const formElementStyle = {
  borderRadius: '14px',
  color: '#FFFFFF',
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px',
    color: '#FFFFFF',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiSelect-select': {
    color: '#FFFFFF',
  },
};

const CustomFileDropArea = styled('div')(({ theme }) => ({
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.primary.main}`,
  color: theme.palette.text.primary,
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: theme.spacing(2),
}));

const useStyles = makeStyles({
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    gridColumn: '1 / -1',
    justifySelf: 'center',
    marginTop: theme.spacing(2),
  },
  chip: {
    color: 'white !important',
    background: 'linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)',
    margin: '2px',
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

function CreateOffer() {
  const history = useHistory();
  const location = useLocation();
  const state = location.state?.row;
  const [open, setOpen] = useState(false);
  const [offerData, setOfferData] = useState({
    title: "",
    description: "",
    categoryId: [],
    image: "",
    allowWhosin: "",
    allowClaimedon: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: ""   
  });

  const [selectedDay, setSelectedDay] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedValues, setSelectedValues] = React.useState([]);
  const [packages, setPackages] = useState([]);
  const [currentPackage, setCurrentPackage] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [imgPreview, setImgPreview] = useState(null);
  const [venueList, setVenueList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = async() => {
    window.scrollTo(0, 400);
    // await for half a second to let the window scroll to top
    await new Promise((r) => setTimeout(r, 500));
    setOpen(true);
  }
  const handleClose = () => setOpen(false);
  const [selectedVenue, setSelectedVenue] = useState(null);

    // Function to open the modal
    const handleOpenModal = () => {
      setOpenModal(true);
    };
  
    // Function to close the modal
    const handleCloseModal = () => {
      setOpenModal(false);
    };
  
    // Function to handle venue selection from the modal
    const handleSelectVenue = (venueId) => {
      setSelectedVenue(venueId);
  
      
      setOpenModal(false);
      handleCloseModal(); 
    };

  const onDrop = useCallback(acceptedFiles => {
    // Select the first image file from the array of accepted files
    const file = acceptedFiles[0];
    setSelectedImage(file);

    // Optional: Create a preview URL for the selected image
    const reader = new FileReader();
    reader.onload = () => {
      // This will set the preview image URL
      setImgPreview(reader.result);
    };
    reader.readAsDataURL(file);
  }, []); 


  const isDesktop = useMediaQuery('(min-width:600px)');



  



  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*' // Accept only image files
  });

  const handleChange = (event) => {
    setSelectedValues(event.target.value);
    setSelectedDay(event.target.value);
  };

  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setOfferData({ ...offerData, allowClaimedon: selectedValue });
    if (selectedValue === "publicHolidays") {
      setOpenModal(true);
    }
  };

  const fetchCategories = async () => {
    // Fetch categories from API

    const payload = {
      limit: 10000
    };  

    const response = await postRequest(`venue/category/list/admin`, payload);
    const data = response.data; 
    setCategories(data.list); 
  } 
 const eventManagment = async (filterArgs = []) => {
    const payload = {
      limit: 10000,
      page: 1,
    };
    const response = await postRequest(`venue/holiday/list`, payload);
    const data = response.data;
    //  setVenue(data.list);
    setVenueList(data.list);
  };

  const handleCategoryChange = (event) => {
    setOfferData({ ...offerData, categoryId: event.target.value });
  };


  useEffect(() => {
    eventManagment();
    fetchCategories();
  }, []);


  useEffect(() => {
    if (!state) return;

    state.allowWhosin = state.allowWhosIn ? 1 : 0;
    

    const parseTime = (dateTime) => {
      const dateObj = parseISO(dateTime);
      const date = format(dateObj, 'yyyy-MM-dd'); // Formats to date string
      const time = format(dateObj, 'HH:mm:ss'); // Formats to local time string
    
      return { date, time };
    };



    // Process start time and date
    const { date: startDate, time: startTime } = parseTime(state?.startTime);
    state.startDate = startDate;
    state.startTime = startTime;

    // Process end time and date
    const { date: endDate, time: endTime } = parseTime(state?.endTime);
    state.endDate = endDate;
    state.endTime = endTime;

    let days = state.days?.split(",") || [];

    if (state?.pack?.length) {     

      const tempPackages = state.pack.map((pack) => {
        return {
          ...pack,
          claimAvilable: pack.isAllowClaim, 
          allowBrunch: pack.isAllowSale,
          cliamCode: pack.claimCode,
        };
      });


      setPackages(tempPackages);
    }

    
    

    let daysFull = [
      { id: "mon", name: "Monday" },
      { id: "tue", name: "Tuesday" },
      { id: "wed", name: "Wednesday" },
      { id: "thu", name: "Thursday" },
      { id: "fri", name: "Friday" },
      { id: "sat", name: "Saturday" },
      { id: "sun", name: "Sunday" },
    ];

    // Filter days based on `days` array from state
    days = daysFull.filter((dayFull) => days.includes(dayFull.id));
    setSelectedValues(days.map((day) => day.name));
    setSelectedDay(days.map((day) => day.name));
    setImgPreview(state.image);
    console.log("state", state);

    const specialOffer = state?.specialOffer || {};

    state.specialOfferTitle = specialOffer.title;
    state.specialOfferDescription = specialOffer.description;
    state.specialOfferDiscount = specialOffer.discount;

    setOfferData(state);

    // If you need to fetch data, call those functions here
    // fetchList();
    // fetchPackages();
  }, [state]);

  const createOffer = async (e) => {
    e.preventDefault();
    //setLoading(true);
   
    if(!offerData.title){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter offer title",
      });
      return;
    }

    

    if(!offerData.categoryId){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select offer category",
      });
      return;
    }

    if(!offerData.allowWhosin){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select offer allow whosin",
      });
      return;
    }

    if(!offerData.startDate){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select offer start date",
      });
      return;
    }

    if(!offerData.startTime){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select offer start time",
      });
      return;
    }

    if(!offerData.endDate){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select offer end date",
      });
      return;
    }

    if(!offerData.endTime){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select offer end time",
      });
      return;
    }    

    if(!offerData.description){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter offer description",
      });
      return;
    } 
  
    // combine date and time

    let startDate = null;
    let endDate = null;

    if (offerData.startDate) {
      if (offerData.startTime) {
        startDate = new Date(offerData.startDate + " " + offerData.startTime);
      } else {
        startDate = new Date(offerData.startDate + " " + "00:00");
      }
    }

    if (offerData.endDate) {
      if (offerData.endTime) {
        endDate = new Date(offerData.endDate + " " + offerData.endTime);
      } else {
        endDate = new Date(offerData.endDate + " " + "00:00");
      }
    }

    const formData = new FormData();
    formData.append("title", offerData.title);
    formData.append("description", offerData.description);
    formData.append("discountTag", offerData.discountText);
    formData.append("disclaimerTitle", offerData.disclaimerTitle);
    formData.append("disclaimerDescription", offerData.disclaimerDescription);
    formData.append("venue", offerData.venueId);
    // sort the selected days by day order
    // convert Sunday to sun, Monday to mon etc in selectedDay array
    let selectedDayTemp = selectedDay?.map((day) => {
      return day.substring(0, 3).toLowerCase();
    });

    // sort the selected days by day order   

    selectedDayTemp.sort((a, b) => {
      const dayOrder = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
      return dayOrder.indexOf(a) - dayOrder.indexOf(b);
    });

    formData.append("days", selectedDayTemp.join(","));
    
    offerData.categoryId.forEach((item, index) => {
      formData.append(`categoryId[${index}]`, item);
    });   


    if(selectedImage){
      formData.append("image", selectedImage);
    }    

    formData.append("allowWhosin", offerData.allowWhosin == "1" ? true : false);
    formData.append("allowClaimedon", offerData.allowClaimedon == "1" ? true : false);

    if (offerData.startDate) {
      formData.append("startTime", startDate);
    }
    if (offerData.endDate) {
      formData.append("endTime", endDate);
    }

    formData.append("offerId", state?._id);

    //formData.append("discount", offerData.discount);

    const finalPackages = packages.filter(
      (pack) =>
        pack.title &&
        pack.amount 
    );   
    if (packages.length && finalPackages.length === 0) {      
      Swal.fire({
        title: 'Error!',
        text: "Please enter at least one package",
        icon: 'error',
        confirmButtonText: 'OK',
        timer: 1500, // closes after 1500ms
        showConfirmButton: false,
        timerProgressBar: true,
      });
     
      return;
    }    

    const isClaimAvilable = finalPackages.some((pack) => pack.claimAvilable === true);
    

    if(isClaimAvilable){
      // we need to enter special offer details

      if(!offerData.specialOfferTitle){
        window.scrollTo(0, 0);
        // wait for half a second
      await new Promise(r => setTimeout(r, 500));
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter special offer title",
        });
        return;
      }

      if(!offerData.specialOfferDescription){
        window.scrollTo(0, 0);
        // wait for half a second
      await new Promise(r => setTimeout(r, 500));
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter special offer description",
        });
        return;
      }

      if(!offerData.specialOfferDiscount){
        window.scrollTo(0, 0);
        // wait for half a second
      await new Promise(r => setTimeout(r, 500));
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter special offer discount",
        });
        return;
      }

      // discount will be 1 - 99

      if(offerData.specialOfferDiscount < 1 || offerData.specialOfferDiscount > 99){
        window.scrollTo(0, 0);
        // wait for half a second
      await new Promise(r => setTimeout(r, 500));
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter special offer discount between 1 - 99",
        });
        return;
      }

      formData.append("specialOfferId", offerData.specialOffer?._id || "");
      formData.append("specialOffer_title", offerData.specialOfferTitle || "");
      formData.append("specialOffer_discription", offerData.specialOfferDescription || "");
      formData.append("specialOffer_discount", offerData.specialOfferDiscount || 0);

    }


    finalPackages.forEach((item, index) => {
      formData.append(`packages_id[${index}]`, item._id || "");
      formData.append(`packages_type[${index}]`, item.type || "regular");
      formData.append(`packages_title[${index}]`, item.title);
      formData.append(`packages_amount[${index}]`, item.amount);
      formData.append(`packages_discount[${index}]`, item.discount || 0);      
      formData.append(`packages_qty[${index}]`, item.qty);
      formData.append(`packages_isAllowClaim[${index}]`, item.claimAvilable || false);
      formData.append(`packages_buyQty[${index}]`, item.buyQty || 0);
      formData.append(`packages_getQty[${index}]`, item.getQty || 0);      
      formData.append(`packages_isFree[${index}]`, item.isFree      || false);
      formData.append(`packages_price_per_brunch[${index}]`, item.pricePerBrunch || 0);
      formData.append(`packages_leftQtyAlert[${index}]`, item.leftQtyAlert || 0);
      formData.append(`packages_isAllowSale[${index}]`, item.allowBrunch || false);  
      formData.append(`packages_isFeatured[${index}]`, item.isFeatured || false);         
      formData.append(
        `packages_description[${index}]`,
        item.packages_description || item.description
      );
    });


    const response = await putRequestForm("venue/offer/update", formData);
    //setLoading(false);

    if (response.status == 1) {
      // If the update is successful, call the pushUpdates function      
      
      window.scrollTo(0, 0);
      await new Promise((r) => setTimeout(r, 500));
      
      Swal.fire({
        title: 'Success!',
        text: response.message,
        icon: 'success',
        confirmButtonText: 'OK',
        timer: 1500, // closes after 1500ms
        showConfirmButton: false,
        timerProgressBar: true,
      });  

      setTimeout(() => {        
        history.push("/offer");
      }, 3000);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response.message,
      });
    }
  };

  const pushPackage = () => {

    if (!currentPackage.title) {
      toast.error("Please enter package title");
      return;
    }

    if (!currentPackage.description) {
      toast.error("Please enter package description");
      return;
    }
    
    if(!currentPackage.amount){
      alert("Please enter amount")
      return;
    }

    // check amount is valid and none negative

    if(currentPackage.amount < 0){
      alert("Please enter valid amount")
      return;
    }
    if(currentPackage.discount){
      // check discount 0 - 100
      if(currentPackage.discount < 0 || currentPackage.discount > 100){
        alert("Please enter discount between 0 - 100")
        return;
      }      
    }  
     

    

    if(!currentPackage.qty){
      alert("Please enter quantity")
      return;
    }

    // check qty is valid and none negative

    if(currentPackage.qty < 0){
      alert("Please enter valid quantity")
      return;
    }

    console.log("currentPackage", currentPackage);
    
    setPackages([...packages, currentPackage]);
    setCurrentPackage({});
    handleClose();
    // Swal.fire({
    //   icon: "success",
    //   title: "Success",
    //   text: "Package added successfully",
    // });
  };  

  

  const handleDelete = (index) => {
    const filtered = packages.filter((_, i) => i !== index);
    setPackages(filtered);
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Package deleted successfully",
    });
  };

  const renderSelectedCategories = (selected) => (
    <div>
      {selected.map((value) => (
        <Chip key={value}  label={categories.find(c => c._id === value)?.title || value} className={classes.chip} />
      ))}
    </div>
  );

  function uppercaseFirstChar(str) {
    if (!str) return str; // Return the original string if it's empty
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (   
    <>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <Box 
          component="form"          
          noValidate 
          autoComplete="off"
          sx={{
            display: 'grid',
            gridTemplateColumns: isDesktop ? '1fr' : '1fr',
            gap: 2,
            '& .MuiFormControl-root': {
              m: 1,
              ...formElementStyle,
            },
          }}
        >
          <TextField onChange={ (e) => setOfferData({...offerData, title: e.target.value})} value={offerData?.title}  label="Title" variant="outlined" fullWidth />
          <TextField label="Description" variant="outlined" fullWidth multiline rows={4} value={offerData?.description} onChange={ (e) => setOfferData( {...offerData, description: e.target.value})} />
          <TextField onChange={ (e) => setOfferData({...offerData, discountText: e.target.value})} value={offerData?.discountText}  label="Discount Tag" variant="outlined" fullWidth />
          <TextField onChange={ (e) => setOfferData({...offerData, disclaimerTitle: e.target.value})} value={offerData?.disclaimerTitle}  label="Disclaimer Title" variant="outlined" fullWidth />
          <TextField label="Disclaimer Description" variant="outlined" fullWidth multiline rows={4} value={offerData?.disclaimerDescription} onChange={ (e) => setOfferData( {...offerData, disclaimerDescription: e.target.value})} /> 
          </Box>

          <Box 
          component="form"       
          noValidate 
          autoComplete="off"
          sx={{
            display: 'grid',
            gridTemplateColumns: isDesktop ? '1fr 1fr' : '1fr',
            gap: 2,
            '& .MuiFormControl-root': {
              m: 1,
              ...formElementStyle,
            },
          }}
        >


          { offerData?.categoryId && (
                   <FormControl fullWidth>
                   <InputLabel>Select Category</InputLabel>
                   <Select
                     labelId="category-label"
                     label="Select Category"
                     multiple
                     value={offerData.categoryId}
                     onChange={handleCategoryChange}
                     renderValue={selected => renderSelectedCategories(selected)}
                     fullWidth
                   >
                     <MenuItem value="">
                       <em>Select Category</em>
                     </MenuItem>
                     {categories?.map((category) => (
                       <MenuItem key={category._id} value={category._id}>
                         {category.title}
                       </MenuItem>
                     ))}
                   </Select>
                 </FormControl>
          ) }


{ offerData?.allowWhosin && (
               <FormControl fullWidth>
               <InputLabel>Allow Whosin</InputLabel>
               <Select labelId="whosin-label" label="Allow Whosin" value={offerData?.allowWhosin} onChange={(e) => setOfferData({...offerData, allowWhosin: e.target.value})} fullWidth>
                 <MenuItem value="1">Yes</MenuItem>
                 <MenuItem value="0">No</MenuItem>
               </Select>
             </FormControl>    
          ) }         

         

              
          


          {/* Select Days */}
          <TextField label="Start Date" variant="outlined" type="date" onChange={(e) => setOfferData({...offerData, startDate: e.target.value})}  value={offerData?.startDate} fullWidth InputLabelProps={{
    shrink: true,
  }} />
          <TextField label="Start Time" variant="outlined" type="time" onChange={(e) => setOfferData({...offerData, startTime: e.target.value})} value={offerData?.startTime} fullWidth InputLabelProps={{
    shrink: true,
  }} />
          <TextField label="End Date" variant="outlined" type="date" onChange={(e) => setOfferData({...offerData, endDate: e.target.value})} value={offerData?.endDate} fullWidth InputLabelProps={{
    shrink: true,
  }} />
          <TextField label="End Time" variant="outlined" type="time" onChange={(e) => setOfferData({...offerData, endTime: e.target.value})} value={offerData?.endTime} fullWidth InputLabelProps={{
    shrink: true,
  }} />
        
          {/* Enter Packages Discounts */}

         

          </Box>

          <Box 
          component="form"          
          noValidate 
          autoComplete="off"
          sx={{
            display: 'grid',
            gridTemplateColumns: isDesktop ? '1fr' : '1fr',
            gap: 2,
            '& .MuiFormControl-root': {
              m: 1,
              ...formElementStyle,
            },
          }}
        >

        <FormControl fullWidth>
              <InputLabel id="demo-multiple-select-label">Days</InputLabel>
              <Select
                labelId="demo-multiple-select-label"
                id="demo-multiple-select"
                multiple
                value={selectedValues}
                onChange={handleChange}
                renderValue={(selected) => selected.join(', ')}
              >
                {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ].map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <>
            <FormControl fullWidth>
            <InputLabel>Allow Cliamed On</InputLabel>
            <Select labelId="whosin-label" label="Allow Cliamed On" onChange={(e) => setOfferData({...offerData, allowClaimedon: e.target.value})} fullWidth>
            {/* <Select labelId="whosin-label" label="Allow Cliamed On" value={offerData.allowClaimedon} onChange={handleSelectChange} fullWidth> */}
              <MenuItem value="selected">Selected When to Claim</MenuItem>
              <MenuItem value="allWeek">All Week</MenuItem>
              <MenuItem value="weekends">Weekends</MenuItem>
              <MenuItem value="weekdays">Weekdays</MenuItem>
              <MenuItem value="publicHolidays">Public Holiday's</MenuItem>

            </Select>
          </FormControl>  
          
  {offerData.allowClaimedon === "publicHolidays" && (
     <>
     <FormControl fullWidth>
        <TextField
          placeholder="Select public holidays"
          variant="outlined"
          fullWidth
          onClick={handleOpenModal}
          value={offerData?.venue ? offerData.venue.name : "Select Public Holidays"}
          InputProps={{
            readOnly: true, // Prevent user input directly into the field
          }}
        />
      </FormControl>
  

        

<VenueModal
 style={{ top: "50% !important",position: "fixed" }}
  open={openModal}
  onClose={handleCloseModal}
  venueList={venueList} // Pass venue list data to VenueModal
  onSelectVenue={(venueId) => {
    const selectedVenue = venueList.find((venue) => venue._id === venueId);
    setEventData((prevData) => ({ ...prevData, venue: selectedVenue })); // Update selected venue in the form data
    setSelectedVenue(selectedVenue);
    if (selectedVenue) {
      // If a venue is selected, update publicHolidays with its name
      setOfferData((prevData) => ({
        ...prevData,
        publicHolidays: selectedVenue.name
      }));
    }
    handleCloseModal(); // Close the modal after selecting a venue
  }}
  selectedVenue={selectedVenue}
/>
</>
)}
</>

          <CustomFileDropArea
            {...getRootProps()}
            style={{ height: 120, marginTop: "12px", backgroundColor: "#2D3547",  borderStyle: "dashed", borderWidth: "2px", borderColor: 'grey', borderRadius: "12px" }}
          >
            <input {...getInputProps()} />
            
            {imgPreview ? <img src={imgPreview} alt="Preview" style={{ height: '100%' }} /> :
             <span style={{ color: "white" }}>Drag 'n' drop some files here, or click to select files</span>}
           
          </CustomFileDropArea>

          </Box>

          <Button onClick={handleOpen} type="button" variant="contained"> Add Package</Button>

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: "20px", marginBottom: "20px" }}>
                
                

                <TableContainer component={Paper} elevation={3} style={{ backgroundColor: '#181B1E' }}>
                <Table aria-label="brunches table">
                  <TableHead>
                    <TableRow>
                      <TableCell  style={{ color: 'white' }}>Type</TableCell>
                      <TableCell  style={{ color: 'white' }}>Title </TableCell>
                      <TableCell  style={{ color: 'white' }}>Description </TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Amount</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Discount</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Quantity</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Left Qty Alert On</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Buy Qty</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Get Qty</TableCell>                      
                      <TableCell  style={{ color: 'white' }} align="right">Claim Amount</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Featured</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Allow Sell</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Allow Claim</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {packages.map((brunch, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ color: 'white' }}>{ uppercaseFirstChar(brunch.type) || "-"}</TableCell>
                        <TableCell style={{ color: 'white' }}>{brunch.title || "-"}</TableCell>
                        <TableCell style={{ color: 'white' }}>{brunch.description ? brunch.description : brunch.package_description || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.amount || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.discount || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.qty || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.leftQtyAlert || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.buyQty || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.getQty || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.isFree ? 'Free' : brunch.pricePerBrunch || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.isFeatured ? 'Yes' : 'No' || "-"}</TableCell>

                        {/* <TableCell align="right" style={{ color: 'white' }}>{brunch.claimCode || "N.A"}</TableCell> */}
                        {/* <TableCell align="right" style={{ color: 'white' }}>{brunch.pricePerBrunch || "N.A"}</TableCell> */}
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.isAllowSale ? 'Yes' : 'No' || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.isAllowClaim ? 'Yes' : 'No' || "-"}</TableCell>

                        <TableCell align="right" style={{ color: 'white' }}>
                        <IconButton onClick={() => handleDelete(index)}>
                          <DeleteIcon style={{ color: 'white' }} />
                        </IconButton>
                      </TableCell>
          
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>


            <Box 
            component="form"          
            noValidate 
            autoComplete="off"
            sx={{
              display: 'grid',
              gridTemplateColumns: isDesktop ? '1fr' : '1fr',
              gap: 2,
              '& .MuiFormControl-root': {
                m: 1,
                ...formElementStyle,
              },
            }}
          >

            <TextField onChange={ (e) => setOfferData({...offerData, specialOfferTitle: e.target.value})} value={offerData?.specialOfferTitle}  label="Special Offer Title" variant="outlined" fullWidth />
            <TextField label="Special Offer Description" variant="outlined" fullWidth multiline rows={4} value={offerData?.specialOfferDescription} onChange={ (e) => setOfferData( {...offerData, specialOfferDescription: e.target.value})} />
            <TextField type="number" value={offerData?.specialOfferDiscount}  onChange={ (e) => setOfferData({...offerData, specialOfferDiscount: e.target.value})}  label="Special Offer Discount" variant="outlined" fullWidth />
          

            </Box>


          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: "16px" }}>

          <Button onClick={createOffer} type="button" variant="contained" style={{ background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)" }}>
            Submit
          </Button>       

          </Box> 
        
      </Container>
    </ThemeProvider>

<ThemeProvider theme={theme}>
<Modal
  style={{ top: '90% !important' , position: "fixed"}}
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={{
      position: 'absolute',
      top: '40%',
      left: '50%',
      bottom: '40%',
      right: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '80vw',
      width: 400, // or whatever width you desire
      maxHeight: '80vh',
      overflowY: 'auto',
      backgroundColor: "#22262F",
      padding: '20px',
       bottom: '1%',
    }}>
    <IconButton
      aria-label="close"
      onClick={handleClose}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
      }}
      style={{backgroundColor:"#EB28A0"}}
    >
      <CloseIcon />
    </IconButton>
    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: 'white' }}>
      Add Package
    </Typography>
    <Box component="form" sx={{ mt: 2 }} noValidate autoComplete="off">
    <FormControl fullWidth margin="normal">
    {/* <InputLabel id="package-type-label" style={{ color: 'white' }}>Type</InputLabel> */}

    <CustomSelect
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        onChange={(e) => setCurrentPackage({...currentPackage, type: e.target.value})}
        // selected={currentPackage.type || ""}
        options={[
          { value: "", label: "Select Type" },
          { value: "regular", label: "Regular" },
          { value: "group", label: "Group" },
        ]}
      />

    {/* <Select
      labelId="package-type-label"
      style={{ color: 'white' }}
      id="package-type"      
      onChange={(e) => setCurrentPackage({...currentPackage, type: e.target.value})}
      label="Type"      
      inputProps={{ style: { color: 'white' } }}
    >
      <MenuItem value="regular" style={{ color: 'white' }}>Regular</MenuItem>
      <MenuItem value="group" style={{ color: 'white' }}>Group</MenuItem>
    
    </Select> */}



    {/* <select
      labelId="package-type-label"
      style={{ color: 'white' }}
      id="package-type"
      defaultValue="regular"
      onChange={(e) => setCurrentPackage({...currentPackage, type: e.target.value})}
      label="Type"
      inputProps={{ style: { color: 'white' } }}
    >
      <option value="regular" style={{ color: 'white' }}>Regular</option>
      <option value="group" style={{ color: 'white' }}>Group</option>
    </select> */}



  </FormControl>
      <TextField
        margin="normal"
        value={currentPackage.title}
        onChange={(e) => setCurrentPackage({...currentPackage, title: e.target.value})}                   
        required
        fullWidth
        id="name"
        label="Title"
        name="item"
        autoFocus
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}      
      />

      <TextField
        margin="normal"
        value={currentPackage.description}
        onChange={(e) => setCurrentPackage({...currentPackage, description: e.target.value})}                   
        required
        fullWidth
        id="name2"
        label="Description"
        name="item2"       
        autoFocus
        multiline
        rows={2}
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}      
      />


      <TextField
        margin="normal"
        value={currentPackage.amount}
        onChange={(e) => setCurrentPackage({...currentPackage, amount: e.target.value})}                   
        required
        fullWidth
        id="amount"
        label="Amount"
        name="amount"
        type="number"
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}      
      />
       <TextField
        margin="normal"
        value={currentPackage.discount}
        onChange={(e) => setCurrentPackage({...currentPackage, discount: e.target.value})}                   
        required
        fullWidth
        id="discount"
        label="Discount"
        name="discount"
        type="number"
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}      
      />
         <FormControlLabel
      control={<Checkbox checked={currentPackage.isFeatured} onChange={(e) => setCurrentPackage({...currentPackage, isFeatured: e.target.checked})} style={{ color: '#EB28A0' }}  />}
      label={<span style={{ color: 'white' }}>is Featured ?</span>}
      />

    <FormControlLabel
      control={<Checkbox checked={currentPackage.isAllowSale} onChange={(e) => setCurrentPackage({...currentPackage, isAllowSale: e.target.checked})} style={{ color: '#EB28A0' }}  />}
      label={<span style={{ color: 'white' }}>Allow Sell</span>}
      />
      {currentPackage.isAllowSale && (
      <>
      <TextField
        margin="normal"
        value={currentPackage.qty}
        onChange={(e) => setCurrentPackage({...currentPackage, qty: e.target.value}) }
        required
        fullWidth
        id="quantity"
        label="Quantity"
        name="quantity"
        type="number"
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}      
      />

{currentPackage.type === "group" && (
      <>
        <TextField
          margin="normal"
          value={currentPackage.buyQty}
          onChange={(e) => setCurrentPackage({...currentPackage, buyQty: e.target.value}) }
          required
          fullWidth
          id="buyQty"
          label="Buy Qty"
          name="buyQty"
          type="number"
          InputLabelProps={{ shrink: true, style: { color: 'white' }}} 
          InputProps={{ style: { color: 'white' }}}      
        />
        <TextField
          margin="normal"
          value={currentPackage.getQty}
          onChange={(e) => setCurrentPackage({...currentPackage, getQty: e.target.value}) }
          required
          fullWidth
          id="getQty"
          label="Get Qty"
          name="getQty"
          type="number"
          InputLabelProps={{ shrink: true, style: { color: 'white' }}} 
          InputProps={{ style: { color: 'white' }}}      
        />
      </>
    )}
       <TextField
      margin="normal"
      value={currentPackage.leftQtyAlert}
      onChange={(e) => setCurrentPackage({...currentPackage, leftQtyAlert: e.target.value}) }
      required
      fullWidth
      id="quantity"
      label="Left Qty Alert On"
      name="quantity"
      type="number"
      InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}      
    />
    </>
      )}
      

      <FormControlLabel
      control={<Checkbox checked={currentPackage.isAllowClaim} onChange={(e) => setCurrentPackage({...currentPackage, isAllowClaim: e.target.checked})} style={{ color: '#EB28A0' }} />}
      label={<span style={{ color: 'white' }}>Allow Claim</span>}
      /> 

{currentPackage.isAllowClaim && (
        <>
        <FormControlLabel
        control={<Checkbox checked={currentPackage.isFree} onChange={(e) => setCurrentPackage({...currentPackage, isFree: e.target.checked})} style={{ color: '#EB28A0' }}  />}
        label={<span style={{ color: 'white' }}>is Packages Free ?</span>}
        /> 

        {!currentPackage.isFree && (

        <TextField
        margin="normal"
        value={currentPackage.pricePerBrunch}
        onChange={(e) => setCurrentPackage({...currentPackage, pricePerBrunch: e.target.value})}                   
        required
        fullWidth
        id="amount"
        label="Claim Amount"
        name="amount"
        type="number"
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}    


      />
        )}
      </>

        
      )}  

{/* <TextField
         margin="normal"
         value={currentPackage.pricePerBrunch}
         onChange={(e) =>{
           const inputValue = e.target.value;
           if (/^\d{0,4}$/.test(inputValue)) {
            setCurrentPackage({...currentPackage, pricePerBrunch: e.target.value});
           }    
         }}               
         required
         fullWidth
         id="pricePerBrunch"
         label="Price Per Brunch"
         name="pricePerBrunch"   
         type="number" 
         InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}         
       /> */}

     {/* <FormControlLabel
      control={<Checkbox checked={currentPackage.claimAvilable} onChange={(e) => setCurrentPackage({...currentPackage, claimAvilable: e.target.checked})} />}
      label={<span style={{ color: 'white' }}>"Allow Claim"</span>}
      /> 
    { currentPackage?.claimAvilable && (
    <TextField
        margin="normal"
        value={currentPackage.cliamCode}
        onChange={(e) =>{
          const inputValue = e.target.value;
          if (/^\d{0,4}$/.test(inputValue)) {
           setCurrentPackage({...currentPackage, cliamCode: e.target.value});
          }   
        }}                
        required
        fullWidth
        id="claimCode"
        label="ClaimCode"
        name="claimCode"     
        type = "number"
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}      
      />
    )}
    

    <FormControlLabel
      control={<Checkbox checked={currentPackage.allowBrunch} onChange={(e) => setCurrentPackage({...currentPackage, allowBrunch: e.target.checked})} style={{ color: '#EB28A0' }}/>}
      label={<span style={{ color: 'white' }}>"Allow Sale"</span>}
      />)} */}


      <Button style={{backgroundColor:"#EB28A0"}} onClick={pushPackage}  type="button" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Save
      </Button>
    </Box>
  </Box>
</Modal>
</ThemeProvider> </>
  );
}

export default CreateOffer;

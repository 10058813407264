

import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";

import { postRequest } from "../../lib/utils";

import VuiBox from "components/VuiBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import colors from "assets/theme/base/colors";

import "assets/css/main.css";

import Form from "layouts/profile/components/Form";
import FormUI from "layouts/reports/ui/form";
import './tab-style.css';
import AddGalleryForm from "./GalleryForm";

function AddGallery() {
  const [businessData, setBusinessData] = useState(null);
  const [activeTab, setActiveTab] = useState("tab1"); // Initialize with the default active tab
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  // const visibleRows = rows.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const images = [businessData?.cover];
  const [index, setIndex] = useState(0);

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const [venue, setVenue] = useState([]);

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");

    if (authUser) {
      const bData = JSON.parse(authUser);     
      setBusinessData(bData);
    }
  }, []);

  const { gradients } = colors;
  const { cardContent } = gradients;

  const venue1 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1698398493097salmon_guru_cover.jpg";
  const venue2 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344268462file-x5klq41c73fewjhf.jpg";
  const venue3 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344512535file-wffm0qfttkbexv2w.jpg";
  const venue4 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344397552file-nm3hzzfy2lnfhxdl.jpg";
  const venuename1 = "Salmon Guru";
  const venuename2 = "Sparkle";
  const venuename3 = "Sky Dive";
  const venuename4 = "Street Division";
  const venueaddress1 =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";

  const eventManagment = async (filterArgs = []) => {
    const payload = {
      limit: 10000,
      page: 1,
    };

    const response = await postRequest(`venue/business/my-venue-list`, payload);
    const data = response.data;

    setVenue(data.list);
  };

  useEffect(() => {
    eventManagment();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
      <Card  style={{ background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)" }}>

<div >
  <div className="tab-buttons">
    <button className={ activeTab == 'tab1' ? "active" : "" } onClick={() => handleTabClick("tab1")}>Add Gallery</button>  
    
  </div>
  <div className="tab-content">
    {activeTab === "tab1" && (
     <AddGalleryForm />
    )} {/* Render Table1 when tab1 is active */}
   


  </div>
</div>	


</Card>	  
        {/* <VenueCreateForm /> */}
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddGallery;

import React from "react";

import { Card, Icon } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useHistory } from 'react-router-dom';
import "assets/css/main.css";
const Hotelinfo = ({ name, address, phone, email, website,claimCode,_id }) => {
  const history = useHistory();
  return (
    <Card
      sx={() => ({

        background: "transparent",

        width: "100%",
        display: "flex",

      })}
    >
      <VuiBox className="venue_card" >
        <VuiBox>
          <VuiTypography color="white" variant="h3" fontWeight="bold" mb="20px">
            {name}
          </VuiTypography>
          <VuiTypography color="text" variant="h6" fontWeight="regular" mb="20px">
            {address}
          </VuiTypography>


          <div className="h-details">
            
              { phone && (
                <div className="d-inline">
                <VuiTypography color="text" variant="h6" fontWeight="regular" mb="5px">
                  <i className="fas fa-phone-alt fs-white"></i> <span> {phone} </span>
                </VuiTypography>
                </div>
              
              ) }
             
            
            
              {email && (
                <div className="d-inline">
                <VuiTypography color="text" variant="h6" fontWeight="regular" mb="5px">
                {/* <i className="fas fa-phone-alt fs-white"></i> */}
                <i className="fas fa-envelope fs-white"></i> <span>{email}</span>
              </VuiTypography> </div>
              )}              
           
            
              { website && (
                <div className="d-inline">
                  <VuiTypography color="text" variant="h6" fontWeight="regular" mb="5px">
                    {/* <i className="fas fa-phone-alt fs-white"></i> */}
                    <i className="fas fa-globe"></i> <span>{website}</span>
                  </VuiTypography>
                </div>
              )}
            

            
              { claimCode && (
                <div className="d-inline">
              <VuiTypography color="text" variant="h6" fontWeight="regular" mb="5px">
                {/* <i className="fas fa-phone-alt fs-white"></i> */}
                <i className="fas fa-tag"></i> <span>{claimCode}</span>
              </VuiTypography>

              </div>
              )}
            


          </div>

        </VuiBox>
        <div >
          <button onClick={() =>  history.push(`/updateVenue/${_id}`)} type="button" className="editbtn"> <i className="fas fa-edit" style={{ cursor: "pointer" }}></i></button>
        </div>
      </VuiBox>

      {/* <div className="hotel_main_card">
        <div className="hotel_card">
          <div className="card_head">
            <div className="h-user">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M12.3462 14.6509C12.2114 14.6374 12.0497 14.6374 11.9014 14.6509C8.69363 14.5431 6.14624 11.9148 6.14624 8.68002C6.14624 5.37785 8.81493 2.69568 12.1306 2.69568C15.4327 2.69568 18.1149 5.37785 18.1149 8.68002C18.1014 11.9148 15.5541 14.5431 12.3462 14.6509Z"
                  stroke="white"
                  stroke-width="1.85404"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22.1178 5.39124C24.7326 5.39124 26.8352 7.50732 26.8352 10.1086C26.8352 12.656 24.8135 14.7317 22.2931 14.826C22.1852 14.8125 22.0639 14.8125 21.9426 14.826"
                  stroke="white"
                  stroke-width="1.85404"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.60695 19.6244C2.34521 21.8079 2.34521 25.3662 5.60695 27.5362C9.31346 30.0161 15.3921 30.0161 19.0987 27.5362C22.3604 25.3527 22.3604 21.7944 19.0987 19.6244C15.4056 17.1579 9.32694 17.1579 5.60695 19.6244Z"
                  stroke="white"
                  stroke-width="1.85404"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M24.7192 26.9566C25.6897 26.7544 26.6062 26.3635 27.361 25.784C29.4636 24.207 29.4636 21.6057 27.361 20.0288C26.6197 19.4627 25.7166 19.0853 24.7597 18.8696"
                  stroke="white"
                  stroke-width="1.85404"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="18"
                viewBox="0 0 37 18"
                fill="none"
              >
                <path
                  d="M1.72685 4.53337L1.72685 13.978C1.72685 15.3433 2.83362 16.4501 4.1989 16.4501L11.389 16.4501"
                  stroke="#FF2B9D"
                  stroke-width="1.85404"
                />
                <path
                  d="M35.5444 0.990551L27.7804 8.96466C26.8099 9.96137 25.2085 9.96137 24.238 8.96466L18.2451 2.80964C17.2747 1.81294 15.6732 1.81294 14.7028 2.80964L2.04884 15.8059"
                  stroke="#FF2B9D"
                  stroke-width="1.85404"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div className="h-percentage"> 1.2%</div>
          </div>

          <div className="card_footer">
            <div className="h-follower">
              <h6> all follwer</h6>
              <div className="h-percentage"> 1000</div>
            </div>
            <div className="h-follower">
                </div>
            <div className="h-follower">
           <h6> this month</h6>
           <VuiTypography
          component="a"
          href="#"
          variant="button"
          color="white"
          fontWeight="regular"
          sx={{
            mr: "5px",
            display: "inline-flex",
            justifyContent:"center",
            width: "106px",
            alignItems: "flex-start",
            cursor: "pointer",
            boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
            padding: "12.36px", // Added padding for button appearance
            borderRadius: "15px", // Optional: Rounded corners
            backgroundColor: "#FF26A8", // Choose a suitable button color
// height:"37px",

//             display: flex;
// width: 106px;
// height: 37px;
// padding: 12.36px;
// align-items: flex-start;
// gap: 12.36px;
// flex-shrink: 0;

            "& .material-icons-round": {
              fontSize: "1.125rem",
              transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
            },

            "&:hover, &:focus": {
              backgroundColor: "#FF25A8", // Choose a suitable hover color
            },

            "&:hover .material-icons-round, &:focus .material-icons-round": {
              transform: `translate(6px, 0px)`, // Adjusted hover effect
            },
          }}
        >
          More

        </VuiTypography>
            </div>


            
          </div>
        </div>
        <div className="hotel_card">
          <div className="card_head">
            <div className="h-user">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M12.3462 14.6509C12.2114 14.6374 12.0497 14.6374 11.9014 14.6509C8.69363 14.5431 6.14624 11.9148 6.14624 8.68002C6.14624 5.37785 8.81493 2.69568 12.1306 2.69568C15.4327 2.69568 18.1149 5.37785 18.1149 8.68002C18.1014 11.9148 15.5541 14.5431 12.3462 14.6509Z"
                  stroke="white"
                  stroke-width="1.85404"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22.1178 5.39124C24.7326 5.39124 26.8352 7.50732 26.8352 10.1086C26.8352 12.656 24.8135 14.7317 22.2931 14.826C22.1852 14.8125 22.0639 14.8125 21.9426 14.826"
                  stroke="white"
                  stroke-width="1.85404"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.60695 19.6244C2.34521 21.8079 2.34521 25.3662 5.60695 27.5362C9.31346 30.0161 15.3921 30.0161 19.0987 27.5362C22.3604 25.3527 22.3604 21.7944 19.0987 19.6244C15.4056 17.1579 9.32694 17.1579 5.60695 19.6244Z"
                  stroke="white"
                  stroke-width="1.85404"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M24.7192 26.9566C25.6897 26.7544 26.6062 26.3635 27.361 25.784C29.4636 24.207 29.4636 21.6057 27.361 20.0288C26.6197 19.4627 25.7166 19.0853 24.7597 18.8696"
                  stroke="white"
                  stroke-width="1.85404"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="18"
                viewBox="0 0 37 18"
                fill="none"
              >
                <path
                  d="M1.72685 4.53337L1.72685 13.978C1.72685 15.3433 2.83362 16.4501 4.1989 16.4501L11.389 16.4501"
                  stroke="#FF2B9D"
                  stroke-width="1.85404"
                />
                <path
                  d="M35.5444 0.990551L27.7804 8.96466C26.8099 9.96137 25.2085 9.96137 24.238 8.96466L18.2451 2.80964C17.2747 1.81294 15.6732 1.81294 14.7028 2.80964L2.04884 15.8059"
                  stroke="#FF2B9D"
                  stroke-width="1.85404"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div className="h-percentage"> 1.2%</div>
          </div>

          <div className="card_footer">
            <div className="h-follower">
              <h6> all follwer</h6>
              <div className="h-percentage"> 1000</div>
            </div>
            <div className="h-follower">
                </div>
            <div className="h-follower">
           <h6> this month</h6>
           <VuiTypography
          component="a"
          href="#"
          variant="button"
          color="white"
          fontWeight="regular"
          sx={{
            mr: "5px",
            display: "inline-flex",
            justifyContent:"center",
            width: "106px",
            alignItems: "flex-start",
            cursor: "pointer",
            boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
            padding: "12.36px", // Added padding for button appearance
            borderRadius: "15px", // Optional: Rounded corners
            backgroundColor: "#FF26A8", // Choose a suitable button color
// height:"37px",

//             display: flex;
// width: 106px;
// height: 37px;
// padding: 12.36px;
// align-items: flex-start;
// gap: 12.36px;
// flex-shrink: 0;

            "& .material-icons-round": {
              fontSize: "1.125rem",
              transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
            },

            "&:hover, &:focus": {
              backgroundColor: "#FF25A8", // Choose a suitable hover color
            },

            "&:hover .material-icons-round, &:focus .material-icons-round": {
              transform: `translate(6px, 0px)`, // Adjusted hover effect
            },
          }}
        >
          More

        </VuiTypography>
            </div>


            
          </div>
        </div>
        <div className="hotel_card">
          <div className="card_head">
            <div className="h-user">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M12.3462 14.6509C12.2114 14.6374 12.0497 14.6374 11.9014 14.6509C8.69363 14.5431 6.14624 11.9148 6.14624 8.68002C6.14624 5.37785 8.81493 2.69568 12.1306 2.69568C15.4327 2.69568 18.1149 5.37785 18.1149 8.68002C18.1014 11.9148 15.5541 14.5431 12.3462 14.6509Z"
                  stroke="white"
                  stroke-width="1.85404"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22.1178 5.39124C24.7326 5.39124 26.8352 7.50732 26.8352 10.1086C26.8352 12.656 24.8135 14.7317 22.2931 14.826C22.1852 14.8125 22.0639 14.8125 21.9426 14.826"
                  stroke="white"
                  stroke-width="1.85404"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.60695 19.6244C2.34521 21.8079 2.34521 25.3662 5.60695 27.5362C9.31346 30.0161 15.3921 30.0161 19.0987 27.5362C22.3604 25.3527 22.3604 21.7944 19.0987 19.6244C15.4056 17.1579 9.32694 17.1579 5.60695 19.6244Z"
                  stroke="white"
                  stroke-width="1.85404"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M24.7192 26.9566C25.6897 26.7544 26.6062 26.3635 27.361 25.784C29.4636 24.207 29.4636 21.6057 27.361 20.0288C26.6197 19.4627 25.7166 19.0853 24.7597 18.8696"
                  stroke="white"
                  stroke-width="1.85404"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="18"
                viewBox="0 0 37 18"
                fill="none"
              >
                <path
                  d="M1.72685 4.53337L1.72685 13.978C1.72685 15.3433 2.83362 16.4501 4.1989 16.4501L11.389 16.4501"
                  stroke="#FF2B9D"
                  stroke-width="1.85404"
                />
                <path
                  d="M35.5444 0.990551L27.7804 8.96466C26.8099 9.96137 25.2085 9.96137 24.238 8.96466L18.2451 2.80964C17.2747 1.81294 15.6732 1.81294 14.7028 2.80964L2.04884 15.8059"
                  stroke="#FF2B9D"
                  stroke-width="1.85404"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div className="h-percentage"> 1.2%</div>
          </div>

          <div className="card_footer">
            <div className="h-follower">
              <h6> all follwer</h6>
              <div className="h-percentage"> 1000</div>
            </div>
            <div className="h-follower">
                </div>
            <div className="h-follower">
           <h6> this month</h6>
           <VuiTypography
          component="a"
          href="#"
          variant="button"
          color="white"
          fontWeight="regular"
          sx={{
            mr: "5px",
            display: "inline-flex",
            justifyContent:"center",
            width: "106px",
            alignItems: "flex-start",
            cursor: "pointer",
            boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
            padding: "12.36px", // Added padding for button appearance
            borderRadius: "15px", // Optional: Rounded corners
            backgroundColor: "#FF26A8", // Choose a suitable button color
// height:"37px",

//             display: flex;
// width: 106px;
// height: 37px;
// padding: 12.36px;
// align-items: flex-start;
// gap: 12.36px;
// flex-shrink: 0;

            "& .material-icons-round": {
              fontSize: "1.125rem",
              transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
            },

            "&:hover, &:focus": {
              backgroundColor: "#FF25A8", // Choose a suitable hover color
            },

            "&:hover .material-icons-round, &:focus .material-icons-round": {
              transform: `translate(6px, 0px)`, // Adjusted hover effect
            },
          }}
        >
          More

        </VuiTypography>
            </div>


            
          </div>
        </div>
      </div> */}
    </Card>
  );
};

export default Hotelinfo;


import { useState, useEffect, useMemo } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import team1 from "assets/images/avatar1.png";
import team2 from "assets/images/avatar2.png";
import team3 from "assets/images/avatar3.png";
import team4 from "assets/images/avatar4.png";
// Images
import profile1 from "assets/images/profile-1.png";
import profile2 from "assets/images/profile-2.png";
import profile3 from "assets/images/profile-3.png";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import Footer from "examples/Footer";
// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import Welcome from "../profile/components/Welcome/index";
import CarInformations from "./components/CarInformations";

function Overview() {
 
  const [businessData, setBusinessData ] = useState(null);  
 

  useEffect(() => {

    const authUser = localStorage.getItem("authUser")

    if(authUser){      
      const bData = JSON.parse(authUser);      
      setBusinessData(bData);  
    }    
    
  }, []);

  return (
    <DashboardLayout>
      <Header  name={businessData?.name || "Whosin User"} email={businessData?.email || "test@mail.com"} logo={businessData?.logo || ""} />
      <VuiBox mt={5} mb={3}>
        <Grid
          container
          spacing={3}
          sx={({ breakpoints }) => ({
            [breakpoints.only("xl")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          })}
        >
          <Grid
            item
            xs={12}
            xl={9}
            xxl={9}
            sx={({ breakpoints }) => ({
              minHeight: "400px",
              [breakpoints.only("xl")]: {
                gridArea: "1 / 1 / 2 / 2",
              },
            })}
          >
            <Welcome img={businessData?.cover || ""} name={businessData?.name || " Whosin User"} />
          </Grid>
          {/* <Grid
            item
            xs={12}
            xl={5}
            xxl={6}
            sx={({ breakpoints }) => ({
              [breakpoints.only("xl")]: {
                gridArea: "2 / 1 / 3 / 3",
              },
            })}
          >
            <CarInformations />
          </Grid> */}
          <Grid
            item
            xs={12}
            xl={3}
            xxl={3}
            sx={({ breakpoints }) => ({
              [breakpoints.only("xl")]: {
                gridArea: "1 / 2 / 2 / 3",
              },
            })}
          >
            <ProfileInfoCard              
              title="About"
              description={businessData?.about || "Whosin User"}
              info={{
                fullName: businessData?.name || "Whosin User",
                mobile: businessData?.phone || "1234567890",
                email: businessData?.email  ,
                location: businessData?.address || "Dubai, UAE",
                website: businessData?.website || "www.whosin.com",
              }}
              social={[
                // {
                //   link: "https://www.facebook.com/CreativeTim/",
                //   icon: <FacebookIcon />,
                //   color: "facebook",
                // },
                // {
                //   link: "https://twitter.com/creativetim",
                //   icon: <TwitterIcon />,
                //   color: "twitter",
                // },
                // {
                //   link: "https://www.instagram.com/creativetimofficial/",
                //   icon: <InstagramIcon />,
                //   color: "instagram",
                // },
              ]}
            />
          </Grid>
        </Grid>
      </VuiBox>   

      <Footer />
    </DashboardLayout>
  );
}

export default Overview;


import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import LocationPicker from "react-location-picker";
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from "react-google-maps";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import {
  Container,
  TextField,
  Box,
  Button,
  createTheme,
  ThemeProvider,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  Modal,
  Typography,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import Multiselect from "components/Multiselect/MultiselectCommon";
import { postRequest } from "lib/utils";
import { postRequestForm ,putRequestForm} from "lib/utils";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

const formElementStyle = {
  borderRadius: "14px", // Border radius for form elements
  color: "#FFFFFF", // Text color for the form elements
  "& .MuiOutlinedInput-root": {
    // Targeting the input root of TextField and Select
    borderRadius: "14px", // Border radius for TextField and Select components
    color: "#FFFFFF", // Text color for the input content
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.23)", // Border color
    },
    "&.Mui-focused fieldset": {
      borderColor: "white", // Border color on focus
    },
  },
  "& .MuiInputLabel-root": {
    // Targeting the label of the TextField and Select
    color: "rgba(255, 255, 255, 0.7)", // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    // Label color when the input is focused
    color: "#FFFFFF", // Focused label color
  },
  "& .MuiSelect-select": {
    // Targeting the select input
    color: "#FFFFFF", // Text color for the select input
  },

  // Apply similar styles for Checkbox, Radio, and other components as needed
};

const CustomFileDropArea = styled("div")(({ theme }) => ({
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.primary.main}`,
  color: theme.palette.text.primary,
  textAlign: "center",
  cursor: "pointer",
  marginBottom: theme.spacing(2),
  maxWidth: "628px",
  marginLeft: "10px",
}));

const useStyles = makeStyles({
  formContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    gridColumn: "1 / -1",
    justifySelf: "center",
    marginTop: theme.spacing(2),
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

function Editprofile() {
  let business_id = localStorage.getItem("business_id");
  const [venueData, setVenueData] = useState(null);
  const [businessData, setBusinessData] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);
  const [remoteLogo, setremoteLogo] = useState([]);
  const [selectedCover, setselectedCover] = useState([]);
  const history = useHistory(); 
  const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Handle logo image files as needed
      
      const file = acceptedFiles[0];
      setremoteLogo(file);

      const reader = new FileReader();
      reader.onload = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);      
      
    },
    accept: "image/*", // Accept only image files
  });

  const { getRootProps: getCoverRootProps, getInputProps: getCoverInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Handle cover image files as needed
      const file = acceptedFiles[0];
      setselectedCover(file);
      

      const reader = new FileReader();
      reader.onload = () => {
        setCoverPreview(reader.result);
      };
      reader.readAsDataURL(file);
    },
    
    accept: "image/*", // Accept only image files
  });

  const isDesktop = useMediaQuery("(min-width:600px)");

  const classes = useStyles();

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");

    if (authUser) {
      const bData = JSON.parse(authUser);      

      setBusinessData(bData);

      setVenueData({
        name: bData.name,
        phone: bData.phone,
        email: bData.email,
        website: bData.website,
        logo: bData.logo,
        cover: bData.cover,
        description: bData.about,
        // Add more fields with their default values if needed
      });
      setLogoPreview(bData.logo);
      setCoverPreview(bData.cover);
    }
  }, []);

  const updateValue = (index, value) => {
    const list = { ...venueData };
    list[index] = value;
    setVenueData(list);    
  };

  const Bussinesprofileupdated = async (e) => {
    e.preventDefault();

    if (!venueData.name) {
      toast.error("Please enter venue name");
      return;
    }

    if (!venueData.description) {
      toast.error("Please enter venue description");
      return;
    }
    if (!venueData.phone) {
      toast.error("Please enter venue phone");
      return;
    }
    // check if phone is valid
    const phoneRegex = /^(\+\d{1,3}[\s-]?)?\d{10}$/;
    if (!phoneRegex.test(venueData.phone)) {
      toast.error("Please enter a valid phone number");
      return;
    }


    if (!venueData.email) {
      toast.error("Please enter venue email");
      return;
    }
    // check if email is valid
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(venueData.email)) {
      toast.error("Please enter valid email");
      return;
    }

    if (!venueData.website) {
      toast.error("Please enter venue website");
      return;
    }

    if (!remoteLogo && !logoPreview) {
      toast.error("Please select venue logo");
      return;
    }

    if (!selectedCover && !coverPreview) {
      toast.error("Please select venue cover");
      return;
    }

    const formData = new FormData();
    const selectedImageForm = new FormData();    
    selectedImageForm.append(`image`, remoteLogo);
    const selectedImageRes = await postRequestForm("comman/doc/upload", selectedImageForm);
    const selectedImageForm2 = new FormData();
    selectedImageForm2.append(`image`, selectedCover); 
    const selectedImageRes2 = await postRequestForm("comman/doc/upload", selectedImageForm2);

    formData.append("name", venueData.name);
    formData.append("description", venueData.description);
    formData.append("phone", venueData.phone);
    formData.append("email", venueData.email);
    formData.append("website", venueData.website);

    if (selectedImageRes?.data?.url) {
      formData.append("logo", selectedImageRes.data.url||venueData?.logo);
    } else {
      console.error("Image URL not found in the response.");
    }
    if (selectedImageRes2?.data?.url) {
      formData.append("cover", selectedImageRes2.data.url ||venueData?.cover);
    } else {
      console.error("Image URL not found in the response.");
    }

    

    const response = await postRequestForm("venue/business/profile-update", formData);
    // setLoading(false);

    if (response.status == 1) {
        let data = response.data       
      toast.success(response.message);
      localStorage.setItem("authUser", JSON.stringify(data));
      localStorage.setItem("BLogo", data.logo);
      localStorage.setItem("BName", data.name);
      setTimeout(() => {
        history.push("/profile");
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <DashboardLayout>
        <ToastContainer/>
      <DashboardNavbar />
      <ThemeProvider theme={theme}>
        {/* <ToastContainer /> */}
       


        <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <div className="tab-buttons" style={{ marginBottom: 30 }}>
              <button className="active">Updated Profile</button>             
            </div>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
              display: "grid",
              gridTemplateColumns: isDesktop ? "1fr 1fr" : "1fr",
              gap: 2,
              "& .MuiFormControl-root": {
                m: 1,
                ...formElementStyle, // Applying the custom styles to each form control
              },
            }}
          >
            <TextField
              label="Name"
              onChange={(e) => updateValue("name", e.target.value)}
              name="name"
              value={venueData?.name || ""}
              variant="outlined"
              fullWidth
            />

            <TextField
              label="Phone"
              onChange={(e) => updateValue("phone", e.target.value)}
              name="phone"
              variant="outlined"
              fullWidth
              value={venueData?.phone || ""}
            />

            <TextField
              label="Email"
              onChange={(e) => updateValue("email", e.target.value)}
              name="email"
              variant="outlined"
              fullWidth
              value={venueData?.email || ""}
            />
            <TextField
              label="Website"
              onChange={(e) => updateValue("website", e.target.value)}
              name="website"
              variant="outlined"
              fullWidth
              value={venueData?.website || ""}
            />

            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              name="description"
              value={venueData?.description || ""}
              onChange={(e) => updateValue("description", e.target.value)}
              rows={4} // You can set the number of rows to suit your design needs
            />
          </Box>

          <Box
            noValidate
            autoComplete="off"
            sx={{
              display: "grid",
              marginTop: "20px",
              gridTemplateColumns: isDesktop ? "1fr 1fr" : "1fr",
              gap: 2,
              "& .MuiFormControl-root": {
                m: 1,
                ...formElementStyle, // Applying the custom styles to each form control
              },
            }}
          >
            <CustomFileDropArea
              {...getLogoRootProps()}
              style={{
                height: 250,
                marginTop: "12px",
                backgroundColor: "#2D3547",
                borderStyle: "dashed",
                borderWidth: "2px",
                borderColor: "grey",
                borderRadius: "12px",
              }}
            >
             
              <input {...getLogoInputProps()} />
              {logoPreview ? <img src={logoPreview} alt="Preview" style={{ height: '100%' }} /> :
              <span style={{ color: "white" }}>Drag 'n' drop some files here, or click to select files</span>}
            </CustomFileDropArea>{" "}
            <CustomFileDropArea
              {...getCoverRootProps()}
              style={{
                height: 250,
                marginTop: "12px",
                backgroundColor: "#2D3547",
                borderStyle: "dashed",
                borderWidth: "2px",
                borderColor: "grey",
                borderRadius: "12px",
              }}
            >
              
              <input {...getCoverInputProps()} />
              {coverPreview ? <img src={coverPreview} alt="Preview" style={{ height: '100%' }} /> :
              <span style={{ color: "white" }}>Drag 'n' drop some files here, or click to select files</span>}
            </CustomFileDropArea>
          </Box>

          <div
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "flex-end",
              marginBottom: "600px",
            }}
          >
            <Button
              type="button"
              variant="contained"
              style={{
                maxWidth: "150px",
                background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
                alignItems: "flex-end",
              }}
              fullWidth
              onClick={(e) => Bussinesprofileupdated(e)}
            >
              Submit
            </Button>
          </div>
        </Container>
      </ThemeProvider>
    </DashboardLayout>
  );
}

export default Editprofile;

/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  Container,
  TextField,
  Box,
  Button,
  createTheme,
  ThemeProvider,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  Modal,
  Typography,
  IconButton,
  TextareaAutosize,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { useHistory } from "react-router-dom";
import { postRequest, postRequestForm } from "../../lib/utils";
import { ToastContainer, toast } from "react-toastify";
import VuiBox from "components/VuiBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import colors from "assets/theme/base/colors";

import "assets/css/main.css";

import "./tab-style.css";
import VenueCreateForm from "./VenueForm";
import VenueCreateForm2 from "./VenueForm2";
import VenueCreateForm3 from "./VenueForm3";
import VenueCreateForm4 from "./VenueForm4";

function VenueCreate() {
  const [businessData, setBusinessData] = useState(null);
  const [activeTab, setActiveTab] = useState("tab1"); // Initialize with the default active tab
  const [currentPage, setCurrentPage] = useState(1);
  
  const rowsPerPage = 10;

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  // const visibleRows = rows.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const images = [businessData?.cover];
  const [index, setIndex] = useState(0);

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const [venue, setVenue] = useState([]);

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");

    if (authUser) {
      const bData = JSON.parse(authUser);   
      setBusinessData(bData);
    }
  }, []);

  const { gradients } = colors;
  const { cardContent } = gradients;

  const venue1 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1698398493097salmon_guru_cover.jpg";
  const venue2 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344268462file-x5klq41c73fewjhf.jpg";
  const venue3 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344512535file-wffm0qfttkbexv2w.jpg";
  const venue4 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344397552file-nm3hzzfy2lnfhxdl.jpg";
  const venuename1 = "Salmon Guru";
  const venuename2 = "Sparkle";
  const venuename3 = "Sky Dive";
  const venuename4 = "Street Division";
  const venueaddress1 =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";

  const eventManagment = async (filterArgs = []) => {
    const payload = {
      limit: 10000,
      page: 1,
    };

    const response = await postRequest(`venue/business/my-venue-list`, payload);
    const data = response.data;

    setVenue(data.list);
  };

  useEffect(() => {
    eventManagment();
  }, []);

  // form data logic

  let business_id = localStorage.getItem("business_id");
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);
  const [venueData, setVenueData] = useState(null);
  const [themeList, setThemeList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [cuisineList, setCuisineList] = useState([]);
  const [BusinessOptions, setBusinessOptions] = useState([]);
  const [remoteLogo, setremoteLogo] = useState(null);
  const [selectedCover, setselectedCover] = useState(null);

  const [defaultTheme, setDefaultTheme] = useState([]);
  const [defaultThemeItem, setDefaultThemeItem] = useState([]);

  const [defaultMusic, setDefaultMusic] = useState([]);
  const [defaultMusicItem, setDefaultMusicItem] = useState([]);

  const [defaultFeature, setDefaultFeature] = useState([]);
  const [defaultFeatureItem, setDefaultFeatureItem] = useState([]);

  const [defaultCuisine, setDefaultCuisine] = useState([]);
  const [defaultCuisineItem, setDefaultCuisineItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [location, setLocation] = useState({ latitude: 25.2048345, longitude: 55.2708345 });

  const isDesktop = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    fetchTheme();
    fetchMusic();
    fetchFeature();
    fetchCuisine();
    fetchListbusiness();
  }, []);

  const fetchListbusiness = async () => {
    const param = { page: 1, limit: 100000 };
    const business = await postRequest("venue/business/list", param);

    const options = business.data.list;  
    setBusinessOptions(options);
  };

  const fetchTheme = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("venue/theme/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setThemeList(options);
      setThemeList(options);
      const defaultThemeIds = options
        .filter((option) => defaultTheme.includes(option.name))
        .map((filteredOption) => filteredOption.id);
      setDefaultThemeItem(defaultThemeIds || []);      
    }
  };

  const fetchMusic = async () => {
    const param = { page: 1, limit: 100000 };
    const music = await postRequest("venue/music/list", param);
    if (music.status == 1) {
      const options = music.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setMusicList(options);
      const defaultMusicIds = options
        .filter((option) => defaultMusic.includes(option.name))
        .map((filteredOption) => filteredOption.id);
      setDefaultMusicItem(defaultMusicIds || []);
    }
  };

  const fetchFeature = async () => {
    const param = { page: 1, limit: 100000 };
    const feature = await postRequest("venue/feature/list", param);
    if (feature.status == 1) {
      const options = feature.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setFeatureList(options);
      const defaultFeatureIds = options
        .filter((option) => defaultFeature.includes(option.name))
        .map((filteredOption) => filteredOption.id);
      setDefaultFeatureItem(defaultFeatureIds || []);
    }
  };

  const fetchCuisine = async () => {
    const param = { page: 1, limit: 100000 };
    const cuisine = await postRequest("venue/cuisine/list", param);
    if (cuisine.status == 1) {
      const options = cuisine.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setCuisineList(options);
      const defaultCuisineIds = options
        .filter((option) => defaultCuisine.includes(option.name))
        .map((filteredOption) => filteredOption.id);
      setDefaultCuisineItem(defaultCuisineIds || []);
    }
  };

  const msUpdateTheme = (selectedList) => {
    setDefaultThemeItem(selectedList);
    setSelectedTheme(selectedList);
  };

  const msUpdateMusic = (selectedList) => {
    setSelectedMusic(selectedList);
    setDefaultMusicItem(selectedList);
  };

  const msUpdateFeature = (selectedList) => {
    setSelectedFeature(selectedList);
    setDefaultFeatureItem(selectedList);
  };

  const msUpdateCuisine = (selectedList) => {
    setSelectedCuisine(selectedList);
    setDefaultCuisineItem(selectedList);
  };

  const updateValue = (index, value) => {
    const list = { ...venueData };
    list[index] = value;
    setVenueData(list);    
  };

  // date time logic
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;    
    setTimeOptions(newTimeOptions);
  };

  const formOneValidation = () => {
    if (!venueData?.name) {
      // scroll to top
      window.scrollTo(0, 0);
      toast.error("Please enter name");
      return false;
    }

    // check phoneCOde is valid

    if (!venueData?.phoneCode) {
      window.scrollTo(0, 0);
      toast.error("Please enter phone code");
      return false;
    }

    if (!venueData?.phone) {
      window.scrollTo(0, 0);
      toast.error("Please enter phone");
      return false;
    }

    const phoneRegex = /^(\+\d{1,3}[\s-]?)?\d{10}$/;
    if (!phoneRegex.test(`${venueData.phoneCode} ${venueData.phone}`)) {
      window.scrollTo(0, 0);
      toast.error("Please enter a valid phone number");
      return;
    }

    if (!venueData?.address) {
      window.scrollTo(0, 0);
      toast.error("Please enter address");
      return false;
    }
    if (!venueData?.about) {
      window.scrollTo(0, 0);
      toast.error("Please enter about");
      return false;
    }
    if (venueData?.email) {
      const emailRegex = /\S+@\S+\.\S+/;
      if (!emailRegex.test(venueData.email)) {
        window.scrollTo(0, 0);
        toast.error("Please enter a valid email address");
        return false;
      }
    }

    setActiveTab("tab2");
  };

  const formTwoValidation = () => {
    const { latitude, longitude } = location;

    if (!latitude) {
      window.scrollTo(0, 0);
      toast.error("Please enter latitude");
      return false;
    }

    if (!longitude) {
      window.scrollTo(0, 0);
      toast.error("Please enter longitude");
      return false;
    }

    if (!timeOptions) {
      window.scrollTo(0, 0);
      toast.error("Please enter time");
      return false;
    }

    if (!days) {
      window.scrollTo(0, 0);
      toast.error("Please enter days");
      return false;
    }

    // select days and time is not empty
    // if (days.length > 0 && timeOptions.length > 0) {
    //   window.scrollTo(0, 0);
    //   toast.error("Please select days and time");
    //   return false;
    // }

    // we need to validate endTime is greater than startTime

    let allTimeChecked = timeOptions.map((time) => time.checked);

    if (!allTimeChecked.includes(true)) {
      window.scrollTo(0, 0);
      toast.error("Please select days and time");
      return false;
    }

    let isValid = true;
    timeOptions.forEach((time) => {
      if (time.open >= time.close && time.checked) {
        isValid = false;
      }
    });

    if (!isValid) {
      // scroll to top
      window.scrollTo(0, 0);
      toast.error("Please select valid time endtime should be greater than starttime");
      return false;
    }

    setActiveTab("tab3");
  };

  const formThreeValidation = () => {
    if (selectedMusic.length === 0) {
      // scroll to top

      window.scrollTo(0, 0);
      toast.error("Please select music");
      return;
    }

    if (selectedTheme.length === 0) {
      window.scrollTo(0, 0);
      toast.error("Please select theme");
      return;
    }

    if (selectedCuisine.length === 0) {
      window.scrollTo(0, 0);
      toast.error("Please select cuisine");
      return;
    }

    if (selectedFeature.length === 0) {
      window.scrollTo(0, 0);
      toast.error("Please select feature");
      return;
    }

    setActiveTab("tab4");
  };

  const Createvenue = async (e) => {
    e.preventDefault();

    if (!venueData.name) {
      toast.error("Please enter venue name");
      return;
    }
    if (!venueData.about) {
      toast.error("Please enter venue about");
      return;
    }
    if (!venueData.address) {
      toast.error("Please enter venue address");
      return;
    }
    if (!venueData.phone) {
      toast.error("Please enter venue phone");
      return;
    }

    // if phonecode is empty set to UAE
    if (!venueData.phoneCode) {
      venueData.phoneCode = "+971";
    }

    //check if phone is valid
    // const phoneRegex = /^(\+\d{1,3}[\s-]?)?\d{10}$/;
    // if (!phoneRegex.test(venueData.phone)) {
    //   toast.error("Please enter valid phone number");
    //   return;
    // }

    // if (!venueData.email) {
    //   toast.error("Please enter venue email");
    //   return;
    // }

    //check if email is valid
   

    if(venueData?.email){
      const emailRegex = /\S+@\S+\.\S+/;
      if (!emailRegex.test(venueData.email)) {
        toast.error("Please enter valid email");
        return;
      }
    }

    
    // if (!venueData.website) {
    //   toast.error("Please enter venue website");
    //   return;
    // }

    // if (!venueData.booking_url) {
    //   toast.error("Please enter venue booking url");
    //   return;
    // }
    // if (!venueData.menu_url) {
    //   toast.error("Please enter venue menu url");
    //   return;
    // }
    if (!venueData.dress_code) {
      toast.error("Please enter venue dress code");
      return;
    }

    if (!location.latitude) {
      toast.error("Please enter venue latitude");
      return;
    }

    if (!location.longitude) {
      toast.error("Please enter venue longitude");
      return;
    }
    if (!remoteLogo && !tempLogo) {
      toast.error("Please select venue logo");
      return;
    }

    if (!selectedCover && !tempCover) {
      toast.error("Please select venue cover");
      return;
    }

    if (!selectedTheme.length) {
      toast.error("Please select venue theme");
      return;
    }

    if (!selectedFeature.length) {
      toast.error("Please select venue feature");
      return;
    }

    if (!selectedCuisine.length) {
      toast.error("Please select venue cuisine");
      return;
    }
    if (!timeOptions.length) {
      toast.error("Please select time Options ");
      return;
    }
    

    const formData = new FormData();

    const selectedImageForm = new FormData();
  
    selectedImageForm.append(`image`, remoteLogo);
    const selectedImageRes = await postRequestForm("main/image/upload", selectedImageForm);
    

    const selectedImageForm2 = new FormData();
    
    selectedImageForm2.append(`image`, selectedCover);
    const selectedImageRes2 = await postRequestForm("main/image/upload", selectedImageForm2);
    

    formData.append("businessId", business_id);
    formData.append("name", venueData.name);
    formData.append("about", venueData.about);
    formData.append("address", venueData.address);
    formData.append("phone", `${venueData.phoneCode} ${venueData.phone}`);
    formData.append("email", venueData.email == 'undefined'  ? '' : venueData.email || '');
    formData.append("website", venueData.website == 'undefined'  ? '' : venueData.website || '');
    formData.append("latitude", location.latitude);
    formData.append("longitude", location.longitude);
    formData.append("booking_url", venueData.booking_url == 'undefined'  ? '' : venueData.booking_url || '');
    formData.append("menu_url", venueData.menu_url == 'undefined'  ? '' : venueData.menu_url || '');
    formData.append("dress_code", venueData.dress_code == 'undefined'  ? '' : venueData.dress_code || '');
    formData.append("discountText", venueData.discountText);
    formData.append("claimCode", venueData.claimCode);
    // formData.append("tempLogo", tempLogo);
    // formData.append("tempCover", tempCover);

    if (selectedImageRes?.data?.url) {
      formData.append("cover", selectedImageRes.data.url);
    } else {
      console.error("Image URL not found in the response.");
    }
    if (selectedImageRes2?.data?.url) {
      formData.append("logo", selectedImageRes2.data.url);
    } else {
      console.error("Image URL not found in the response.");
    }

    formData.append("isAllowReview", venueData.isAllowReview);
    formData.append("isAllowRatting", venueData.isAllowRatting);

    selectedTheme.forEach((item) => {
      formData.append("themes[]", item);
    });

    selectedMusic.forEach((item) => {
      formData.append("music[]", item);
    });

    selectedFeature.forEach((item) => {
      formData.append("features[]", item);
    });

    selectedCuisine.forEach((item) => {
      formData.append("cuisines[]", item);
    });

    timeOptions.forEach((item, index) => {
      if (item.checked) {
        formData.append(`days[]`, days[index].toString().toLowerCase());
        formData.append(`open_time[]`, item.open);
        formData.append(`close_time[]`, item.close);
      }
    });

    const response = await postRequestForm("venue/add", formData);
    // setLoading(false);

    if ((response.status = 1)) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/dashboard");
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <DashboardLayout>
      <ToastContainer />
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card style={{ background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)" }}>
          <div>
            <div className="tab-buttons">
              <button
                className={activeTab == "tab1" ? "active" : ""}
                onClick={() => setActiveTab("tab1")}
              >
                Basic Details
              </button>
              <button
                className={activeTab == "tab2" ? "active" : ""}
                onClick={() => formOneValidation()}
              >
                Select Location & Time
              </button>
              <button
                className={activeTab == "tab3" ? "active" : ""}
                onClick={() => formTwoValidation()}
              >
                Select Features
              </button>
              <button
                className={activeTab == "tab4" ? "active" : ""}
                onClick={() => formThreeValidation()}
              >
                Upload Image
              </button>
            </div>
            <div className="tab-content">
              {activeTab === "tab1" && (
                <VenueCreateForm
                  updateValue={updateValue}
                  venueData={venueData}
                  onSubmit={() => setActiveTab("tab2")}
                />
              )}
              {activeTab === "tab2" && (
                <VenueCreateForm2
                  onPrev={() => setActiveTab("tab1")}
                  updateTiming={updateTiming}
                  setTimeOptions={setTimeOptions}
                  timeOptions={timeOptions}
                  days={days}
                  location={location}
                  setLocation={setLocation}
                  onSubmit={() => setActiveTab("tab3")}
                />
              )}
              {activeTab === "tab3" && (
                <VenueCreateForm3
                  onPrev={() => setActiveTab("tab2")}
                  selectedFeature={defaultFeatureItem}
                  selectedMusic={defaultMusicItem}
                  selectedTheme={defaultThemeItem}
                  selectedCuisine={defaultCuisineItem}
                  msUpdateFeature={msUpdateFeature}
                  featureList={featureList}
                  msUpdateCuisine={msUpdateCuisine}
                  cuisineList={cuisineList}
                  msUpdateTheme={msUpdateTheme}
                  themeList={themeList}
                  msUpdateMusic={msUpdateMusic}
                  musicList={musicList}
                  onSubmit={() => setActiveTab("tab4")}
                />
              )}
              {activeTab === "tab4" && (
                <VenueCreateForm4
                  onPrev={() => setActiveTab("tab3")}
                  setselectedCover={setselectedCover}
                  selectedCover={selectedCover}
                  setremoteLogo={setremoteLogo}
                  remoteLogo={remoteLogo}
                  Createvenue={Createvenue}
                />
              )}
            </div>
          </div>
        </Card>
        {/* <VenueCreateForm /> */}
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default VenueCreate;

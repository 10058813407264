import React, { useEffect, useState } from "react";
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from "react-google-maps";
import "react-toastify/dist/ReactToastify.css";
import { Container, Box, Button, createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";

import { postRequestForm } from "lib/utils";

import DayTimePicker from "./component/Daypicker";
import { set } from "date-fns";

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

const formElementStyle = {
  borderRadius: "14px", // Border radius for form elements
  color: "#FFFFFF", // Text color for the form elements
  "& .MuiOutlinedInput-root": {
    // Targeting the input root of TextField and Select
    borderRadius: "14px", // Border radius for TextField and Select components
    color: "#FFFFFF", // Text color for the input content
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.23)", // Border color
    },
    "&.Mui-focused fieldset": {
      borderColor: "white", // Border color on focus
    },
  },
  "& .MuiInputLabel-root": {
    // Targeting the label of the TextField and Select
    color: "rgba(255, 255, 255, 0.7)", // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    // Label color when the input is focused
    color: "#FFFFFF", // Focused label color
  },
  "& .MuiSelect-select": {
    // Targeting the select input
    color: "#FFFFFF", // Text color for the select input
  },

  // Apply similar styles for Checkbox, Radio, and other components as needed
};

const CustomFileDropArea = styled("div")(({ theme }) => ({
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.primary.main}`,
  color: theme.palette.text.primary,
  textAlign: "center",
  cursor: "pointer",
  marginBottom: theme.spacing(2),
  maxWidth: "628px",
  marginLeft: "10px",
}));

const useStyles = makeStyles({
  formContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    gridColumn: "1 / -1",
    justifySelf: "center",
    marginTop: theme.spacing(2),
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

function VenueCreateForm({ Createvenue, defaultData, onPrev }) {
  let business_id = localStorage.getItem("business_id");
  const [remoteLogo, setremoteLogo] = useState("");
  const [previewLogo, setpreviewLogo] = useState("");
  const [previewCover, setpreviewCover] = useState("");
  const [selectedCover, setselectedCover] = useState("");
  const [submitEnable, setsubmitEnable] = useState(false);
  const [selectedCoverRes, setselectedCoverRes] = useState(null);
  const [remoteLogoRes, setremoteLogoRes] = useState(null);

  useEffect(() => {
    if (defaultData) {
      setpreviewLogo(defaultData?.logo);
      setpreviewCover(defaultData?.cover);

      if (defaultData?.logo && defaultData?.cover) {
        setsubmitEnable(true);
      }
    }
  }, [defaultData]);

  const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Handle logo image files as needed
      setremoteLogoRes(acceptedFiles[0]);      
    },
    accept: "image/*", // Accept only image files
  });

  const { getRootProps: getCoverRootProps, getInputProps: getCoverInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Handle cover image files as needed
      setselectedCoverRes(acceptedFiles[0]);
     
    },
    accept: "image/*", // Accept only image files
  });
  useEffect(() => {
    if (!selectedCoverRes) return;
    handlerCover();
  }, [selectedCoverRes]);

  useEffect(() => {
    if (!remoteLogoRes) return;
    handlerLogo();
  }, [remoteLogoRes]);

  const isDesktop = useMediaQuery("(min-width:600px)");

  const classes = useStyles();

  const handlerCover = async (event, page) => {
    setsubmitEnable(false);
    const selectedImageForm = new FormData();
    selectedImageForm.append(`image`, selectedCoverRes);
    const selectedImageRes = await postRequestForm("comman/doc/upload", selectedImageForm);
    const url = selectedImageRes?.data?.url || "";
    setselectedCover(url);
    setpreviewCover(url);

    if (remoteLogo && previewLogo) {
      setsubmitEnable(true);
    }
  };

  const handlerLogo = async (event, page) => {
    setsubmitEnable(false);
    const selectedImageForm = new FormData();
    selectedImageForm.append(`image`, remoteLogoRes);
    const selectedImageRes = await postRequestForm("comman/doc/upload", selectedImageForm);
    const url = selectedImageRes?.data?.url || "";
    setremoteLogo(url);
    setpreviewLogo(url);
    if (selectedCover && previewCover) {
      setsubmitEnable(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <ToastContainer /> */}
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <Box
          noValidate
          autoComplete="off"
          sx={{
            display: "grid",
            marginTop: "20px",
            gridTemplateColumns: isDesktop ? "1fr 1fr" : "1fr",
            gap: 2,
            "& .MuiFormControl-root": {
              m: 1,
              ...formElementStyle, // Applying the custom styles to each form control
            },
          }}
        >
          <CustomFileDropArea
            {...getLogoRootProps()}
            style={{
              height: 250,
              marginTop: "12px",
              backgroundColor: "#2D3547",
              borderStyle: "dashed",
              borderWidth: "2px",
              borderColor: "grey",
              borderRadius: "12px",
            }}
          >
            {previewLogo ? (
              <img src={previewLogo} alt="Preview" style={{ height: "100%" }} />
            ) : (
              <>
                <span style={{ color: "white" }}>
                  Drag 'n' drop some files here, or click to select files
                </span>
                <i
                  className="far fa-file-image fa-lg"
                  style={{ color: "#ffff", fontSize: "3rem" }}
                ></i>{" "}
              </>
            )}
            <input {...getLogoInputProps()} />
          </CustomFileDropArea>{" "}
          <CustomFileDropArea
            {...getCoverRootProps()}
            style={{
              height: 250,
              marginTop: "12px",
              backgroundColor: "#2D3547",
              borderStyle: "dashed",
              borderWidth: "2px",
              borderColor: "grey",
              borderRadius: "12px",
            }}
          >
            {previewCover ? (
              <img src={previewCover} alt="Preview" style={{ height: "100%" }} />
            ) : (
              <>
                <span style={{ color: "white" }}>
                  Drag 'n' drop some files here, or click to select files
                </span>{" "}
                <i
                  className="far fa-file-image fa-lg"
                  style={{ color: "#ffff", fontSize: "3rem" }}
                ></i>{" "}
              </>
            )}

            <input {...getCoverInputProps()} />
          </CustomFileDropArea>
        </Box>

        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="button"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
              alignItems: "flex-end",
              borderRadius: "10px",
            }}
            fullWidth
            onClick={(e) => onPrev()}
          >
            Previous
          </Button>
          <Button
            type="button"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
              alignItems: "flex-end",
            }}
            fullWidth
            disabled={!submitEnable}
            onClick={(e) => Createvenue(e)}
          >
            Submit
          </Button>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default VenueCreateForm;



import { useState } from "react";
import Swal from "sweetalert2";

// react-router-dom components
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import logo from "assets/images/logo-main.webp";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";


import { postRequest } from "../../../lib/utils";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgSignIn from "assets/images/signInImage.png";

function ResetPassword() {
  const [rememberMe, setRememberMe] = useState(true);
  let history = useHistory();
  const [email, setEmail] = useState("");

  async function resetpassword() {
  let data = { email: email };
  postRequest("venue/business/forget-password", data).then((data) => {
    let log = data;
    if (log.status) {
      Swal.fire({
        title: 'Success!',
        text: log.message,
        icon: 'success',
        confirmButtonText: 'OK',
        timer: 1500, // closes after 1500ms
        showConfirmButton: false,
        timerProgressBar: true,
      });      

      setTimeout(() => {
        history.push("/sign-in");
      }, 1500); // adjusted time to match the SweetAlert timer
    } else {
      Swal.fire({
        title: 'Error!',
        text: log.message,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  });
}
  

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <CoverLayout
    
     logo={logo}
      title="WHOS'IN"
      color="white"
      //  description="Reset your password."
      // premotto="INSPIRED BY THE FUTURE:"
      // motto="THE VISION UI DASHBOARD"
      // image={bgSignIn}
    >
      <VuiBox component="form" role="form" >
        <h4 style={{color:"#fff"}}>Reset your password</h4>&nbsp;&nbsp;&nbsp;&nbsp;
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={1.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Email
            </VuiTypography>
          </VuiBox>
         
            <VuiInput 
            type="email"            
             style={{
                width: "300px !important"
              }} 
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your email..."  
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              />          
        </VuiBox>
        
        <VuiBox mt={4} mb={1} style={{textAlign:"center"}}>
          <VuiButton style={{
              background: "linear-gradient(101.39deg, #DB2C56 17.78%, #FF25A8 77.89%)", 
              width: 160
            }} fullWidth
            onClick={resetpassword}
            >
            Submit
          </VuiButton>
        </VuiBox>       
      </VuiBox>
    </CoverLayout>
  );
}

export default ResetPassword;

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Button,
  Avatar,
} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';

const HolidayModal = ({ open, onClose, venueList, onSelectVenue }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <Paper style={{ background: "#292F3C", maxHeight: 400, overflow: "auto" }}>
          <TableContainer>
          <Button style={{marginLeft:"80%"}} onClick={onClose} startIcon={<CloseIcon />}></Button>
            <Table>
              <TableHead>
                <TableRow >
                  <TableCell style={{ color: "white" }}>Title</TableCell>
                  <TableCell style={{ color: "white" }}>Date</TableCell>
                  <TableCell style={{ color: "white" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {venueList.map((venue) => (
                  <TableRow key={venue._id}>
                    <TableCell style={{ color: "white" }}>{venue.title}</TableCell>
                    <TableCell style={{ color: "white" }}>{venue.date}</TableCell>
                    <TableCell>
                      <Checkbox
                        onClick={() => onSelectVenue(venue._id)}
                        style={{ color: "#FF25A8" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          
        </Paper>
      </div>
    </Modal>
  );
};

export default HolidayModal;

import React, { useState, useEffect, useCallback } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { 
  Container, TextField, Box, Button, createTheme, ThemeProvider, 
  Checkbox, FormControlLabel, RadioGroup, Radio, FormControl, 
  InputLabel, Select, MenuItem, useMediaQuery , Modal, Typography, IconButton, TextareaAutosize,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip,CircularProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from "sweetalert2";

import { useHistory } from "react-router-dom";

import { postRequest, postRequestForm } from "../../../lib/utils";
// import { da } from "date-fns/locale";
// import { position } from "stylis";
import VenueModal from "./VenueModal";
import CustomSelect from "./CustomSelect";
const theme = createTheme({
  
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: 'dark',
    },
  },
  action: {
    hover: 'rgba(255, 255, 255, 0.08)',
  },
});

const formElementStyle = {
  borderRadius: '14px',
  color: '#FFFFFF',
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px',
    color: '#FFFFFF',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiSelect-select': {
    color: '#FFFFFF',
  },
};

const CustomFileDropArea = styled('div')(({ theme }) => ({
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.primary.main}`,
  color: theme.palette.text.primary,
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: theme.spacing(2),
}));

const useStyles = makeStyles({
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    gridColumn: '1 / -1',
    justifySelf: 'center',
    marginTop: theme.spacing(2),
  },
  chip: {
    color: 'white !important',
    background: 'linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)',
    margin: '2px',
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

function CreateOffer(isloading) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [offerData, setOfferData] = useState({
    title: "",
    description: "",
    disclaimerTitle: "",
    disclaimerDescription: "",
    categoryId: [],
    image: "",
    allowWhosin: "",
    allowClaimedon: "allWeek",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: ""   
  });
  const [selectedDay, setSelectedDay] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedValues, setSelectedValues] = React.useState([]);
  const [packages, setPackages] = useState([]);
  const [currentPackage, setCurrentPackage] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [imgPreview, setImgPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [venueList, setVenueList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [eventText, setEventText] = useState("");

  // const handleOpen = () => setOpen(true);
  const handleOpen = async() => {
    window.scrollTo(0, 400);
    // await for half a second to let the window scroll to top
    await new Promise((r) => setTimeout(r, 500));
    setOpen(true);
  }
  const handleClose = () => setOpen(false);
  const [selectedVenue, setSelectedVenue] = useState(null);

     // Function to open the modal
     const handleOpenModal = () => {
      setOpenModal(true);
    };
  
    // Function to close the modal
    const handleCloseModal = () => {
      setOpenModal(false);
    };
  
    // Function to handle venue selection from the modal
    const handleSelectVenue = (venueId) => {
      setSelectedVenue(venueId);      
      setOpenModal(false);
      handleCloseModal(); 
    };

  const onDrop = useCallback(acceptedFiles => {
    // Select the first image file from the array of accepted files
    setIsLoading(true);
    const file = acceptedFiles[0];
    setSelectedImage(file);

    // Optional: Create a preview URL for the selected image
    const reader = new FileReader();
    reader.onload = () => {
      // This will set the preview image URL
      setImgPreview(reader.result);
      setIsLoading(false);
    };
    reader.readAsDataURL(file);
  }, []); 


  const isDesktop = useMediaQuery('(min-width:600px)');



  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  
    accept: 'image/*' // Accept only image files
  });

  const handleChange = (event) => {
    setSelectedValues(event.target.value);
    setSelectedDay(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setOfferData({ ...offerData, categoryId: event.target.value });
  };

  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setOfferData({ ...offerData, allowClaimedon: selectedValue });
    if (selectedValue === "publicHolidays") {
      setOpenModal(true);
    }
  };

  const fetchCategories = async () => {
    // Fetch categories from API

    const payload = {
      limit: 10000
    };  

    const response = await postRequest(`venue/category/list/admin`, payload);
    const data = response.data; 
    setCategories(data.list); 
  } 

  const eventManagment = async (filterArgs = []) => {
    const payload = {
      limit: 10000,
      page: 1,
    };
    const response = await postRequest(`venue/holiday/list`, payload);
    const data = response.data;
    //  setVenue(data.list);
    setVenueList(data.list);
  };


  useEffect(() => {
    eventManagment();
    fetchCategories();
  }, []);


  


  const createOffer = async (e) => {
    e.preventDefault();
    //setLoading(true);

    



    if(!offerData.title){      
      // wait for the page to scroll to the top

      window.scrollTo(0, 0);
      // wait for half a second
      await new Promise(r => setTimeout(r, 500));

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter offer title",
      });
      return;
    }

    

    if(!offerData.categoryId){
      window.scrollTo(0, 0);
      // wait for half a second
      await new Promise(r => setTimeout(r, 500));
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select offer category",
      });
      return;
    }

    if(!offerData.allowWhosin){
      window.scrollTo(0, 0);
      // wait for half a second
      await new Promise(r => setTimeout(r, 500));
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select offer allow whosin",
      });
      return;
    }



    if(!offerData.startDate){
      window.scrollTo(0, 0);
      // wait for half a second
      await new Promise(r => setTimeout(r, 500));
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select offer start date",
      });
      return;
    }

    if(!offerData.startTime){
      window.scrollTo(0, 0);
      // wait for half a second
      await new Promise(r => setTimeout(r, 500));
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select offer start time",
      });
      return;
    }

    if(!offerData.endDate){
      window.scrollTo(0, 0);
      // wait for half a second
      await new Promise(r => setTimeout(r, 500));
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select offer end date",
      });
      return;
    }

   

    if(!offerData.endTime){
      window.scrollTo(0, 0);
      // wait for half a second
      await new Promise(r => setTimeout(r, 500));
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select offer end time",
      });
      return;
    }

    

    if(!offerData.description){
      window.scrollTo(0, 0);
      // wait for half a second
      await new Promise(r => setTimeout(r, 500));
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter offer description",
      });
      return;
    }



    if(!selectedImage){
      window.scrollTo(0, 0);
      // wait for half a second
      await new Promise(r => setTimeout(r, 500));
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select offer image",
      });
      return;
    }    

  
   
    
  
    // combine date and time

    let startDate = null;
    let endDate = null;

    if (offerData.startDate) {
      if (offerData.startTime) {
        startDate = new Date(offerData.startDate + " " + offerData.startTime);
      } else {
        startDate = new Date(offerData.startDate + " " + "00:00");
      }
    }

    if (offerData.endDate) {
      if (offerData.endTime) {
        endDate = new Date(offerData.endDate + " " + offerData.endTime);
      } else {
        endDate = new Date(offerData.endDate + " " + "00:00");
      }
    }

    const formData = new FormData();
    formData.append("title", offerData.title);
    formData.append("description", offerData.description);
    formData.append("discountTag", offerData.discountText);
    formData.append("disclaimerTitle", offerData.disclaimerTitle);
    formData.append("disclaimerDescription", offerData.disclaimerDescription);


    // sort the selected days by day order

    // convert Sunday to sun, Monday to mon etc in selectedDay array

    let selectedDayTemp = selectedDay?.map((day) => {
      return day.substring(0, 3).toLowerCase();
    });

    // sort the selected days by day order   

    selectedDayTemp.sort((a, b) => {
      const dayOrder = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
      return dayOrder.indexOf(a) - dayOrder.indexOf(b);
    });

    formData.append("days", selectedDayTemp.join(","));


    offerData.categoryId.forEach((item, index) => {
      formData.append(`categoryId[${index}]`, item);
    });   

    formData.append("image", selectedImage);


    formData.append("allowWhosin", offerData.allowWhosin == "1" ? true : false);
    formData.append("allowClaimedon", offerData.allowClaimedon );


    if (offerData.startDate) {
      formData.append("startTime", startDate);
    }
    if (offerData.endDate) {
      formData.append("endTime", endDate);
    }

    let venueId = localStorage.getItem("venueId");
    formData.append("venue", venueId);

    //formData.append("discount", offerData.discount);

    const finalPackages = packages.filter((pack) => pack.title && pack.amount);

    const isClaimAvilable = finalPackages.some((pack) => pack.claimAvilable === true);
    

    if(isClaimAvilable){
      // we need to enter special offer details

      if(!offerData.specialOfferTitle){
        window.scrollTo(0, 0);
        // wait for half a second
      await new Promise(r => setTimeout(r, 500));
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter special offer title",
        });
        return;
      }

      if(!offerData.specialOfferDescription){
        window.scrollTo(0, 0);
        // wait for half a second
      await new Promise(r => setTimeout(r, 500));
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter special offer description",
        });
        return;
      }

      if(!offerData.specialOfferDiscount){
        window.scrollTo(0, 0);
        // wait for half a second
      await new Promise(r => setTimeout(r, 500));
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter special offer discount",
        });
        return;
      }

      // discount will be 1 - 99

      if(offerData.specialOfferDiscount < 1 || offerData.specialOfferDiscount > 99){
        window.scrollTo(0, 0);
        // wait for half a second
      await new Promise(r => setTimeout(r, 500));
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter special offer discount between 1 - 99",
        });
        return;
      }

      formData.append("specialOffer_title", offerData.specialOfferTitle || "");
      formData.append("specialOffer_discription", offerData.specialOfferDescription || "");
      formData.append("specialOffer_discount", offerData.specialOfferDiscount || 0);

    }    


    // error if no packages

    // if (finalPackages.length === 0) {
    //   toast.error("Please add atleast one package");
    //   setLoading(false);
    //   return;
    // }

    finalPackages.forEach((item, index) => {
      formData.append(`packages_type[${index}]`, item.type?.toLowerCase() || "regular" );
      formData.append(`packages_title[${index}]`, item.title);
      formData.append(`packages_amount[${index}]`, item.amount);
      formData.append(`packages_discount[${index}]`, item.discount);
      formData.append(`packages_qty[${index}]`, item.qty);
      formData.append(`packages_leftQtyAlert[${index}]`, item.leftQtyAlert || 0);
      formData.append(`packages_buyQty[${index}]`, item.buyQty || 0);
      formData.append(`packages_getQty[${index}]`, item.getQty || 0);
      formData.append(`packages_isAllowClaim[${index}]`, item.claimAvilable);     
      formData.append(`packages_isAllowSale[${index}]`, item.isAllowSale ? true : false);
      formData.append(`packages_isFeatured[${index}]`, item.isFeatured ? true : false);
      formData.append(`packages_isFree[${index}]`, item.isPackageFree ? true : false);
      formData.append(`packages_price_per_brunch[${index}]`, item.claimAmount || 0);      
      formData.append(
        `packages_description[${index}]`,
        item.description
      );
    });

    const response = await postRequestForm("venue/offer/add", formData);
    //setLoading(false);

    if (response.status == 1) {
      // If the update is successful, call the pushUpdates function     
      window.scrollTo(0, 0); 
      // wait for half a second
      await new Promise(r => setTimeout(r, 500));
      Swal.fire({
        title: 'Success!',
        text: response.message,
        icon: 'success',
        confirmButtonText: 'OK',
        timer: 1500, // closes after 1500ms
        showConfirmButton: false,
        timerProgressBar: true,
      });  

      setTimeout(() => {        
        history.push("/offer");
      }, 3000);
    } else {
      window.scrollTo(0, 0);
      // wait for half a second
      await new Promise(r => setTimeout(r, 500));
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response.message,
      });
    }
  };

  const pushPackage = async () => {

    console.log(currentPackage)

    if (!currentPackage.title) {
      toast.error("Please enter package title");
      return;
    }

    if (!currentPackage.description) {
      toast.error("Please enter package description");
      return;
    }
    
    if(!currentPackage.amount){
      alert("Please enter amount")
      return;
    }

    // check amount is valid and none negative

    if(currentPackage.amount < 0){
      alert("Please enter valid amount")
      return;
    }
    if(currentPackage.discount){
      // check discount 0 - 100
      if(currentPackage.discount < 0 || currentPackage.discount > 100){
        alert("Please enter discount between 0 - 100")
        return;
      }      
    }   

    

    if(currentPackage.qty < 0){
      alert("Please enter valid quantity")
      return;
    }    
    

    // pricePerBrunch should not greatewr than amount

    if(currentPackage.pricePerBrunch > currentPackage.amount){
      alert("Claim Amount should not greater than amount")
      return;
    }   

    setPackages([...packages, currentPackage]);
    setCurrentPackage({});
    handleClose();
    //window.scrollTo(0, 0);
    // wait for half a second
    //await new Promise(r => setTimeout(r, 500));
    
  };   

  const handleDelete = async (index) => {
    const filtered = packages.filter((_, i) => i !== index);
    setPackages(filtered);
    window.scrollTo(0, 0);
    // wait for half a second
    await new Promise(r => setTimeout(r, 500));
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Package deleted successfully",
    });
  };

  function getNextDay(date) {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay.toISOString().split('T')[0];
  }

  const renderSelectedCategories = (selected) => (
    <div>
      {selected.map((value) => (
        <Chip key={value}  label={categories.find(c => c._id === value)?.title || value} className={classes.chip} />
      ))}
    </div>
  );

  return (   
    <>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <Box 
          component="form"          
          noValidate 
          autoComplete="off"
          sx={{
            display: 'grid',
            gridTemplateColumns: isDesktop ? '1fr' : '1fr',
            gap: 2,
            '& .MuiFormControl-root': {
              m: 1,
              ...formElementStyle,
            },
          }}
        >
          <TextField onChange={ (e) => setOfferData({...offerData, title: e.target.value})}  label="Title" variant="outlined" fullWidth />
          <TextField label="Description" variant="outlined" fullWidth multiline rows={4} onChange={ (e) => setOfferData( {...offerData, description: e.target.value})} />
          
          
          <TextField onChange={ (e) => setOfferData({...offerData, discountText: e.target.value})}  label="Discount Tag" variant="outlined" fullWidth />
          
          
          
          <TextField onChange={ (e) => setOfferData({...offerData, disclaimerTitle: e.target.value})}  label="Disclaimer Title" variant="outlined" fullWidth />
          <TextField label="Disclaimer Description" variant="outlined" fullWidth multiline rows={4} onChange={ (e) => setOfferData( {...offerData, disclaimerDescription: e.target.value})} />
          </Box>

          <Box 
          component="form"       
          noValidate 
          autoComplete="off"
          sx={{
            display: 'grid',
            gridTemplateColumns: isDesktop ? '1fr 1fr' : '1fr',
            gap: 2,
            '& .MuiFormControl-root': {
              m: 1,
              ...formElementStyle,
            },
          }}
        >

          <FormControl fullWidth>
            <InputLabel>Select Category</InputLabel>
            <Select
              labelId="category-label"
              label="Select Category"
              multiple
              value={offerData.categoryId}
              onChange={handleCategoryChange}
              renderValue={selected => renderSelectedCategories(selected)}
              fullWidth
            >
              {/* <MenuItem value="">
                <em>Select Category</em>
              </MenuItem> */}
              {categories?.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Allow Whosin</InputLabel>
            <Select labelId="whosin-label" label="Allow Whosin" onChange={(e) => setOfferData({...offerData, allowWhosin: e.target.value})} fullWidth>
              <MenuItem value="1">Yes</MenuItem>
              <MenuItem value="0">No</MenuItem>
            </Select>
          </FormControl>         
          


      {/* Select Days */}
                <TextField label="Start Date" variant="outlined" type="date" onChange={(e) => setOfferData({...offerData, startDate: e.target.value})} fullWidth InputLabelProps={{
          shrink: true,
        }}  inputProps={{
          min: new Date().toISOString().split('T')[0] // Sets minimum date to today
        }} 
         />
                <TextField label="Start Time" variant="outlined" type="time" onChange={(e) => setOfferData({...offerData, startTime: e.target.value})} fullWidth InputLabelProps={{
          shrink: true,
        }} />
                <TextField label="End Date" variant="outlined" type="date" onChange={(e) => setOfferData({...offerData, endDate: e.target.value})} fullWidth InputLabelProps={{
          shrink: true,
        }} inputProps={{
          min: offerData.startDate ? getNextDay(offerData.startDate) : new Date().toISOString().split('T')[0]
        }} />
                <TextField label="End Time" variant="outlined" type="time" onChange={(e) => setOfferData({...offerData, endTime: e.target.value})} fullWidth InputLabelProps={{
          shrink: true,
        }} />
        
          {/* Enter Packages Discounts */}

         

          </Box>

          <Box 
          component="form"          
          noValidate 
          autoComplete="off"
          sx={{
            display: 'grid',
            gridTemplateColumns: isDesktop ? '1fr' : '1fr',
            gap: 2,
            '& .MuiFormControl-root': {
              m: 1,
              ...formElementStyle,
            },
          }}
        >

        <FormControl fullWidth>
              <InputLabel >Days</InputLabel>
              <Select
                labelId="day-label"
                id="Days"
                multiple
                value={selectedValues}
                onChange={handleChange}
                renderValue={(selected) => selected.join(', ')}
                fullWidth
              >
                {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ].map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <>
  <FormControl fullWidth>
    <InputLabel>Allow Claimed On</InputLabel>
    <Select
      labelId="whosin-label"
      label="Allow Claimed On"
      onChange={(e) => setOfferData({ ...offerData, allowClaimedon: e.target.value })}
      fullWidth
    >
      <MenuItem value="selected">Selected When to Claim</MenuItem>
      <MenuItem value="allWeek">All Week</MenuItem>
      <MenuItem value="weekends">Weekends</MenuItem>
      <MenuItem value="weekdays">Weekdays</MenuItem>
      <MenuItem value="publicHolidays">Public Holiday's</MenuItem>
    </Select>
  </FormControl>

  {offerData.allowClaimedon === "publicHolidays" && (
    <>
      <FormControl fullWidth>
        <TextField
          placeholder="Select public holidays"
          variant="outlined"
          fullWidth
          onClick={handleOpenModal}
          value={eventText ? eventText : "Select Public Holidays"}
          InputProps={{
            readOnly: true, // Prevent user input directly into the field
          }}
        />
      </FormControl>
      <VenueModal
      style={{ top: "50% !important",position: "fixed" }}
        open={openModal}
        onClose={handleCloseModal}
        venueList={venueList} // Pass venue list data to VenueModal
        onSelectVenue={(venueId) => {  
          
          const selectedVenue = venueList.find((venue) => venue._id === venueId);
          console.log(selectedVenue);
          // selectedVenue exists on eventData array then remove else add check venueId exists on eventData?.id then remove else add

          const index = eventData.findIndex((item) => item._id === selectedVenue._id);

          if (index > -1) {
            // If the venue is already selected, remove it
            const updatedVenues = eventData.filter((item) => item._id !== selectedVenue._id);
            const eventText = updatedVenues.map((venue) => venue.title).join(", ");

            setEventText(eventText);
            setEventData(updatedVenues);
            console.log(eventText, updatedVenues)
          } else {
            // If the venue is not selected, add it
            let eventText = eventData.map((venue) => venue.title).join(", ");
            eventText += eventText ? `, ${selectedVenue.title}` : selectedVenue.title;
            setEventText(eventText);
            setEventData([...eventData, selectedVenue]);
            console.log(eventText, eventData)
          }  

          //setEventData((prevData) => ({ ...prevData, venue: selectedVenue })); // Update selected venue in the form data
          // setSelectedVenue(selectedVenue);
          // if (selectedVenue) {
          //   // If a venue is selected, update publicHolidays with its name
          //   setOfferData((prevData) => ({
          //     ...prevData,
          //     venue: selectedVenue,
          //   }));
          // }
          //handleCloseModal(); // Close the modal after selecting a venue
        }}
        selectedVenue={selectedVenue}
      />
    </>
  )}
</>

         

          <CustomFileDropArea
            {...getRootProps()}
            style={{ height: 120, marginTop: "12px", backgroundColor: "#2D3547",  borderStyle: "dashed", borderWidth: "2px", borderColor: 'grey', borderRadius: "12px" }}
          >
            {/* <input {...getInputProps()} />
            {selectedImage ? <img src={imgPreview} alt="Preview" style={{ height: '100%' }} /> :
             <span style={{ color: "white" }}>Drag 'n' drop some files here, or click to select files</span>} */}
           <input {...getInputProps()} />
            {/* Conditionally render circular progress when isLoading is true */}
            {isLoading ? (
              <CircularProgress style={{ color: 'white' }} />
            ) : selectedImage ? (
              <img src={imgPreview} alt="Preview" style={{ height: '100%' }} />
            ) : (
              <span style={{ color: "white" }}>Drag 'n' drop some files here, or click to select files</span>
            )}
          </CustomFileDropArea>

          </Box>

          <Button onClick={handleOpen} type="button" variant="contained"> Add Package </Button>

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: "20px", marginBottom: "20px" }}>
                
                

                <TableContainer component={Paper} elevation={3} style={{ backgroundColor: '#181B1E' }}>
                <Table aria-label="brunches table">
                  <TableHead>
                    <TableRow>
                      <TableCell  style={{ color: 'white' }}> Type </TableCell>
                      <TableCell  style={{ color: 'white' }}> Title </TableCell>
                      <TableCell  style={{ color: 'white' }}>Description </TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Amount</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Discount</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Quantity</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Left Qty Alert On</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Buy Qty</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Get Qty</TableCell>                      
                      <TableCell  style={{ color: 'white' }} align="right">Claim Amount</TableCell>                     
                      <TableCell  style={{ color: 'white' }} align="right">Featured</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Allow Sale</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Allow Claim</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {packages.map((brunch, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ color: 'white' }}>{brunch.type || "-"}</TableCell>
                        <TableCell style={{ color: 'white' }}>{brunch.title || "-"}</TableCell>
                        <TableCell style={{ color: 'white' }}>{brunch.description || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.amount || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.discount || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.qty || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.leftQtyAlert || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.buyQty || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.getQty || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.isPackageFree ? 'Free' : brunch.claimAmount || "-"}</TableCell>
                        {/* <TableCell align="right" style={{ color: 'white' }}>{brunch.claimCode || "N.A"}</TableCell> */}
                        {/* <TableCell align="right" style={{ color: 'white' }}>{brunch.pricePerBrunch || "N.A"}</TableCell> */}
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.isFeatured ? 'Yes' : 'No' || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.isAllowSale ? 'Yes' : 'No' || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.claimAvilable ? 'Yes' : 'No' || "-"}</TableCell>

                        <TableCell align="right" style={{ color: 'white' }}>
                        <IconButton onClick={() => handleDelete(index)}>
                          <DeleteIcon style={{ color: 'white' }} />
                        </IconButton>
                      </TableCell>
          
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
           

            <Box 
          component="form"          
          noValidate 
          autoComplete="off"
          sx={{
            display: 'grid',
            gridTemplateColumns: isDesktop ? '1fr' : '1fr',
            gap: 2,
            '& .MuiFormControl-root': {
              m: 1,
              ...formElementStyle,
            },
          }}
        >

          <TextField onChange={ (e) => setOfferData({...offerData, specialOfferTitle: e.target.value})}  label="Special Offer Title" variant="outlined" fullWidth />
          <TextField label="Special Offer Description" variant="outlined" fullWidth multiline rows={4} onChange={ (e) => setOfferData( {...offerData, specialOfferDescription: e.target.value})} />
          <TextField type="number" onChange={ (e) => setOfferData({...offerData, specialOfferDiscount: e.target.value})}  label="Special Offer Discount" variant="outlined" fullWidth />
          
          
          </Box>             



          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: "16px" }}>

          <Button onClick={createOffer} type="button" variant="contained" style={{ background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)" }}>
            Submit
          </Button>       

          </Box> 
        
      </Container>
    </ThemeProvider>

<ThemeProvider theme={theme}>
<Modal
 style={{ top: '90% !important' , position: "fixed"}}
  // style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>

  <Box sx={{
      position: 'absolute',
      top: '40%',
      left: '50%',
      bottom: '40%',
      right: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '80vw',
      width: 400, // or whatever width you desire
      maxHeight: '80vh',
      overflowY: 'auto',
      backgroundColor: "#22262F",
      padding: '20px',
       bottom: '1%',
    }}>
    <IconButton
      aria-label="close"
      onClick={handleClose}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
      }}
      style={{backgroundColor:"#EB28A0"}}
    >
      <CloseIcon />
    </IconButton>
    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: 'white' }}>
      Add Package
    </Typography>
    <Box component="form" sx={{ mt: 2 }} noValidate autoComplete="off">
    <FormControl fullWidth margin="normal">
    {/* <InputLabel id="package-type-label" style={{ color: 'white' }}>Type</InputLabel> */}
    {/* <Select
      labelId="package-type-label"
      style={{ color: 'white' }}
      id="package-type"
      value={currentPackage.type}
      onChange={(e) => setCurrentPackage({...currentPackage, type: e.target.value})}
      label="Type"
      inputProps={{ style: { color: 'white' } }}
    >
      <MenuItem value="Regular" style={{ color: 'white' }}>Regular</MenuItem>
      <MenuItem value="Group" style={{ color: 'white' }}>Group</MenuItem>
    </Select> */}

    <CustomSelect
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        onChange={(e) => setCurrentPackage({...currentPackage, type: e.target.value})}
        // selected={currentPackage.type || ""}
        options={[
          { value: "", label: "Select Type" },
          { value: "regular", label: "Regular" },
          { value: "group", label: "Group" },
        ]}
      />
  </FormControl>
      <TextField
        margin="normal"
        value={currentPackage.title}
        onChange={(e) => setCurrentPackage({...currentPackage, title: e.target.value})}                   
        required
        fullWidth
        id="name"
        label="Title"
        name="item"
        autoFocus
        InputLabelProps={{ shrink: true ,style: { color: 'white' }}}    
        InputProps={{
          style: { color: 'white' },
        }}
      />

      <TextField
        margin="normal"
        value={currentPackage.description}
        onChange={(e) => setCurrentPackage({...currentPackage, description: e.target.value})}                   
        required
        fullWidth
        id="name2"
        label="Description"
        name="item2"        
        autoFocus
        multiline
        rows={2}
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}   
      />


      <TextField
        margin="normal"
        value={currentPackage.amount}
        onChange={(e) => setCurrentPackage({...currentPackage, amount: e.target.value})}                   
        required
        fullWidth
        id="amount"
        label="Amount"
        name="amount"
        type="number"
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}    
      />
       <TextField
        margin="normal"
        value={currentPackage.discount}
        onChange={(e) => setCurrentPackage({...currentPackage, discount: e.target.value})}                   
        required
        fullWidth
        id="discount"
        label="Discount"
        name="discount"
        type="number"
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}     
      />   
      <FormControlLabel
      control={<Checkbox checked={currentPackage.isFeatured} onChange={(e) => setCurrentPackage({...currentPackage, isFeatured: e.target.checked})} style={{ color: '#EB28A0' }}  />}
      label={<span style={{ color: 'white' }}>is Featured ?</span>}
      />   
      <FormControlLabel
      control={<Checkbox checked={currentPackage.allowSale} onChange={(e) => setCurrentPackage({...currentPackage, isAllowSale: e.target.checked})} style={{ color: '#EB28A0' }}  />}
      label={<span style={{ color: 'white' }}>Allow Sell</span>}
      />


     {currentPackage.isAllowSale && (
      <>
      <TextField
        margin="normal"
        value={currentPackage.qty}
        onChange={(e) => setCurrentPackage({...currentPackage, qty: e.target.value}) }
        required
        fullWidth
        id="quantity"
        label="Quantity"
        name="quantity"
        type="number"
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}      
      />   

{currentPackage.type === "Group" && (
      <>
        <TextField
          margin="normal"
          value={currentPackage.buyQty}
          onChange={(e) => setCurrentPackage({...currentPackage, buyQty: e.target.value}) }
          required
          fullWidth
          id="buyQty"
          label="Buy Qty"
          name="buyQty"
          type="number"
          InputLabelProps={{ shrink: true, style: { color: 'white' }}} 
          InputProps={{ style: { color: 'white' }}}      
        />
        <TextField
          margin="normal"
          value={currentPackage.getQty}
          onChange={(e) => setCurrentPackage({...currentPackage, getQty: e.target.value}) }
          required
          fullWidth
          id="getQty"
          label="Get Qty"
          name="getQty"
          type="number"
          InputLabelProps={{ shrink: true, style: { color: 'white' }}} 
          InputProps={{ style: { color: 'white' }}}      
        />
      </>
    )}
       <TextField
      margin="normal"
      value={currentPackage.leftQtyAlert}
      onChange={(e) => setCurrentPackage({...currentPackage, leftQtyAlert: e.target.value}) }
      required
      fullWidth
      id="quantity"
      label="Left Qty Alert On"
      name="quantity"
      type="number"
      InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}      
    />
      </>
      )}


      <FormControlLabel
      control={<Checkbox checked={currentPackage.claimAvilable} onChange={(e) => setCurrentPackage({...currentPackage, claimAvilable: e.target.checked})} style={{ color: '#EB28A0' }} />}
      label={<span style={{ color: 'white' }}>Allow Claim</span>}
      /> 
      {currentPackage.claimAvilable && (
        <>
        <FormControlLabel
        control={<Checkbox checked={currentPackage.isPackageFree} onChange={(e) => setCurrentPackage({...currentPackage, isPackageFree: e.target.checked})} style={{ color: '#EB28A0' }}  />}
        label={<span style={{ color: 'white' }}>is Packages Free ?</span>}
        /> 

        {!currentPackage.isPackageFree && (

        <TextField
        margin="normal"
        value={currentPackage.claimAmount}
        onChange={(e) => setCurrentPackage({...currentPackage, claimAmount: e.target.value})}                   
        required
        fullWidth
        id="amount"
        label="Claim Amount"
        name="amount"
        type="number"
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}    


      />
        )}
      </>

        
      )}      


      <Button style={{backgroundColor:"#EB28A0"}} onClick={pushPackage}  type="button" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Save
      </Button>
    </Box>
  </Box>
  
</Modal>
</ThemeProvider> </>
  );
}

export default CreateOffer;

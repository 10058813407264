import React from 'react';
import './CustomSelect.css';
// Import your custom CSS here if using CSS Modules
// import styles from './CustomSelectBox.module.css';

const CustomSelect = ({ options, selected, onChange }) => {
  return (
    <div className="custom-select">
      <select
        id="package-type"
        value={selected}
        onChange={onChange}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomSelect;
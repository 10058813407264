import {
  Container,
  TextField,
  Box,
  Button,
  createTheme,
  ThemeProvider,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  Modal,
  Typography,
  IconButton,
  TextareaAutosize,
} from "@mui/material";


import { useDropzone } from "react-dropzone";
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { useHistory } from "react-router-dom";
import { postRequest, postRequestForm, putRequestForm } from "../../lib/utils";
import { ToastContainer, toast } from "react-toastify";
import VuiBox from "components/VuiBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import colors from "assets/theme/base/colors";

import "assets/css/main.css";
import './tab-style.css';
import VenueCreateForm from "./VenueForm";
import VenueCreateForm2 from "./VenueForm2";
import VenueCreateForm3 from "./VenueForm3";
import VenueCreateForm4 from "./VenueForm4";

function VenueCreate() {

  const [businessData, setBusinessData] = useState(null);
  const [activeTab, setActiveTab] = useState("tab1"); // Initialize with the default active tab
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;  

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  // const visibleRows = rows.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const images = [businessData?.cover];
  const [index, setIndex] = useState(0);

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const [venue, setVenue] = useState([]);

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");

    if (authUser) {
      const bData = JSON.parse(authUser);      
      setBusinessData(bData);
    }
  }, []);

  const { gradients } = colors;
  const { cardContent } = gradients;

  const venue1 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1698398493097salmon_guru_cover.jpg";
  const venue2 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344268462file-x5klq41c73fewjhf.jpg";
  const venue3 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344512535file-wffm0qfttkbexv2w.jpg";
  const venue4 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344397552file-nm3hzzfy2lnfhxdl.jpg";
  const venuename1 = "Salmon Guru";
  const venuename2 = "Sparkle";
  const venuename3 = "Sky Dive";
  const venuename4 = "Street Division";
  const venueaddress1 =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";

  const fetchVenue = async (filterArgs = []) => {

    const venueId = localStorage.getItem("venueId");

    const payload = {
      venueId
    };
    const response = await postRequest(`venue/business/my-venue-detail`, payload);
    const data = response.data;

    const location = {
      latitude: parseFloat(data.venue.location.coordinates[1]),
      longitude: parseFloat(data.venue.location.coordinates[0])
    }


    if (data.venue?.timings && data.venue?.timings.length > 0) {
      const newTimeOptions = [...timeOptions];
      data.venue?.timings.map((item, index) => {
        const daysIndex = days.indexOf(capitalizeFirstLetter(item.day));
        newTimeOptions[daysIndex].checked = true;
        newTimeOptions[daysIndex].open = item.openingTime;
        newTimeOptions[daysIndex].close = item.closingTime;
      });

      setTimeOptions(newTimeOptions);
    }

    setDefaultLocation(location);
    setVenueData(data.venue);
    setDefaultTheme(data.venue?.themes);    
    setDefaultMusic(data.venue?.music);
    setDefaultFeature(data.venue?.features);
    setDefaultCuisine(data.venue?.cuisines);

  };

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    fetchVenue();
  }, []);

  // form data logic 

  let business_id = localStorage.getItem("business_id");
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);
  const [venueData, setVenueData] = useState(null);
  const [themeList, setThemeList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [cuisineList, setCuisineList] = useState([]);
  const [BusinessOptions, setBusinessOptions] = useState([]);
  const [remoteLogo, setremoteLogo] = useState([]);
  const [selectedCover, setselectedCover] = useState([]);

  const [defaultTheme, setDefaultTheme] = useState([]);
  const [defaultThemeItem, setDefaultThemeItem] = useState([]);

  const [defaultMusic, setDefaultMusic] = useState([]);
  const [defaultMusicItem, setDefaultMusicItem] = useState([]);

  const [defaultFeature, setDefaultFeature] = useState([]);
  const [defaultFeatureItem, setDefaultFeatureItem] = useState([]);

  const [defaultCuisine, setDefaultCuisine] = useState([]);
  const [defaultCuisineItem, setDefaultCuisineItem] = useState([]);





  const [defaultLocation, setDefaultLocation] = useState({ latitude: 25.2048345, longitude: 55.2708345 });
  const [location, setLocation] = useState({ latitude: 25.2048345, longitude: 55.2708345 });

  const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Handle logo image files as needed
      setselectedCover(acceptedFiles);     
    },
    accept: "image/*", // Accept only image files
  });

  const { getRootProps: getCoverRootProps, getInputProps: getCoverInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Handle cover image files as needed
      setremoteLogo(acceptedFiles);     
    },
    accept: "image/*", // Accept only image files
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isDesktop = useMediaQuery("(min-width:600px)");



  useEffect(() => {

    if(!defaultTheme?.length) return;

    fetchTheme();      
  }, [defaultTheme]);

  useEffect(() => {  
    if(!defaultMusic?.length) return;
    fetchMusic();     
  }, [defaultMusic]);

  useEffect(() => {  
    if(!defaultFeature?.length) return;
    fetchFeature();      
  }, [defaultFeature]);

  useEffect(() => {   
    if(!defaultCuisine?.length) return;
    fetchCuisine();       
  }, [defaultCuisine]);

  useEffect(() => {   
    fetchListbusiness();
  }, []);


  const fetchListbusiness = async () => {
    const param = { page: 1, limit: 100000 };
    const business = await postRequest("venue/business/list", param);

    const options = business.data.list;   
    setBusinessOptions(options);
  };

  const fetchTheme = async () => {
    const param = { page: 1, limit: 100000 };
      if(!themeList?.length){
        const theme = await postRequest("venue/theme/list", param);
        const options = theme.data.list.map((item) => {
          return { id: item._id, name: item.title };
        });        
        setThemeList(options);   
        const defaultThemeIds = options
        .filter(option => defaultTheme.includes(option.name))
        .map(filteredOption => filteredOption.id); 
        setDefaultThemeItem(defaultThemeIds);
        setSelectedTheme(defaultThemeIds);
      }     
  };

  const fetchMusic = async () => {
    const param = { page: 1, limit: 100000 };

    if(!musicList?.length){
      const music = await postRequest("venue/music/list", param);
      if (music.status == 1) {
        const options = music.data.list.map((item) => {
          return { id: item._id, name: item.title };
        });
        setMusicList(options);
        const defaultMusicIds = options
        .filter(option => defaultMusic.includes(option.name))
        .map(filteredOption => filteredOption.id);
        setDefaultMusicItem(defaultMusicIds);
        setSelectedMusic(defaultMusicIds);
      }      
    }
  };

  const fetchFeature = async () => {
    const param = { page: 1, limit: 100000 };

    if(!featureList?.length){
      const feature = await postRequest("venue/feature/list", param);
      if (feature.status == 1) {
        const options = feature.data.list.map((item) => {
          return { id: item._id, name: item.title };
        });
        setFeatureList(options);
        const defaultFeatureIds = options
        .filter(option => defaultFeature.includes(option.name))
        .map(filteredOption => filteredOption.id);
        setDefaultFeatureItem(defaultFeatureIds);
        setSelectedFeature(defaultFeatureIds);
      }     
    }
  };

  const fetchCuisine = async () => {
    const param = { page: 1, limit: 100000 };
    if(!cuisineList?.length){
      const cuisine = await postRequest("venue/cuisine/list", param);
      if (cuisine.status == 1) {
        const options = cuisine.data.list.map((item) => {
          return { id: item._id, name: item.title };
        });
        setCuisineList(options);
        const defaultCuisineIds = options
        .filter(option => defaultCuisine.includes(option.name))
        .map(filteredOption => filteredOption.id);
        setDefaultCuisineItem(defaultCuisineIds);
        setSelectedCuisine(defaultCuisineIds);
      }      
    }
  };

  const msUpdateTheme = (selectedList) => {
    setDefaultThemeItem(selectedList);
    setSelectedTheme(selectedList);
  };

  const msUpdateMusic = (selectedList) => {    
    setSelectedMusic(selectedList);    
    setDefaultMusicItem(selectedList);
  };


  const msUpdateFeature = (selectedList) => {   
    setSelectedFeature(selectedList);
    setDefaultFeatureItem(selectedList);
  };

  const msUpdateCuisine = (selectedList) => {   
    setSelectedCuisine(selectedList);
    setDefaultCuisineItem(selectedList);
  };

  const updateValue = (index, value) => {
    const list = { ...venueData };
    list[index] = value;
    setVenueData(list);   
  };

  // date time logic
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;  
    setTimeOptions(newTimeOptions);
  };

  const Createvenue = async (e) => {
    e.preventDefault();  

    if (!selectedTheme.length) {
      toast.error("Please select venue theme");
      return;
    }

    if (!selectedFeature.length) {
      toast.error("Please select venue feature");
      return;
    }

    if (!selectedCuisine.length) {
      toast.error("Please select venue cuisine");
      return;
    }

    if (!selectedMusic.length) {
      toast.error("Please select venue music");
      return;
    }
  

    const formData = new FormData();
    formData.append("venueId", venueData?._id);
   

    selectedTheme.forEach((item) => {
      formData.append("themes[]", item);
    });

    selectedMusic.forEach((item) => {
      formData.append("music[]", item);
    });

    selectedFeature.forEach((item) => {
      formData.append("features[]", item);
    });

    selectedCuisine.forEach((item) => {
      formData.append("cuisines[]", item);
    });



    const response = await postRequestForm("venue/business/my-venue-update", formData);
    // setLoading(false);

    if (response.status = 1) {
      toast.success(response.message);

      const payload = {
        ids: [venueData._id]
      };
     
      const response1 = await postRequest(`venue/business/my-venue-list`, payload);
      const data = response1.data?.list;  
      localStorage.setItem("currentVenueData", JSON.stringify(data[0]));
  
      // wait for 1 second
      setTimeout(() => {
        history.push("/venuedetail/" + venueData._id);  
      } , 1000);  
      
      } else {
        toast.error(response.message);
      }
  };

  return (
    <DashboardLayout>
      <ToastContainer/>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card style={{ background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)" }}>

          <div >
            <div className="tab-buttons" style={{display: "none"}}>
              <button className={activeTab == 'tab1' ? "active" : ""} onClick={() => setActiveTab('tab3')}>Select Features</button>
            </div>
            <div className="tab-content">
             

              {activeTab === "tab1" && (
                <VenueCreateForm3 msUpdateFeature={msUpdateFeature} featureList={featureList} msUpdateCuisine={msUpdateCuisine} selectedFeature={defaultFeatureItem} selectedMusic={defaultMusicItem} selectedTheme={defaultThemeItem} selectedCuisine={defaultCuisineItem}  cuisineList={cuisineList} msUpdateTheme={msUpdateTheme} themeList={themeList} msUpdateMusic={msUpdateMusic} musicList={musicList} onSubmit={(e) => Createvenue(e) } />
              )}

            </div>
          </div>

        </Card>
        {/* <VenueCreateForm /> */}
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default VenueCreate;



import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Card, LinearProgress, Stack } from "@mui/material";

import { postRequest,deleteRequest } from "../../lib/utils";

import backImage from "assets/images/backimage.png";
import Welcome from "./components/Welcome";
import Header from "./components/Header";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Swal from "sweetalert2";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import linearGradient from "assets/theme/functions/linearGradient";

// Vision UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// Dashboard layout components
import WelcomeMark from "layouts/dashboard/components/WelcomeMark";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";
import SatisfactionRate from "layouts/dashboard/components/SatisfactionRate";
import ReferralTracking from "layouts/dashboard/components/ReferralTracking";
import Header2 from "./components/Header2";

// React icons
import { IoIosRocket } from "react-icons/io";
import { IoGlobe } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoWallet } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";

// Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import BarChart from "examples/Charts/BarCharts/BarChart";
import { lineChartDataDashboard } from "layouts/dashboard/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/dashboard/data/lineChartOptions";
import { barChartDataDashboard } from "layouts/dashboard/data/barChartData";
import { barChartOptionsDashboard } from "layouts/dashboard/data/barChartOptions";
import "assets/css/main.css";
import Hotelinfo from "./components/hotelinfo";
function Dashboard() {
  let history = useHistory();
  const [businessData, setBusinessData] = useState(null);  
  const [venue, setVenue] = useState([]);
  const [displayCount, setDisplayCount] = useState(8);

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    
    if (authUser) {
      const bData = JSON.parse(authUser);      
      setBusinessData(bData);
    }
  }, []);

  const { gradients } = colors;
  const { cardContent } = gradients;

  const venue1 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1698398493097salmon_guru_cover.jpg";
  const venue2 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344268462file-x5klq41c73fewjhf.jpg";
  const venue3 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344512535file-wffm0qfttkbexv2w.jpg";
  const venue4 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344397552file-nm3hzzfy2lnfhxdl.jpg";
  const venuename1 = "Salmon Guru";
  const venuename2 = "Sparkle";
  const venuename3 = "Sky Dive";
  const venuename4 = "Street Division";
  const venueaddress1 =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";

  const eventManagment = async (filterArgs = []) => {
    const payload = {
      limit: 10000,
      page: 1,
    };

    const response = await postRequest(`venue/business/my-venue-list`, payload);
    const data = response.data;

    setVenue(data.list);
  };

  const selectVenue = (venueId) => {

    const currentVenueData = venue.find((item) => item._id === venueId);
    localStorage.setItem("venueId", venueId);
    localStorage.setItem("venueLogo", currentVenueData.logo);
    localStorage.setItem("venueName", currentVenueData.name);

    setTimeout(() => {
      history.push(`/venuedetail/${venueId}`);
    }, 500)

  };

  const showAlertInMiddle = async (venueId) => {
    // Calculate the middle of the document
    const middleOfPage = document.documentElement.scrollHeight / 2 - window.innerHeight / 2;

    // Scroll to the middle of the page
    window.scrollTo({
      top: middleOfPage,
      behavior: 'smooth', // Smooth scroll
    });

    // Wait a bit for the scrolling to finish, then open SweetAlert
    setTimeout(async() => {
      await deleteVenue(venueId)
    }, 500); // Adjust the delay as needed
  };

  const deleteVenue = async (venueId) => {
    // Swal confirm box
    const willDelete = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    });

    // Proceed only if confirmed
    if (willDelete.isConfirmed) {
        const response = await deleteRequest(`venue/delete`, { "venueID": venueId });
        const data = response;

       

        if(data.status) {
            eventManagment(); // Assuming this is a function to manage events post-deletion
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            });
        }
    }
}
const buttonStyle = {
  display: 'block',
  margin: '0 auto', // Center-align the button horizontally
  padding: '10px 20px',
  backgroundColor: '#d63384', // Customize button background color
  color: 'white', // Customize button text color
  border: 'none',
  borderRadius: '10px',
  marginTop: '20px', // Margin at the top
  cursor: 'pointer',
};

const loadMoreImages = () => {
  // Increase the number of images to display by 4 when the button is clicked
  setDisplayCount(displayCount + 4);
};

  useEffect(() => {
    eventManagment();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={12}>
              <div
              className="main_card"
              >
                <WelcomeMark />

                <div
                  style={{
           
                    backgroundImage: `
                    url(${backImage})`, // Image first, then gradient with adjusted opacity
                    backgroundSize: "contain", // Cover for both the image and gradient
                    backgroundRepeat: "no-repeat", // Do not repeat the background
                    backgroundPosition: "center", // Center the background
                    borderRadius: "0 46px 46px 0", 
                    width:"100%",
                    display:"block",
                    height:"300px"// Apply rounded corners to the right side
                  }}
                />
              </div>
            </Grid>

            
          </Grid>
        </VuiBox>
        {/* <Header venueCount={venue?.length || 0} /> */}
        <Header2  name={businessData?.name || "Whosin User"} email={businessData?.email || "test@mail.com"} logo={businessData?.logo || ""}/>
        <br></br>
        <VuiBox mb={3}>       

         

          <Grid container spacing={3}>
          {venue?.slice(0, displayCount).map((item, index) => (
              <Grid
                item
                xs={12}
                md={6}
                xl={3}
                sx={({ breakpoints }) => ({
                  minHeight: "400px",
                })}
              >
                <Welcome img={item.cover} title={item.name} desc={item.about || item.address} id={item._id} onDelete={(id) => deleteVenue(id) } onSelect={(id) => selectVenue(id)} />
              </Grid>
            ))}

            
          </Grid>

          {displayCount < venue?.length && (
           <button style={buttonStyle} onClick={loadMoreImages}>Load More</button>
           )}
        </VuiBox>

       
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;

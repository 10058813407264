
import React from "react";

import { Card, Icon } from "@mui/material";
import welcome from "assets/images/welcome-profile.png";
import VuiTypography from "components/VuiTypography/index";
import VuiBox from "components/VuiBox/index";

const Welcome = ({img,title,desc, onSelect, id, onSlide, index}) => {
  return (
    <Card
      sx={({ breakpoints }) => ({
        background: `
        linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), 
        url(${img || welcome})`,
        backgroundSize: "cover",        
        borderRadius: "20px",
        height: "100%",
        [breakpoints.only("xl")]: {
          gridArea: "1 / 1 / 2 / 2",
        },
      })}
    >
      <VuiBox display="flex" flexDirection="column" sx={{ height: "100%" }}>
        <VuiBox display="flex" flexDirection="column" mb="auto">
          <VuiTypography color="white" variant="h3" fontWeight="bold" mb="3px">
            {title || ""}
          </VuiTypography>
          <VuiTypography color="white" variant="button" fontWeight="regular">
            {desc || ""}
          </VuiTypography>
        </VuiBox>
        <VuiBox justifySelf="flex-end">
          <VuiTypography
            component="a"           
            variant="button"
            color="white"           
            fontWeight="regular"
            sx={{
              mr: "5px",
              display: "inline-flex",
              alignItems: "center",
              justifySelf: "flex-end",
              cursor: "pointer",

              "& .material-icons-round": {
                fontSize: "1.5rem",
                transform: `translate(2px, -0.5px)`,
                transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
              },

              "&:hover .material-icons-round, &:focus  .material-icons-round": {
                transform: `translate(6px, -0.5px)`,
              },
            }}
          >
             

              <Icon 
                className="material-icons-round" 
                onClick={ () => onSlide(index)}
                sx={{ 
                  marginLeft: "8px",
                  fontSize: "22px !important",                  
                  fontWeight: "bold", 
                  transform: "translate(2px, -0.5px)", 
                  transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)" 
                }}>
                preview
              </Icon>

              <Icon onClick={ () => onSelect(img)} className="material-icons-round" sx={{ marginLeft:"280px",fontSize:"22px !important", color: "#E53935", fontWeight: "bold", transform: `translate(2px, -0.5px)`, transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)" }}>
                delete
              </Icon>


               {/* <Icon sx={{ fontWeight: "bold", ml: "5px" }}>trash</Icon> */}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default Welcome;

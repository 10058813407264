import React, { useState } from "react";
import Card from "@mui/material/Card";
import { Pagination } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green, orange } from "@mui/material/colors";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import Offers from "./table/offers";
import SpecialOffer from "./table/specialoffer";
import Claims from "./table/claims";

import './tab-style.css';
import UpdateOffer from './ui/updateOffer';


const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "grey",
      dark: "black",
      contrastText: "white",
    },
  },
});

import authorsTableData from "layouts/offers/data/authorsTableData";
 
function Tables() {
  const { columns, rows } = authorsTableData;
   const [activeTab, setActiveTab] = useState("tab1"); // Initialize with the default active tab
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const visibleRows = rows.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3} >

          <Card  style={{ background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)" }}>

          <div >
            <div className="tab-buttons">
              <button className={ activeTab == 'tab1' ? "active" : "" } onClick={() => handleTabClick("tab1")}>Update Offer</button>             
            </div>
            <div className="tab-content">
              {activeTab === "tab1" && (
               <UpdateOffer />
              )}     
            </div>
          </div>	


          </Card>	        
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;


import React, { useState } from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";

const DayTimePicker = ({ day, updateTiming, index, currentValue }) => {
  const updateByObj = (obj, val) => {
    updateTiming(index, obj, val);
  };

  return (
    <Row>
    
      <Col md={2}>
        <FormGroup check>
          <Label check    style={{color:"rgb(255 255 255 / 60%)"}}>
            <Input
              type="checkbox"
              checked={currentValue.checked}
              onChange={(e) => updateByObj("checked", e.target.checked)}
              style={{color:"rgb(255 255 255 / 60%)"}}
            />

            {day}

          </Label>
        </FormGroup>
      </Col>
      <Col md={4}>
        <input
          value={currentValue.open}
          type="time"
          onChange={(e) => updateByObj("open", e.target.value)}
          disabled={!currentValue.checked}
          name="openingTime"
          className="form-control custom-time-input"
          style={{color:"rgb(255 255 255 / 60%)",background:"#22262f"}}
        />
      </Col>
      <Col md={4}>
        <input
          value={currentValue.close}
          type="time"
          onChange={(e) => updateByObj("close", e.target.value)}
          disabled={!currentValue.checked}
          name="closingTime"
          className="form-control custom-time-input"
          style={{color:"rgb(255 255 255 / 60%)",background:"#22262f"}}
        />
      </Col>
    </Row>
  );
};

export default DayTimePicker;

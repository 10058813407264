/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState, useEffect } from "react";

import CountUp from 'react-countup';

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Card, LinearProgress, Stack } from "@mui/material";

import { postRequest } from "../../lib/utils";

import backImage from "assets/images/backimage.png";
import Welcome from "./components/Welcome";
import Header from "./components/Header";
import { useParams } from 'react-router-dom';
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import linearGradient from "assets/theme/functions/linearGradient";

// Vision UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// Dashboard layout components
import WelcomeMark from "layouts/dashboard/components/WelcomeMark";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";
import SatisfactionRate from "layouts/dashboard/components/SatisfactionRate";
import ReferralTracking from "layouts/dashboard/components/ReferralTracking";
import Header2 from "./components/Header2";

// React icons
import { IoIosRocket } from "react-icons/io";
import { IoGlobe } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoWallet } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";

// Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import BarChart from "examples/Charts/BarCharts/BarChart";
import { lineChartDataDashboard } from "layouts/dashboard/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/dashboard/data/lineChartOptions";
import { barChartDataDashboard } from "layouts/dashboard/data/barChartData";
import { barChartOptionsDashboard } from "layouts/dashboard/data/barChartOptions";
import "assets/css/main.css";
import Hotelinfo from "./components/hotelinfo";
import { Slide } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

function Venuedetail() {
  const { _id } = useParams();
  const [businessData, setBusinessData] = useState(null);
  const [images, setImages] = useState([]);
  const [analyticsData, setAnalyticsData] = useState(null);

  const [index, setIndex] = useState(0);

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const [venue, setVenue] = useState([]);

  useEffect(() => {   
    const venueId = localStorage.getItem("venueId");
    fetchVenue(venueId);    
  }, [_id]);
  useEffect(() => {   
    const venueId = localStorage.getItem("venueId");
    fetchVenue(venueId);    
  }, []);

  const fetchVenue = async (venueId) => {

    const payload = {     
      venueId: venueId     
    };

    const response = await postRequest(`venue/business/my-venue-detail`, payload);
    let data = response.data;
    const analytics = data?.analytics ? data?.analytics : {};
    data = data?.venue ? data?.venue : {};

    const galleryList = data?.galleries ? data?.galleries : [];
    if(data?.cover){
      galleryList.unshift(data?.cover);
    }
    setImages(galleryList);
    setBusinessData(data);
    setAnalyticsData(analytics);
  }

  const { gradients } = colors;
  const { cardContent } = gradients;

  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={12}>
              <div className="v_card">
                <div
                  style={{
                    borderRadius: "46px",
                    borderBottomRightRadius: "0px",
                    borderBottomLeftRadius: "0px",

                    backgroundSize: "cover",
                    background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)",
                    backgroundPosition: "center",

                    height: "300px",
                    
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <Slide
                      in={true}
                      direction="right"
                      style={{
                        borderRadius: "46px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px",

                        backgroundSize: "cover",
                        background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)",
                        backgroundPosition: "center",

                        height: "300px",
                        objectFit: "cover",
                      }}
                      mountOnEnter
                      unmountOnExit
                    >
                      <img
                        src={images[index]}
                        alt={`Image ${index + 1}`}
                        style={{ width: "100%" }}
                      />
                    </Slide>
                    <div style={{ position: "absolute", top: "50%", left: "10px" }}>
                      <ArrowBackIos onClick={handlePrev} style={{ cursor: "pointer" }} />
                    </div>
                    <div style={{ position: "absolute", top: "50%", right: "10px" }}>
                      <ArrowForwardIos onClick={handleNext} style={{ cursor: "pointer" }} />
                    </div>
                  </div>
                  <div className="banner_logo">
                    <img src={businessData?.logo} />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Hotelinfo
                    name={businessData?.name == "undefined" ? "" : businessData?.name  || ""}
                    email={businessData?.email == "undefined" ? "" : businessData?.email || ""}
                    address={businessData?.about == "undefined" ? "" : businessData?.about || ""}
                    phone={businessData?.phone == "undefined" ? "" : businessData?.phone || ""}
                    website={businessData?.website == "undefined" ? "" : businessData?.website || ""}
                    claimCode={businessData?.claimCode == "undefined" ? "" : businessData?.claimCode || ""}
                    _id={_id}
                  />
                </div>
              </div>
            </Grid>

            {/* <Grid item xs={12} lg={6} xl={3}>
              <SatisfactionRate />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <ReferralTracking />
            </Grid> */}
          </Grid>
        </VuiBox>
        {/* <Header venueCount={venue?.length || 0} /> */}
        {/* <Header2  name={businessData?.name || "Whosin User"} email={businessData?.email || "test@mail.com"} logo={businessData?.logo || ""}/> */}
        <br></br>
        <VuiBox mb={3}>
          {/* satic venue data */}

          {/* <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              sx={({ breakpoints }) => ({
                minHeight: "400px",
                [breakpoints.only("xl")]: {
                  gridArea: "1 / 1 / 2 / 2",
                },
              })}
            >
              <Welcome img={venue1} title={venuename1} desc={venueaddress1} />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <Welcome img={venue2} title={venuename2} desc={venueaddress1} />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <Welcome img={venue3} title={venuename3} desc={venueaddress1} />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <Welcome img={venue4} title={venuename4} desc={venueaddress1} />
            </Grid>
          </Grid> */}

          {/* <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={4}>
              <MiniStatisticsCard
                title={{ text: "today's money", fontWeight: "regular" }}
                count="$53,000"
                percentage={{ color: "success", text: "+55%" }}
                icon={{ color: "info", component: <IoWallet size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <MiniStatisticsCard
                title={{ text: "today's users" }}
                count="2,300"
                percentage={{ color: "success", text: "+3%" }}
                icon={{ color: "info", component: <IoGlobe size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <MiniStatisticsCard
                title={{ text: "new clients" }}
                count="+3,462"
                percentage={{ color: "error", text: "-2%" }}
                icon={{ color: "info", component: <IoDocumentText size="22px" color="white" /> }}
              />
            </Grid>           
          </Grid> */}
          {/* <Grid
            item
            xs={12}
            xl={4}
            xxl={3}
            sx={({ breakpoints }) => ({
              minHeight: "400px",
              [breakpoints.only("xl")]: {
                gridArea: "1 / 1 / 2 / 2",
              },
            })}
          >
            <Welcome />
            <Welcome />
            <Welcome /> <Welcome />
          </Grid> */}

          {/* 
<Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              xl={3}
              sx={({ breakpoints }) => ({
                minHeight: "400px",
                [breakpoints.only("xl")]: {
                  gridArea: "1 / 1 / 2 / 2",
                },
              })}
            >
              <Welcome img={venue1} title={venuename1} desc={venueaddress1} />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <Welcome img={venue2} title={venuename2} desc={venueaddress1} />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <Welcome img={venue3} title={venuename3} desc={venueaddress1} />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <Welcome img={venue4} title={venuename4} desc={venueaddress1} />
            </Grid>
          </Grid> */}
        </VuiBox>

        {/* <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} xl={7}>
              <Card>
                <VuiBox sx={{ height: "100%" }}>
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    Sales Overview
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    <VuiTypography variant="button" color="success" fontWeight="bold">
                      +5% more{" "}
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        in 2021
                      </VuiTypography>
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox sx={{ height: "310px" }}>
                    <LineChart
                      lineChartData={lineChartDataDashboard}
                      lineChartOptions={lineChartOptionsDashboard}
                    />
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6} xl={5}>
              <Card>
                <VuiBox>
                  <VuiBox
                    mb="24px"
                    height="220px"
                    sx={{
                      background: linearGradient(
                        cardContent.main,
                        cardContent.state,
                        cardContent.deg
                      ),
                      borderRadius: "20px",
                    }}
                  >
                    <BarChart
                      barChartData={barChartDataDashboard}
                      barChartOptions={barChartOptionsDashboard}
                    />
                  </VuiBox>
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    Active Users
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    <VuiTypography variant="button" color="success" fontWeight="bold">
                      (+23){" "}
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        than last week
                      </VuiTypography>
                    </VuiTypography>
                  </VuiBox>
                  <Grid container spacing="50px">
                    <Grid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          bgColor="info"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                        >
                          <IoWallet color="#fff" size="12px" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium">
                          Users
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        32,984
                      </VuiTypography>
                      <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          bgColor="info"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                        >
                          <IoIosRocket color="#fff" size="12px" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium">
                          Clicks
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        2,42M
                      </VuiTypography>
                      <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          bgColor="info"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                        >
                          <FaShoppingCart color="#fff" size="12px" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium">
                          Sales
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        2,400$
                      </VuiTypography>
                      <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <Stack
                        direction="row"
                        spacing={{ sm: "10px", xl: "4px", xxl: "10px" }}
                        mb="6px"
                      >
                        <VuiBox
                          bgColor="info"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ borderRadius: "6px", width: "25px", height: "25px" }}
                        >
                          <IoBuild color="#fff" size="12px" />
                        </VuiBox>
                        <VuiTypography color="text" variant="button" fontWeight="medium">
                          Items
                        </VuiTypography>
                      </Stack>
                      <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
                        320
                      </VuiTypography>
                      <VuiProgress value={60} color="info" sx={{ background: "#2D2E5F" }} />
                    </Grid>
                  </Grid>
                </VuiBox>
              </Card>
            </Grid>
          </Grid>
        </VuiBox> */}
        <div className="hotel_main_card">
          <div className="hotel_card">
            <div className="card_head">
              <div className="h-user">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                >
                  <path
                    d="M12.3462 14.6509C12.2114 14.6374 12.0497 14.6374 11.9014 14.6509C8.69363 14.5431 6.14624 11.9148 6.14624 8.68002C6.14624 5.37785 8.81493 2.69568 12.1306 2.69568C15.4327 2.69568 18.1149 5.37785 18.1149 8.68002C18.1014 11.9148 15.5541 14.5431 12.3462 14.6509Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.1178 5.39124C24.7326 5.39124 26.8352 7.50732 26.8352 10.1086C26.8352 12.656 24.8135 14.7317 22.2931 14.826C22.1852 14.8125 22.0639 14.8125 21.9426 14.826"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.60695 19.6244C2.34521 21.8079 2.34521 25.3662 5.60695 27.5362C9.31346 30.0161 15.3921 30.0161 19.0987 27.5362C22.3604 25.3527 22.3604 21.7944 19.0987 19.6244C15.4056 17.1579 9.32694 17.1579 5.60695 19.6244Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.7192 26.9566C25.6897 26.7544 26.6062 26.3635 27.361 25.784C29.4636 24.207 29.4636 21.6057 27.361 20.0288C26.6197 19.4627 25.7166 19.0853 24.7597 18.8696"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                <svg
                  style={ analyticsData?.follower?.growth ?  { transform: "rotate(180deg)"} : {} }
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="18"
                  viewBox="0 0 37 18"
                  fill="none"
                >
                  <path
                    d="M1.72685 4.53337L1.72685 13.978C1.72685 15.3433 2.83362 16.4501 4.1989 16.4501L11.389 16.4501"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                  />
                  <path
                    d="M35.5444 0.990551L27.7804 8.96466C26.8099 9.96137 25.2085 9.96137 24.238 8.96466L18.2451 2.80964C17.2747 1.81294 15.6732 1.81294 14.7028 2.80964L2.04884 15.8059"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <div className="h-percentage"> {analyticsData?.follower?.differencePercentage ? (<CountUp start={0}  duration={1.50} end={analyticsData?.follower?.differencePercentage}/>) : 0 }%</div>
            </div>

            <div className="card_footer">
              <div className="h-follower">
                <h6> all follwer</h6>
                <div className="h-percentage">  { analyticsData?.follower?.currentMonth ? (<CountUp start={0}  duration={1.00} end={analyticsData?.follower?.currentMonth}/>) : 0  }  </div>
              </div>
              <div className="h-follower"></div>
              <div className="h-follower">
                <h6> this month</h6>
                <VuiTypography
                  component="a"
                  href="/analytics"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    mr: "5px",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "106px",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
                    padding: "12.36px", // Added padding for button appearance
                    borderRadius: "15px", // Optional: Rounded corners
                    backgroundColor: "#FF26A8", // Choose a suitable button color
                    // height:"37px",

                    //             display: flex;
                    // width: 106px;
                    // height: 37px;
                    // padding: 12.36px;
                    // align-items: flex-start;
                    // gap: 12.36px;
                    // flex-shrink: 0;

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                    },

                    "&:hover, &:focus": {
                      backgroundColor: "#FF25A8", // Choose a suitable hover color
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translate(6px, 0px)`, // Adjusted hover effect
                    },
                  }}
                >
                More
                </VuiTypography>
              </div>
            </div>
          </div>
          <div className="hotel_card">
            <div className="card_head">
              <div className="h-user">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                >
                  <path
                    d="M12.3462 14.6509C12.2114 14.6374 12.0497 14.6374 11.9014 14.6509C8.69363 14.5431 6.14624 11.9148 6.14624 8.68002C6.14624 5.37785 8.81493 2.69568 12.1306 2.69568C15.4327 2.69568 18.1149 5.37785 18.1149 8.68002C18.1014 11.9148 15.5541 14.5431 12.3462 14.6509Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.1178 5.39124C24.7326 5.39124 26.8352 7.50732 26.8352 10.1086C26.8352 12.656 24.8135 14.7317 22.2931 14.826C22.1852 14.8125 22.0639 14.8125 21.9426 14.826"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.60695 19.6244C2.34521 21.8079 2.34521 25.3662 5.60695 27.5362C9.31346 30.0161 15.3921 30.0161 19.0987 27.5362C22.3604 25.3527 22.3604 21.7944 19.0987 19.6244C15.4056 17.1579 9.32694 17.1579 5.60695 19.6244Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.7192 26.9566C25.6897 26.7544 26.6062 26.3635 27.361 25.784C29.4636 24.207 29.4636 21.6057 27.361 20.0288C26.6197 19.4627 25.7166 19.0853 24.7597 18.8696"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                <svg
                  style={ analyticsData?.reviews?.growth ?  { transform: "rotate(180deg)"} : {} }
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="18"
                  viewBox="0 0 37 18"
                  fill="none"
                >
                  <path
                    d="M1.72685 4.53337L1.72685 13.978C1.72685 15.3433 2.83362 16.4501 4.1989 16.4501L11.389 16.4501"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                  />
                  <path
                    d="M35.5444 0.990551L27.7804 8.96466C26.8099 9.96137 25.2085 9.96137 24.238 8.96466L18.2451 2.80964C17.2747 1.81294 15.6732 1.81294 14.7028 2.80964L2.04884 15.8059"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <div className="h-percentage"> {analyticsData?.reviews?.differencePercentage ? (<CountUp start={0}  duration={1.50} end={analyticsData?.reviews?.differencePercentage}/>) : 0 }%</div>
            </div>

            <div className="card_footer">
              <div className="h-follower">
                <h6> all reviews</h6>
                <div className="h-percentage">  { analyticsData?.reviews?.currentMonth ? (<CountUp start={0}  duration={1.00} end={analyticsData?.reviews?.currentMonth}/>) : 0  }  </div>
              </div>
              <div className="h-follower"></div>
              <div className="h-follower">
                <h6> this month</h6>
                <VuiTypography
                  component="a"
                  href="/analytics"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    mr: "5px",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "106px",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
                    padding: "12.36px", // Added padding for button appearance
                    borderRadius: "15px", // Optional: Rounded corners
                    backgroundColor: "#FF26A8", // Choose a suitable button color
                    // height:"37px",

                    //             display: flex;
                    // width: 106px;
                    // height: 37px;
                    // padding: 12.36px;
                    // align-items: flex-start;
                    // gap: 12.36px;
                    // flex-shrink: 0;

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                    },

                    "&:hover, &:focus": {
                      backgroundColor: "#FF25A8", // Choose a suitable hover color
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translate(6px, 0px)`, // Adjusted hover effect
                    },
                  }}
                >
                  More
                </VuiTypography>
              </div>
            </div>
          </div>
          <div className="hotel_card">
            <div className="card_head">
              <div className="h-user">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                >
                  <path
                    d="M12.3462 14.6509C12.2114 14.6374 12.0497 14.6374 11.9014 14.6509C8.69363 14.5431 6.14624 11.9148 6.14624 8.68002C6.14624 5.37785 8.81493 2.69568 12.1306 2.69568C15.4327 2.69568 18.1149 5.37785 18.1149 8.68002C18.1014 11.9148 15.5541 14.5431 12.3462 14.6509Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.1178 5.39124C24.7326 5.39124 26.8352 7.50732 26.8352 10.1086C26.8352 12.656 24.8135 14.7317 22.2931 14.826C22.1852 14.8125 22.0639 14.8125 21.9426 14.826"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.60695 19.6244C2.34521 21.8079 2.34521 25.3662 5.60695 27.5362C9.31346 30.0161 15.3921 30.0161 19.0987 27.5362C22.3604 25.3527 22.3604 21.7944 19.0987 19.6244C15.4056 17.1579 9.32694 17.1579 5.60695 19.6244Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.7192 26.9566C25.6897 26.7544 26.6062 26.3635 27.361 25.784C29.4636 24.207 29.4636 21.6057 27.361 20.0288C26.6197 19.4627 25.7166 19.0853 24.7597 18.8696"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                <svg
                   style={ analyticsData?.checkin?.growth ?  { transform: "rotate(180deg)"} : {} }
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="18"
                  viewBox="0 0 37 18"
                  fill="none"
                >
                  <path
                    d="M1.72685 4.53337L1.72685 13.978C1.72685 15.3433 2.83362 16.4501 4.1989 16.4501L11.389 16.4501"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                  />
                  <path
                    d="M35.5444 0.990551L27.7804 8.96466C26.8099 9.96137 25.2085 9.96137 24.238 8.96466L18.2451 2.80964C17.2747 1.81294 15.6732 1.81294 14.7028 2.80964L2.04884 15.8059"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <div className="h-percentage"> {analyticsData?.checkin?.differencePercentage ? (<CountUp start={0}  duration={1.50} end={analyticsData?.checkin?.differencePercentage}/>) : 0 }%</div>
            </div>

            <div className="card_footer">
              <div className="h-follower">
                <h6> all checkins </h6>
                <div className="h-percentage">  { analyticsData?.checkin?.currentMonth ? (<CountUp start={0}  duration={1.00} end={analyticsData?.checkin?.currentMonth}/>) : 0  }  </div>
              </div>
              <div className="h-follower"></div>
              <div className="h-follower">
                <h6> this month</h6>
                <VuiTypography
                  component="a"
                  href="/analytics"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    mr: "5px",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "106px",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
                    padding: "12.36px", // Added padding for button appearance
                    borderRadius: "15px", // Optional: Rounded corners
                    backgroundColor: "#FF26A8", // Choose a suitable button color
                    // height:"37px",

                    //             display: flex;
                    // width: 106px;
                    // height: 37px;
                    // padding: 12.36px;
                    // align-items: flex-start;
                    // gap: 12.36px;
                    // flex-shrink: 0;

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                    },

                    "&:hover, &:focus": {
                      backgroundColor: "#FF25A8", // Choose a suitable hover color
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translate(6px, 0px)`, // Adjusted hover effect
                    },
                  }}
                >
                  More
                </VuiTypography>
              </div>
            </div>
          </div>
          <div className="hotel_card">
            <div className="card_head">
              <div className="h-user">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                >
                  <path
                    d="M12.3462 14.6509C12.2114 14.6374 12.0497 14.6374 11.9014 14.6509C8.69363 14.5431 6.14624 11.9148 6.14624 8.68002C6.14624 5.37785 8.81493 2.69568 12.1306 2.69568C15.4327 2.69568 18.1149 5.37785 18.1149 8.68002C18.1014 11.9148 15.5541 14.5431 12.3462 14.6509Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.1178 5.39124C24.7326 5.39124 26.8352 7.50732 26.8352 10.1086C26.8352 12.656 24.8135 14.7317 22.2931 14.826C22.1852 14.8125 22.0639 14.8125 21.9426 14.826"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.60695 19.6244C2.34521 21.8079 2.34521 25.3662 5.60695 27.5362C9.31346 30.0161 15.3921 30.0161 19.0987 27.5362C22.3604 25.3527 22.3604 21.7944 19.0987 19.6244C15.4056 17.1579 9.32694 17.1579 5.60695 19.6244Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.7192 26.9566C25.6897 26.7544 26.6062 26.3635 27.361 25.784C29.4636 24.207 29.4636 21.6057 27.361 20.0288C26.6197 19.4627 25.7166 19.0853 24.7597 18.8696"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                <svg
                  style={ analyticsData?.pageView?.growth ?  { transform: "rotate(180deg)"} : {} }
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="18"
                  viewBox="0 0 37 18"
                  fill="none"
                >
                  <path
                    d="M1.72685 4.53337L1.72685 13.978C1.72685 15.3433 2.83362 16.4501 4.1989 16.4501L11.389 16.4501"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                  />
                  <path
                    d="M35.5444 0.990551L27.7804 8.96466C26.8099 9.96137 25.2085 9.96137 24.238 8.96466L18.2451 2.80964C17.2747 1.81294 15.6732 1.81294 14.7028 2.80964L2.04884 15.8059"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <div className="h-percentage"> {analyticsData?.pageView?.differencePercentage ? (<CountUp start={0}  duration={1.50} end={analyticsData?.pageView?.differencePercentage}/>) : 0 }%</div>
            </div>

            <div className="card_footer">
              <div className="h-follower">
                <h6> all Pageview</h6>
                <div className="h-percentage">  { analyticsData?.pageView?.currentMonth ? (<CountUp start={0}  duration={1.00} end={analyticsData?.pageView?.currentMonth}/>) : 0  }  </div>
              </div>
              <div className="h-follower"></div>
              <div className="h-follower">
                <h6> this month</h6>
                <VuiTypography
                  component="a"
                  href="/analytics"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    mr: "5px",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "106px",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
                    padding: "12.36px", // Added padding for button appearance
                    borderRadius: "15px", // Optional: Rounded corners
                    backgroundColor: "#FF26A8", // Choose a suitable button color
                    // height:"37px",

                    //             display: flex;
                    // width: 106px;
                    // height: 37px;
                    // padding: 12.36px;
                    // align-items: flex-start;
                    // gap: 12.36px;
                    // flex-shrink: 0;

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                    },

                    "&:hover, &:focus": {
                      backgroundColor: "#FF25A8", // Choose a suitable hover color
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translate(6px, 0px)`, // Adjusted hover effect
                    },
                  }}
                >
                  More
                </VuiTypography>
              </div>
            </div>
          </div>
        </div>

        <div className="hotel_main_card">
          <div className="hotel_card">
            <div className="card_head">
              <div className="h-user">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                >
                  <path
                    d="M12.3462 14.6509C12.2114 14.6374 12.0497 14.6374 11.9014 14.6509C8.69363 14.5431 6.14624 11.9148 6.14624 8.68002C6.14624 5.37785 8.81493 2.69568 12.1306 2.69568C15.4327 2.69568 18.1149 5.37785 18.1149 8.68002C18.1014 11.9148 15.5541 14.5431 12.3462 14.6509Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.1178 5.39124C24.7326 5.39124 26.8352 7.50732 26.8352 10.1086C26.8352 12.656 24.8135 14.7317 22.2931 14.826C22.1852 14.8125 22.0639 14.8125 21.9426 14.826"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.60695 19.6244C2.34521 21.8079 2.34521 25.3662 5.60695 27.5362C9.31346 30.0161 15.3921 30.0161 19.0987 27.5362C22.3604 25.3527 22.3604 21.7944 19.0987 19.6244C15.4056 17.1579 9.32694 17.1579 5.60695 19.6244Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.7192 26.9566C25.6897 26.7544 26.6062 26.3635 27.361 25.784C29.4636 24.207 29.4636 21.6057 27.361 20.0288C26.6197 19.4627 25.7166 19.0853 24.7597 18.8696"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                <svg
                  style={ analyticsData?.offer?.growth ?  { transform: "rotate(180deg)"} : {} }
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="18"
                  viewBox="0 0 37 18"
                  fill="none"
                >
                  <path
                    d="M1.72685 4.53337L1.72685 13.978C1.72685 15.3433 2.83362 16.4501 4.1989 16.4501L11.389 16.4501"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                  />
                  <path
                    d="M35.5444 0.990551L27.7804 8.96466C26.8099 9.96137 25.2085 9.96137 24.238 8.96466L18.2451 2.80964C17.2747 1.81294 15.6732 1.81294 14.7028 2.80964L2.04884 15.8059"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <div className="h-percentage"> {analyticsData?.offer?.differencePercentage ? (<CountUp start={0}  duration={1.50} end={analyticsData?.offer?.differencePercentage}/>) : 0 }%</div>
            </div>

            <div className="card_footer">
              <div className="h-follower">
                <h6> all Offers</h6>
                <div className="h-percentage">  { analyticsData?.offer?.currentMonth ? (<CountUp start={0}  duration={1.00} end={analyticsData?.offer?.currentMonth}/>) : 0  }  </div>
              </div>
              <div className="h-follower"></div>
              <div className="h-follower">
                <h6> this month</h6>
                <VuiTypography
                  component="a"
                  href="/analytics"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    mr: "5px",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "106px",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
                    padding: "12.36px", // Added padding for button appearance
                    borderRadius: "15px", // Optional: Rounded corners
                    backgroundColor: "#FF26A8", // Choose a suitable button color
                    // height:"37px",

                    //             display: flex;
                    // width: 106px;
                    // height: 37px;
                    // padding: 12.36px;
                    // align-items: flex-start;
                    // gap: 12.36px;
                    // flex-shrink: 0;

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                    },

                    "&:hover, &:focus": {
                      backgroundColor: "#FF25A8", // Choose a suitable hover color
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translate(6px, 0px)`, // Adjusted hover effect
                    },
                  }}
                >
                  More
                </VuiTypography>
              </div>
            </div>
          </div>
          <div className="hotel_card">
            <div className="card_head">
              <div className="h-user">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                >
                  <path
                    d="M12.3462 14.6509C12.2114 14.6374 12.0497 14.6374 11.9014 14.6509C8.69363 14.5431 6.14624 11.9148 6.14624 8.68002C6.14624 5.37785 8.81493 2.69568 12.1306 2.69568C15.4327 2.69568 18.1149 5.37785 18.1149 8.68002C18.1014 11.9148 15.5541 14.5431 12.3462 14.6509Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.1178 5.39124C24.7326 5.39124 26.8352 7.50732 26.8352 10.1086C26.8352 12.656 24.8135 14.7317 22.2931 14.826C22.1852 14.8125 22.0639 14.8125 21.9426 14.826"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.60695 19.6244C2.34521 21.8079 2.34521 25.3662 5.60695 27.5362C9.31346 30.0161 15.3921 30.0161 19.0987 27.5362C22.3604 25.3527 22.3604 21.7944 19.0987 19.6244C15.4056 17.1579 9.32694 17.1579 5.60695 19.6244Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.7192 26.9566C25.6897 26.7544 26.6062 26.3635 27.361 25.784C29.4636 24.207 29.4636 21.6057 27.361 20.0288C26.6197 19.4627 25.7166 19.0853 24.7597 18.8696"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                <svg
                  style={ analyticsData?.splOffer?.growth ?  { transform: "rotate(180deg)"} : {} }
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="18"
                  viewBox="0 0 37 18"
                  fill="none"
                >
                  <path
                    d="M1.72685 4.53337L1.72685 13.978C1.72685 15.3433 2.83362 16.4501 4.1989 16.4501L11.389 16.4501"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                  />
                  <path
                    d="M35.5444 0.990551L27.7804 8.96466C26.8099 9.96137 25.2085 9.96137 24.238 8.96466L18.2451 2.80964C17.2747 1.81294 15.6732 1.81294 14.7028 2.80964L2.04884 15.8059"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <div className="h-percentage"> {analyticsData?.splOffer?.differencePercentage ? (<CountUp start={0}  duration={1.50} end={analyticsData?.splOffer?.differencePercentage}/>) : 0 }%</div>
            </div>

            <div className="card_footer">
              <div className="h-follower">
                <h6> all special offers</h6>
                <div className="h-percentage">  { analyticsData?.splOffer?.currentMonth ? (<CountUp start={0}  duration={1.00} end={analyticsData?.splOffer?.currentMonth}/>) : 0  }  </div>
              </div>
              <div className="h-follower"></div>
              <div className="h-follower">
                <h6> this month</h6>
                <VuiTypography
                  component="a"
                  href="/analytics"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    mr: "5px",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "106px",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
                    padding: "12.36px", // Added padding for button appearance
                    borderRadius: "15px", // Optional: Rounded corners
                    backgroundColor: "#FF26A8", // Choose a suitable button color
                    // height:"37px",

                    //             display: flex;
                    // width: 106px;
                    // height: 37px;
                    // padding: 12.36px;
                    // align-items: flex-start;
                    // gap: 12.36px;
                    // flex-shrink: 0;

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                    },

                    "&:hover, &:focus": {
                      backgroundColor: "#FF25A8", // Choose a suitable hover color
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translate(6px, 0px)`, // Adjusted hover effect
                    },
                  }}
                >
                  More
                </VuiTypography>
              </div>
            </div>
          </div>
          <div className="hotel_card">
            <div className="card_head">
              <div className="h-user">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                >
                  <path
                    d="M12.3462 14.6509C12.2114 14.6374 12.0497 14.6374 11.9014 14.6509C8.69363 14.5431 6.14624 11.9148 6.14624 8.68002C6.14624 5.37785 8.81493 2.69568 12.1306 2.69568C15.4327 2.69568 18.1149 5.37785 18.1149 8.68002C18.1014 11.9148 15.5541 14.5431 12.3462 14.6509Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.1178 5.39124C24.7326 5.39124 26.8352 7.50732 26.8352 10.1086C26.8352 12.656 24.8135 14.7317 22.2931 14.826C22.1852 14.8125 22.0639 14.8125 21.9426 14.826"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.60695 19.6244C2.34521 21.8079 2.34521 25.3662 5.60695 27.5362C9.31346 30.0161 15.3921 30.0161 19.0987 27.5362C22.3604 25.3527 22.3604 21.7944 19.0987 19.6244C15.4056 17.1579 9.32694 17.1579 5.60695 19.6244Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.7192 26.9566C25.6897 26.7544 26.6062 26.3635 27.361 25.784C29.4636 24.207 29.4636 21.6057 27.361 20.0288C26.6197 19.4627 25.7166 19.0853 24.7597 18.8696"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                <svg
                   style={ analyticsData?.splOfferClaim?.growth ?  { transform: "rotate(180deg)"} : {} }
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="18"
                  viewBox="0 0 37 18"
                  fill="none"
                >
                  <path
                    d="M1.72685 4.53337L1.72685 13.978C1.72685 15.3433 2.83362 16.4501 4.1989 16.4501L11.389 16.4501"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                  />
                  <path
                    d="M35.5444 0.990551L27.7804 8.96466C26.8099 9.96137 25.2085 9.96137 24.238 8.96466L18.2451 2.80964C17.2747 1.81294 15.6732 1.81294 14.7028 2.80964L2.04884 15.8059"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <div className="h-percentage"> {analyticsData?.splOfferClaim?.differencePercentage ? (<CountUp start={0}  duration={1.50} end={analyticsData?.splOfferClaim?.differencePercentage}/>) : 0 }%</div>
            </div>

            <div className="card_footer">
              <div className="h-follower">
                <h6> all Cliams</h6>
                <div className="h-percentage">  { analyticsData?.splOfferClaim?.currentMonth ? (<CountUp start={0}  duration={1.00} end={analyticsData?.splOfferClaim?.currentMonth}/>) : 0  }  </div>
              </div>
              <div className="h-follower"></div>
              <div className="h-follower">
                <h6> this month</h6>
                <VuiTypography
                  component="a"
                  href="/analytics"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    mr: "5px",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "106px",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
                    padding: "12.36px", // Added padding for button appearance
                    borderRadius: "15px", // Optional: Rounded corners
                    backgroundColor: "#FF26A8", // Choose a suitable button color
                    // height:"37px",

                    //             display: flex;
                    // width: 106px;
                    // height: 37px;
                    // padding: 12.36px;
                    // align-items: flex-start;
                    // gap: 12.36px;
                    // flex-shrink: 0;

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                    },

                    "&:hover, &:focus": {
                      backgroundColor: "#FF25A8", // Choose a suitable hover color
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translate(6px, 0px)`, // Adjusted hover effect
                    },
                  }}
                >
                  More
                </VuiTypography>
              </div>
            </div>
          </div>
          <div className="hotel_card">
            <div className="card_head">
              <div className="h-user">
                <svg
                  
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                >
                  <path
                    d="M12.3462 14.6509C12.2114 14.6374 12.0497 14.6374 11.9014 14.6509C8.69363 14.5431 6.14624 11.9148 6.14624 8.68002C6.14624 5.37785 8.81493 2.69568 12.1306 2.69568C15.4327 2.69568 18.1149 5.37785 18.1149 8.68002C18.1014 11.9148 15.5541 14.5431 12.3462 14.6509Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.1178 5.39124C24.7326 5.39124 26.8352 7.50732 26.8352 10.1086C26.8352 12.656 24.8135 14.7317 22.2931 14.826C22.1852 14.8125 22.0639 14.8125 21.9426 14.826"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.60695 19.6244C2.34521 21.8079 2.34521 25.3662 5.60695 27.5362C9.31346 30.0161 15.3921 30.0161 19.0987 27.5362C22.3604 25.3527 22.3604 21.7944 19.0987 19.6244C15.4056 17.1579 9.32694 17.1579 5.60695 19.6244Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.7192 26.9566C25.6897 26.7544 26.6062 26.3635 27.361 25.784C29.4636 24.207 29.4636 21.6057 27.361 20.0288C26.6197 19.4627 25.7166 19.0853 24.7597 18.8696"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                <svg
                   style={ analyticsData?.ad?.growth ?  { transform: "rotate(180deg)"} : {} }
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="18"
                  viewBox="0 0 37 18"
                  fill="none"
                >
                  <path
                    d="M1.72685 4.53337L1.72685 13.978C1.72685 15.3433 2.83362 16.4501 4.1989 16.4501L11.389 16.4501"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                  />
                  <path
                    d="M35.5444 0.990551L27.7804 8.96466C26.8099 9.96137 25.2085 9.96137 24.238 8.96466L18.2451 2.80964C17.2747 1.81294 15.6732 1.81294 14.7028 2.80964L2.04884 15.8059"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <div className="h-percentage"> {analyticsData?.ad?.differencePercentage ? (<CountUp start={0}  duration={1.50} end={analyticsData?.ad?.differencePercentage}/>) : 0 }%</div>
            </div>

            <div className="card_footer">
              <div className="h-follower">
                <h6> all Ad views</h6>
                <div className="h-percentage">  { analyticsData?.ad?.currentMonth ? (<CountUp start={0}  duration={1.00} end={analyticsData?.ad?.currentMonth}/>) : 0  }  </div>
              </div>
              <div className="h-follower"></div>
              <div className="h-follower">
                <h6> this month</h6>
                <VuiTypography
                  component="a"
                  href="/analytics"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    mr: "5px",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "106px",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
                    padding: "12.36px", // Added padding for button appearance
                    borderRadius: "15px", // Optional: Rounded corners
                    backgroundColor: "#FF26A8", // Choose a suitable button color
                    // height:"37px",

                    //             display: flex;
                    // width: 106px;
                    // height: 37px;
                    // padding: 12.36px;
                    // align-items: flex-start;
                    // gap: 12.36px;
                    // flex-shrink: 0;

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                    },

                    "&:hover, &:focus": {
                      backgroundColor: "#FF25A8", // Choose a suitable hover color
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translate(6px, 0px)`, // Adjusted hover effect
                    },
                  }}
                >
                  More
                </VuiTypography>
              </div>
            </div>
          </div>
        </div>
        {/* <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid> */}
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Venuedetail;

import React, { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { 
  Container, TextField, Box, Button, createTheme, ThemeProvider, 
  Checkbox, FormControlLabel, RadioGroup, Radio, FormControl, 
  InputLabel, Select, MenuItem, useMediaQuery , Modal, Typography, IconButton, TextareaAutosize
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: 'dark',
    },
  },
  action: {
    hover: 'rgba(255, 255, 255, 0.08)',
  },
});

const formElementStyle = {
  borderRadius: '14px',
  color: '#FFFFFF',
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px',
    color: '#FFFFFF',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiSelect-select': {
    color: '#FFFFFF',
  },
};

const CustomFileDropArea = styled('div')(({ theme }) => ({
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.primary.main}`,
  color: theme.palette.text.primary,
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: theme.spacing(2),
}));

const useStyles = makeStyles({
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    gridColumn: '1 / -1',
    justifySelf: 'center',
    marginTop: theme.spacing(2),
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

function CreateOffer() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isDesktop = useMediaQuery('(min-width:600px)');
  const { getRootProps, getInputProps } = useDropzone();

  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
  };

  return (   
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <Box 
          component="form" 
          onSubmit={handleSubmit} 
          noValidate 
          autoComplete="off"
          sx={{
            display: 'grid',
            gridTemplateColumns: isDesktop ? '1fr 1fr' : '1fr',
            gap: 2,
            '& .MuiFormControl-root': {
              m: 1,
              ...formElementStyle,
            },
          }}
        >
          <TextField label="Title" variant="outlined" fullWidth />
          <TextareaAutosize
            label="Description"
            placeholder="Enter Description"
            minRows={3}
            style={{ width: '100%', padding: '8px', borderRadius: '14px', color: '#FFFFFF', border: '1px solid rgba(255, 255, 255, 0.23)' }}
          />
          <FormControl fullWidth>
            <InputLabel>Select Category</InputLabel>
            <Select labelId="category-label" label="Select Category" fullWidth>
              <MenuItem value="">Select Category</MenuItem>
              <MenuItem value={1}>Category 1</MenuItem>
              <MenuItem value={2}>Category 2</MenuItem>
              <MenuItem value={3}>Category 3</MenuItem>
            </Select>
          </FormControl>
          {/* Select Days */}
          <TextField label="Start Date" variant="outlined" type="date" fullWidth />
          <TextField label="Start Time" variant="outlined" type="time" fullWidth />
          <TextField label="End Date" variant="outlined" type="date" fullWidth />
          <TextField label="End Time" variant="outlined" type="time" fullWidth />
          <FormControl fullWidth>
            <InputLabel>Allow Whosin</InputLabel>
            <Select labelId="whosin-label" label="Allow Whosin" fullWidth>
              <MenuItem value="1">Yes</MenuItem>
              <MenuItem value="0">No</MenuItem>
            </Select>
          </FormControl>
          <CustomFileDropArea {...getRootProps()} style={{ minHeight: 300 }}>
            <input {...getInputProps()} />
            Drag 'n' drop some files here, or click to select files
          </CustomFileDropArea>
          {/* Enter Packages Discounts */}
          <Button type="submit" variant="contained" style={{ background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)" }} fullWidth>
            Submit
          </Button>
          
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default CreateOffer;

import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";

import LocationPicker from "react-location-picker";
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from "react-google-maps";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  TextField,
  Box,
  Button,
  createTheme,
  ThemeProvider,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  Modal,
  Typography,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { styled } from "@mui/material/styles";
import TagsInput from "./TagsInput";


const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

const formElementStyle = {
  borderRadius: "14px", // Border radius for form elements
  color: "#FFFFFF", // Text color for the form elements
  "& .MuiOutlinedInput-root": {
    // Targeting the input root of TextField and Select
    borderRadius: "14px", // Border radius for TextField and Select components
    color: "#FFFFFF", // Text color for the input content
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.23)", // Border color
    },
    "&.Mui-focused fieldset": {
      borderColor: "white", // Border color on focus
    },
  },
  "& .MuiInputLabel-root": {
    // Targeting the label of the TextField and Select
    color: "rgba(255, 255, 255, 0.7)", // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    // Label color when the input is focused
    color: "#FFFFFF", // Focused label color
  },
  "& .MuiSelect-select": {
    // Targeting the select input
    color: "#FFFFFF", // Text color for the select input
  },

  // Apply similar styles for Checkbox, Radio, and other components as needed
};

const CustomFileDropArea = styled("div")(({ theme }) => ({
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.primary.main}`,
  color: theme.palette.text.primary,
  textAlign: "center",
  cursor: "pointer",
  marginBottom: theme.spacing(2),
  maxWidth: "628px",
  marginLeft: "10px",
}));

const useStyles = makeStyles({
  formContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    gridColumn: "1 / -1",
    justifySelf: "center",
    marginTop: theme.spacing(2),
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

const MapComponent = withScriptjs(
  withGoogleMap(({ latitude, longitude, onMapClick }) => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: latitude, lng: longitude }}
      onClick={onMapClick}
    >
      <Marker position={{ lat: latitude, lng: longitude }} />
    </GoogleMap>
  ))
);

function VenueCreateForm({ msUpdateFeature, featureList, msUpdateCuisine, cuisineList, selectedMusic, selectedFeature, selectedTheme, selectedCuisine, onSubmit, musicList, msUpdateMusic, themeList, msUpdateTheme }) {
  
  
  // Handle updating the selected tags
  const handleUpdateMusic = (newSelectedIds) => {
    msUpdateFeature(newSelectedIds);
  };


  const isDesktop = useMediaQuery("(min-width:600px)");

  const classes = useStyles();

  const validateAndSubmit = (e) => {

    if (selectedMusic.length === 0) {
      // scroll to top

      window.scrollTo(0, 0);
      toast.error("Please select music");
      return;
    }

    if (selectedTheme.length === 0) {
      window.scrollTo(0, 0);
      toast.error("Please select theme");
      return;
    }

    if (selectedCuisine.length === 0) {
      window.scrollTo(0, 0);
      toast.error("Please select cuisine");
      return;
    }

    if (selectedFeature.length === 0) {
      window.scrollTo(0, 0);
      toast.error("Please select feature");
      return;
    }

    onSubmit(e);
  }


  return (
    <ThemeProvider theme={theme}>
      {/* <ToastContainer /> */}
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "grid",
            gridTemplateColumns: isDesktop ? "1fr" : "1fr",
            gap: 2,
            "& .MuiFormControl-root": {
              m: 1,
              ...formElementStyle, // Applying the custom styles to each form control
            },
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", color: "white" }}>Select Music</div>
          {/* <Multiselect
            options={musicList}
            onSelect={msUpdateMusic}
            selectedValues={selectedMusic}
            placeholder="Select Music"
            style={{ width: "100%" }}
            
          /> */}          
          <TagsInput 
            dataList={musicList} 
            selectedIds={selectedMusic} 
            onUpdateSelectedIds={msUpdateMusic} 
          /> 
 
          <div style={{ display: "flex", justifyContent: "space-between", color: "white"  }}> Select Theme</div>

          <TagsInput
            dataList={themeList}
            selectedIds={selectedTheme}
            onUpdateSelectedIds={msUpdateTheme}
          />

          <div style={{ display: "flex", justifyContent: "space-between", color: "white"  }}> Select Cuisine</div>

          <TagsInput
            dataList={cuisineList}
            selectedIds={selectedCuisine}
            onUpdateSelectedIds={msUpdateCuisine}
          />

          <div style={{ display: "flex", justifyContent: "space-between", color: "white"  }}> Select Feature</div>

          <TagsInput
            dataList={featureList}
            selectedIds={selectedFeature}
            onUpdateSelectedIds={msUpdateFeature}
          />
        </Box>

        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "flex-end"
          }}
        > 
          <Button
            type="button"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
              color: "white",
              padding: "6px",
              alignItems: "flex-end",
              borderRadius: "10px"
            }}
            fullWidth
            onClick={(e) => validateAndSubmit(e)}          
          >
            Submit
          </Button>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default VenueCreateForm;

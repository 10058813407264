import React, { useState } from "react";
import { Pagination } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VuiBox from "components/VuiBox";
import Table from "examples/Tables/Table";

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "grey",
      dark: "black",
      contrastText: "white",
    },
  },
});

import authorsTableData from "layouts/followers/data/authorsTableData";
 
function Claim() {
  const { columns, rows } = authorsTableData; 
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const visibleRows = rows.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (   
                <>
                  <VuiBox
                    sx={{
                      "& th": {
                        borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                          `${borderWidth[1]} solid ${grey[700]}`,
                      },
                      "& .MuiTableRow-root:not(:last-child)": {
                        "& td": {
                          borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                            `${borderWidth[1]} solid ${grey[700]}`,
                        },
                      },
                      
                    }}
                  >
                    <Table columns={columns} rows={visibleRows} />
                  </VuiBox>
                  <div style={{ alignSelf: "flex-end", color: "white", margin: "7px 10px 0px auto" }}>
                    <ThemeProvider theme={theme}>
                      <Pagination
                        count={Math.ceil(rows.length / rowsPerPage)}
                        color="primary"
                        page={currentPage}
                        onChange={handlePageChange}
                      />
                    </ThemeProvider>
                  </div>
                </>    
  );
}

export default Claim;

import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Pagination } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VuiBox from "components/VuiBox";
import Table from "examples/Tables/Table";

import { postRequest,deleteRequest } from "../../../lib/utils";

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "grey",
      dark: "black",
      contrastText: "white",
    },
  },
});

import authorsTableData from "layouts/followers/data/authorsTableData";

const columnTbl =  [
  { name: "Title", align: "left" },
  { name: "Image", align: "left" },
  { name: "Description", align: "left" },

  { name: "Days", align: "left" },
  { name: "Start Time", align: "center" },
  { name: "End Time", align: "center" },  
  { name: "action", align: "center" },
];



 
function Offers() {
  const { columns, rows } = authorsTableData; 

  const [businessData, setBusinessData] = useState(null);  
  const [offers, setOffers] = useState([]);
  const [venue, setVenue] = useState([]);


  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const visibleRows = rows.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const fetchOffers = async (venueId) => {
    const payload = {
      limit: 10000,
      page: 1,
      venueId: venueId
    };
  
    const response = await postRequest(`venue/business/venue-offer`, payload);
    const data = response.data;  
    setVenue(data.list);    
  };

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");  
    const venueId = localStorage.getItem("venueId"); 

       
    if (authUser) {
      const bData = JSON.parse(authUser);      
      fetchOffers(venueId)
    }
  }, []);

  const deleteFunction = async (id) => {
    const response = await deleteRequest("venue/offer/delete", { offerId: id });
    if (response.status) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          // User clicked "Yes"
          // Perform the actual delete action
          Offers();
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        } else {
          // User clicked "No" or closed the dialog
          Swal.fire("Cancelled", "Your file is safe :)", "info");
        }
      });
    } else {
      Swal.fire("Error!", "Something went wrong", "error");
    }
  };
  

  return (   
                <>
                  <VuiBox
                    sx={{
                      "& th": {
                        borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                          `${borderWidth[1]} solid ${grey[700]}`,
                      },
                      "& .MuiTableRow-root:not(:last-child)": {
                        "& td": {
                          borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                            `${borderWidth[1]} solid ${grey[700]}`,
                        },
                      },
                      
                    }}
                  >
                    <Table columns={columnTbl} rows={visibleRows} />
                  </VuiBox>
                  <div style={{ alignSelf: "flex-end", color: "white", margin: "7px 10px 0px auto" }}>
                    <ThemeProvider theme={theme}>
                      <Pagination
                        count={Math.ceil(rows.length / rowsPerPage)}
                        color="primary"
                        page={currentPage}
                        onChange={handlePageChange}
                      />
                    </ThemeProvider>
                  </div>
                </>    
  );
}

export default Offers;

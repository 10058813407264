import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Button,
  Avatar,
  OutlinedInput,
  InputAdornment,
  IconButton
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

const OfferModal = ({ open, onClose, venueList, onSelectVenue }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredVenues = venueList.filter((venue) =>
    venue.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal open={open} onClose={onClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Paper style={{ background: "#292F3C", width: 500, maxHeight: 400, overflow: "auto" }}>
      <IconButton size="small" onClick={onClose} style={{ color: 'white', marginLeft:"90%" }}>
      <CloseIcon />
    </IconButton>
        <OutlinedInput
          style={{ margin: '10px', color: 'white',backgroundColor:'#22262F', borderColor: 'white',width:"90%" }}
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
          startAdornment={
            <InputAdornment position="start">
              <IconButton size="small" style={{ color: 'white' }}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }          
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "white" }}>Title</TableCell>
                <TableCell style={{ color: "white" }}>Image</TableCell>
                <TableCell style={{ color: "white" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredVenues.map((venue) => (
                <TableRow key={venue._id}>
                  <TableCell style={{ color: "white" }}>{venue.title}</TableCell>
                  <TableCell>
                    <Avatar src={venue.image} sx={{ width: 40, height: 40, marginTop: "10px" }} />
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => onSelectVenue(venue._id)}
                      startIcon={<AddIcon style={{ backgroundColor: "#FF25A8" }} />}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Modal>
  );
};

export default OfferModal;

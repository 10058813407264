import React from 'react';
import { Chip } from '@material-ui/core';

const TagsInput = ({ dataList, selectedIds, onUpdateSelectedIds }) => {
  // Toggle selection state of a tag
  const handleToggle = (id) => {
    if (selectedIds?.includes(id)) {
      onUpdateSelectedIds(selectedIds.filter((selectedId) => selectedId !== id)); // Deselect
    } else {
      onUpdateSelectedIds([...selectedIds, id]); // Select
    }
  };

  // Determine if the tag is selected
  const isSelected = (id) => selectedIds?.includes(id);

  return (
    <div>
      {dataList.map((data) => (
        <Chip
          key={data.id}
          label={data.name}
          onClick={() => handleToggle(data.id)}
          style={{
            margin: '5px',
            cursor: 'pointer',
            ...(isSelected(data.id)
              ? {
                  background: 'linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)',
                  color: 'white',
                }
              : {
                  color: '#FF25A8', // Fallback color
                  border: '1px solid #FF25A8', // Fallback border
                  background: 'inherit', // Fallback background
                }),
          }}
        />
      ))}
    </div>
  );
};

export default TagsInput;

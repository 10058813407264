import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import LocationPicker from "react-location-picker";
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from "react-google-maps";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  TextField,
  Box,
  Button,
  createTheme,
  ThemeProvider,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  Modal,
  Typography,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import UnstyledTextareaIntroduction from "components/MuiTextArea";

import Multiselect from "components/Multiselect/MultiselectCommon";
import { postRequest } from "lib/utils";
import { postRequestForm } from "lib/utils";
import DayTimePicker from "./component/Daypicker";

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

const formElementStyle = {
  borderRadius: "14px", // Border radius for form elements
  color: "#FFFFFF", // Text color for the form elements
  "& .MuiOutlinedInput-root": {
    // Targeting the input root of TextField and Select
    borderRadius: "14px", // Border radius for TextField and Select components
    color: "#FFFFFF", // Text color for the input content
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.23)", // Border color
    },
    "&.Mui-focused fieldset": {
      borderColor: "white", // Border color on focus
    },
  },
  "& .MuiInputLabel-root": {
    // Targeting the label of the TextField and Select
    color: "rgba(255, 255, 255, 0.7)", // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    // Label color when the input is focused
    color: "#FFFFFF", // Focused label color
  },
  "& .MuiSelect-select": {
    // Targeting the select input
    color: "#FFFFFF", // Text color for the select input
  },

  // Apply similar styles for Checkbox, Radio, and other components as needed
};

const CustomFileDropArea = styled("div")(({ theme }) => ({
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.primary.main}`,
  color: theme.palette.text.primary,
  textAlign: "center",
  cursor: "pointer",
  marginBottom: theme.spacing(2),
  maxWidth: "628px",
  marginLeft: "10px",
}));

const useStyles = makeStyles({
  formContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    gridColumn: "1 / -1",
    justifySelf: "center",
    marginTop: theme.spacing(2),
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

const MapComponent = withScriptjs(
  withGoogleMap(({ latitude, longitude, onMapClick }) => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: latitude, lng: longitude }}
      onClick={onMapClick}
    >
      <Marker position={{ lat: latitude, lng: longitude }} />
    </GoogleMap>
  ))
);

function VenueCreateForm({onSubmit,updateValue, defaultData}) {
  let business_id = localStorage.getItem("business_id");
  let history = useHistory();







  const isDesktop = useMediaQuery("(min-width:600px)");

  const classes = useStyles();





 

 


  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "grid",
            gridTemplateColumns: isDesktop ? "1fr 1fr" : "1fr",
            gap: 2,
            "& .MuiFormControl-root": {
              m: 1,
              ...formElementStyle, // Applying the custom styles to each form control
            },
          }}
        >
          {/* <FormControl fullWidth>
            <InputLabel>Business Name</InputLabel>
            <Select
              labelId="category-label"
              label="Select Category"
              onChange={(e) => updateValue("business_id", e.target.value)}
              name="business_id"
              fullWidth
            >
              <MenuItem value="">Select Business</MenuItem>
              {BusinessOptions?.map((category) => (
                <MenuItem value={category._id}>{category.name}</MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <TextField
            label="Name"
            onChange={(e) => updateValue("name", e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            value={defaultData?.name}
            name="name"
            variant="outlined"
            fullWidth
          />

          <TextField
            label="Phone"
            onChange={(e) => updateValue("phone", e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            value={defaultData?.phone}
            name="phone"
            variant="outlined"
            fullWidth
          />

          <TextField
            label="Address"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={defaultData?.address}
            fullWidth
            multiline
            onChange={(e) => updateValue("address", e.target.value)}
            rows={4} // You can set the number of rows to suit your design needs
          />

          <TextField
            label="About"
            InputLabelProps={{
              shrink: true,
            }}
            value={defaultData?.about}
            variant="outlined"
            fullWidth
            multiline
            onChange={(e) => updateValue("about", e.target.value)}
            name="about"
            rows={4} // You can set the number of rows to suit your design needs
          />

          <TextField
            label="Email"
            InputLabelProps={{
              shrink: true,
            }}
            value={defaultData?.email}
            onChange={(e) => updateValue("email", e.target.value)}
            name="email"
            variant="outlined"
            fullWidth
          />
          <TextField
            label="Website"
            InputLabelProps={{
              shrink: true,
            }}
            value={defaultData?.website}
            onChange={(e) => updateValue("website", e.target.value)}
            name="website"
            variant="outlined"
            fullWidth
          />
          <TextField
            label="Booking Url"
            InputLabelProps={{
              shrink: true,
            }}
            value={defaultData?.booking_url}
            onChange={(e) => updateValue("booking_url", e.target.value)}
            name="booking_url"
            variant="outlined"
            fullWidth
          />
          <TextField
            label="Menu Url"
            InputLabelProps={{
              shrink: true,
            }}
            value={defaultData?.menu_url}
            onChange={(e) => updateValue("menu_url", e.target.value)}
            name="menu_url"
            variant="outlined"
            fullWidth
          />

          <TextField
            label=" Dress Code"
            InputLabelProps={{
              shrink: true,
            }}
            value={defaultData?.dress_code}
            name="dress_code"
            onChange={(e) => updateValue("dress_code", e.target.value)}
            variant="outlined"
            fullWidth
          />

          <FormControl fullWidth>
            <InputLabel>Allow Reviews</InputLabel>
            <Select
              labelId="category-label"
              onChange={(e) => updateValue("isAllowReview", e.target.value)}
              label="Select Review"
              value={defaultData?.isAllowReview ? true : false}
              name="isAllowReview"
              fullWidth
            >
              <MenuItem value="">Allow Review</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Allow Ratings</InputLabel>
            <Select
              labelId="category-label2"
              label="Select Rating"
              name="isAllowRatting"
              value={defaultData?.isAllowRatting  ? true : false}
              fullWidth
              onChange={(e) => updateValue("isAllowRatting", e.target.value)}
            >
              <MenuItem>Allow Ratings</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>         
         
        </Box>      
       
        
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "flex-end"          
          }}
        >
          <Button
            type="button"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
              borderRadius:"10px"
        
            }}
            fullWidth
            onClick={(e) => onSubmit()}
            // onClick={(e) => handlerimage(e)}
          >
            Next
          </Button>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default VenueCreateForm;

import {
  Container,
  TextField,
  Box,
  Button,
  createTheme,
  ThemeProvider,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  Modal,
  Typography,
  IconButton,
  TextareaAutosize,
} from "@mui/material";


import { useDropzone } from "react-dropzone";
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { useHistory } from "react-router-dom";
import { postRequest, postRequestForm, putRequestForm } from "../../lib/utils";
import { ToastContainer, toast } from "react-toastify";
import VuiBox from "components/VuiBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import colors from "assets/theme/base/colors";
import Swal from "sweetalert2";

import "assets/css/main.css";
import './tab-style.css';
import VenueCreateForm2 from "./VenueForm2";


function VenueCreate() {

  const [businessData, setBusinessData] = useState(null);
  const [activeTab, setActiveTab] = useState("tab1"); // Initialize with the default active tab
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;  

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  // const visibleRows = rows.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const images = [businessData?.cover];
  const [index, setIndex] = useState(0);

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const [venue, setVenue] = useState([]);

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");

    if (authUser) {
      const bData = JSON.parse(authUser);      
      setBusinessData(bData);
    }
  }, []);

  const { gradients } = colors;
  const { cardContent } = gradients;

  const venue1 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1698398493097salmon_guru_cover.jpg";
  const venue2 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344268462file-x5klq41c73fewjhf.jpg";
  const venue3 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344512535file-wffm0qfttkbexv2w.jpg";
  const venue4 =
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344397552file-nm3hzzfy2lnfhxdl.jpg";
  const venuename1 = "Salmon Guru";
  const venuename2 = "Sparkle";
  const venuename3 = "Sky Dive";
  const venuename4 = "Street Division";
  const venueaddress1 =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";

  const fetchVenue = async (filterArgs = []) => {

    const venueId = localStorage.getItem("venueId");

    const payload = {
      venueId
    };
    const response = await postRequest(`venue/business/my-venue-detail`, payload);
    const data = response.data;

    const location = {
      latitude: parseFloat(data.venue.location.coordinates[1]),
      longitude: parseFloat(data.venue.location.coordinates[0])
    }




    if (data.venue?.timings && data.venue?.timings.length > 0) {
      const newTimeOptions = [...timeOptions];
      data.venue?.timings.map((item, index) => {
        const daysIndex = days.indexOf(capitalizeFirstLetter(item.day));
        newTimeOptions[daysIndex].checked = true;
        newTimeOptions[daysIndex].open = item.openingTime;
        newTimeOptions[daysIndex].close = item.closingTime;
      });

      setTimeOptions(newTimeOptions);
    }    

    setDefaultLocation(location);
    setVenueData(data.venue);
  };

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    fetchVenue();
  }, []);

  // form data logic 

  let business_id = localStorage.getItem("business_id");
  let history = useHistory();
  const [venueData, setVenueData] = useState(null);
  const [defaultTheme, setDefaultTheme] = useState([]);
  const [defaultMusic, setDefaultMusic] = useState([]);
  const [defaultFeature, setDefaultFeature] = useState([]);
  const [defaultCuisine, setDefaultCuisine] = useState([]);


  const [defaultLocation, setDefaultLocation] = useState({ latitude: 25.2048345, longitude: 55.2708345 });
  const [location, setLocation] = useState({ latitude: 25.2048345, longitude: 55.2708345 });

 


  // date time logic
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;    
    setTimeOptions(newTimeOptions);
  };

  const Createvenue = async (e) => {
    e.preventDefault(); 
    if (!location.latitude) {
      window.scrollTo(0, 0);
      toast.error("Please enter venue latitude");
      return;
    }

    if (!location.longitude) {
      window.scrollTo(0, 0);
      toast.error("Please enter venue longitude");
      return;
    }
    if (!timeOptions.length) {
      window.scrollTo(0, 0);
      toast.error("Please select time Options ");
      return;
    } 

    let isValid = true;
    timeOptions.forEach((time) => {

      if (time.open >= time.close && time.checked) {        
        isValid = false;
      }
    });

    if (!isValid) {
      window.scrollTo(0, 0);
      toast.error("Please select valid time endtime should be greater than starttime");
      return false;
    }
    
    

    const formData = new FormData();
    formData.append("businessId", business_id);
    formData.append("venueId", venueData?._id);
    formData.append("latitude", location.latitude);
    formData.append("longitude", location.longitude);
    // formData.append("tempLogo", tempLogo);
    // formData.append("tempCover", tempCover);  

    timeOptions.forEach((item, index) => {
      if (item.checked) {
        formData.append(`days[]`, days[index].toString().toLowerCase());
        formData.append(`open_time[]`, item.open);
        formData.append(`close_time[]`, item.close);
      }
    });  

    const response = await postRequestForm("venue/business/my-venue-update", formData);
    if (response.status = 1) {      
      Swal.fire({
        title: 'Success',
        text: 'Venue Setting Updated successfully',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
      const payload = {
        ids: [venueData._id]
      };     
      const response1 = await postRequest(`venue/business/my-venue-list`, payload);
      const data = response1.data?.list;  
      localStorage.setItem("currentVenueData", JSON.stringify(data[0]));  
      // wait for 1 second
      setTimeout(() => {
        history.push("/venuedetail/" + venueData._id);  
      } , 1000);  
      
      } else {  

        Swal.fire({
          title: 'Error',
          text: 'Something went wrong',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
  };

  return (
    <DashboardLayout>
      <ToastContainer/>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card style={{ background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)" }}>
          <div>
            <div className="tab-buttons" style={{visibility: "hidden"}}>
              <button className={activeTab == 'tab1' ? "active" : ""} onClick={() => setActiveTab('tab1')}>Settings</button>             
            </div>
            <div className="tab-content">           
              {activeTab === "tab1" && (
                <VenueCreateForm2  updateTiming={updateTiming} setTimeOptions={setTimeOptions} timeOptions={timeOptions} days={days} location={location} defaultLocation={defaultLocation}  setLocation={setLocation} onSubmit={(e) => Createvenue(e)} />
              )}
            </div>
          </div>
        </Card>
        {/* <VenueCreateForm /> */}
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default VenueCreate;

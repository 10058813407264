

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";


// Vision UI Dashboard React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem"; 

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

import {
  Box,
  Button,
  Card,
  Modal,
  TextField,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";

// Vision UI Dashboard React context
import {
  useVisionUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import VenueComponent from "./venueSelect"; 
import { useHistory } from "react-router-dom";
import { postRequest } from "../../../lib/utils";

import routes from "../../../routes";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#181B1E",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

const theme = createTheme({
  palette: {
    primary: {
      light: "#212121",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [selectVenue, setSelectVenue] = useState({});//
  const [venue, setVenue] = useState([]);
  const [open, setOpen] = useState(false);
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [isLanding, setIsLanding] = useState(true);
  const [loginType, setLoginType] = useState("business" || localStorage.getItem("loginType") || 'business' );
  const [title, setTitle] = useState("Business Name");
  const [logo, setLogo] = useState(null);
  const route = useLocation().pathname.split("/").slice(1);
  let history = useHistory();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Set initial window width
    setWindowWidth(window.innerWidth);

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);  







  const eventManagment = async (filterArgs = []) => {
    const payload = {
      limit: 10000,
      page: 1,
    };

    const response = await postRequest(`venue/business/my-venue-list`, payload);
    const data = response.data;

    setVenue(data.list);
  };

  useEffect(() => {
    eventManagment();
  }, []);

  useEffect(() => {
    
      if(!selectVenue._id) return;
      setLogo(selectVenue.logo);
      setTitle(selectVenue.name);
  }, [selectVenue]);


  const gotoVenue = (venue) => {    
    if(!venue._id) return;
    localStorage.setItem("venueId", venue._id);
    localStorage.setItem("venueLogo", venue.logo);
    localStorage.setItem("venueName", venue.name);
    // window.location.href = "/venuedetail";
    setTimeout(() => {
      history.push(`/venuedetail/${venue._id}`);
    }, 500)
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => { 

    const landingPage = routes.filter((route) => route.landing).map((route) => route.route); 
    const loginType =  localStorage.getItem("loginType") || 'business'

    setLoginType(loginType);
    
    

    if (!landingPage.includes(`/${route[0]}`)) {
      const logo = localStorage.getItem("venueLogo");
      const name = localStorage.getItem("venueName");
      setTitle(name);
      setLogo(logo);
      setIsLanding(false);


    }else{    

      const logo = localStorage.getItem("BLogo");
      const name = localStorage.getItem("BName");
      setTitle(name);
      setLogo(logo);   
    }


  }, []);  

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleSignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("authUser");
    localStorage.removeItem("access");
    window.location.href = "/sign-in";
  };

  const openModal = () => {
    alert("open modal");
  }


  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        image={<img src={team2} alt="person" />}
        title={["New message", "from Laur"]}
        date="13 minutes ago"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={logoSpotify} alt="person" />}
        title={["New album", "by Travis Scott"]}
        date="1 day"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        color="text"
        image={
          <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
            payment
          </Icon>
        }
        title={["", "Payment successfully completed"]}
        date="2 days"
        onClick={handleCloseMenu}
      />
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <VuiBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          {/* <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} /> */}
        </VuiBox>
        {isMini ? null : (
          <VuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            <VuiBox pr={1} style={{borderRadius: "0px", border: "none", marginRight: "50px" }}>
              <VuiInput
                placeholder="can’t find something? search it here"
                icon={{ component: "search", direction: "right" }}
                backgroundColor="#2E323C"              
                sx={({ breakpoints }) => ({
                  [breakpoints.down("sm")]: {
                    maxWidth: "100px",
                  },
                  [breakpoints.only("sm")]: {
                    maxWidth: "100px",
                  },                 
                  color: "white !important",
                  padding: "29.71px, 16.51px, 9.71px, 14.57px !important" ,
                  borderRadius: "11.65px !important",
                  border: "0 !important",
                  height: "58px !important"                  
                })}
              />
            </VuiBox>

            {isLanding ? null : ( <>



              <VuiBox pr={1} style={{borderRadius: "0px", border: "none",  }}>
              
              {
                windowWidth>=800 && loginType == 'business'  ? <VenueComponent  className="mb-venue" logo={logo} title={title} onClick={handleOpen}/> :""
              }
              
              
             
            </VuiBox>

            {/* <VuiBox pr={1} style={{borderRadius: "0px", border: "none" }}>
              <button onClick={gotoVenue} style={{ background: "#FF26A8", color: "white", border: "none", gap: "12.36px", borderRadius: "14.78px", width: "179px", height: "45px" }} >Go to Venue</button>
            </VuiBox>  */}
            
            </>) }

             



             <VuiBox color={light ? "white" : "inherit"}>
              
                <IconButton sx={navbarIconButton} size="small" onClick={handleSignOut}>
                  <Icon
                    sx={({ palette: { dark, white } }) => ({
                      color: light ? white.main : dark.main,
                    })}
                  >
                    account_circle
                  </Icon>
                  <VuiTypography
                    variant="button"
                    fontWeight="medium"
                    color={light ? "white" : "dark"}
                  >
                    Sign Out
                  </VuiTypography>
                </IconButton>             


              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={"text-white"}>{miniSidenav ? "menu_open" : "menu"}</Icon>
              </IconButton>
              {/* <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon>settings</Icon>
              </IconButton> */}
              {/* <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
              </IconButton> */}
              {/* {renderMenu()} */}
            </VuiBox> 
          </VuiBox>
        )}
      </Toolbar>

      <ThemeProvider theme={theme}>
            <Modal
              style={{ bottom: "25%" }}
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>               
                <Box component="form" sx={{  mt: 2, height: '300px', overflowY: 'auto', maxHeight: '500px', paddingRight: "16px"  }} noValidate autoComplete="off">
                    { venue.map((venue) => (
                      <div onClick={() => {setSelectVenue(venue);gotoVenue(venue); handleClose(); }} className="venue-container" style={{ cursor: "pointer", marginBottom: "24px"  }}>
                          <div className="profile-section">
                              <img src={venue.logo} alt="profile" className="profile-pic"/>
                              <div className="profile-name">{venue.name}</div>               
                          </div>                                 
                      </div>
                    ))}   
                </Box>
              </Box>
            </Modal>
          </ThemeProvider>
          {
                windowWidth<800? <VenueComponent  className="mb-venue" logo={logo} title={title} onClick={handleOpen}/> :""
              }
    </AppBar>

    


  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;

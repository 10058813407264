
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// Vision UI Dashboard React icons
import { IoRocketSharp } from "react-icons/io5";
import { IoIosDocument } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { IoBuild } from "react-icons/io5";
import { BsCreditCardFill } from "react-icons/bs";
import { IoStatsChart } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import ResetPassword from "layouts/authentication/reset-password";
import Venue from "layouts/Venue";
// import Editprofile from "layouts/Editprofile";
import Venuedetail from "layouts/venuedetail";
import Followers from "layouts/followers";
import Reviews from "layouts/reviews";
import Checkin from "layouts/checkin";
import Tab from "layouts/tab";
import Offer from "layouts/offers";
import Reports from "layouts/reports";
import Analytics from "layouts/analytics";
import CreateOffer from "layouts/offercreate";
import UpdateOffer from "layouts/offerupdate";
import VenueCreate from "layouts/VenueCreate";
import VenueUpdate from "layouts/VenueUpdate";
import VenueSetting from "layouts/VenueSetting";
import VenueFeature from "layouts/VenueFeature";



import Gallery from "layouts/gallery";
import AddGallery from "layouts/AddGallery";
import CreateSpecialOffer from "layouts/specialoffer";
import UpdateSpecialOffer from "layouts/updatespecialoffer";
import Editprofile from "layouts/profile/Editprofile";


// import VenueCreate from "layouts/VenueCreate";

// const routes = [
//   {
//     type: "collapse",
//     name: "Dashboard",
//     key: "dashboard",
//     route: "/dashboard",
//     icon: <IoHome size="15px" color="inherit" />,
//     component: Dashboard,
//     noCollapse: true,
//     landing: true,
//     showInNav: true
//   },
//   {
//     type: "collapse",
//     name: "Tables",
//     key: "tables",
//     route: "/tables",
//     icon: <IoStatsChart size="15px" color="inherit" />,
//     component: Tables,
//     noCollapse: true,
//     landing: true,
//     showInNav: true
//   },
//   { type: "title", title: "Account Pages", key: "account-pages" },

// {
//   type: "collapse",
//   name: "Profile",
//   key: "profile",
//   route: "/profile",
//   icon: <BsFillPersonFill size="15px" color="inherit" />,
//   component: Profile,
//   noCollapse: true,
//   landing: true,
//   showInNav: true
// },

//   {
//     type: "collapse",
//     name: "Setting",
//     key: "billing",
//     route: "/billing",
//     icon: <BsCreditCardFill size="15px" color="inherit" />,
//     component: Billing,
//     noCollapse: true,
//     landing: true,
//     showInNav: true
//   },

//   {
//     type: "collapse",
//     name: "Analytics",
//     key: "analytics",
//     route: "/analytics",
//     icon: <BsFillPersonFill size="15px" color="inherit" />,
//     component: Billing,
//     noCollapse: true,
//     landing: true,
//     showInNav: true
//   },

//   {
//     type: "collapse",
//     name: "Sign In",
//     key: "sign-in",
//     route: "/authentication/sign-in",
//     icon: <IoIosDocument size="15px" color="inherit" />,
//     component: SignIn,
//     noCollapse: true,
//     landing: false,
//     showInNav: false
//   },
//   {
//     type: "collapse",
//     name: "Reset Password",
//     key: "reset-password",
//     route: "/authentication/reaet-password",
//     icon: <IoIosDocument size="15px" color="inherit" />,
//     component: ResetPassword,
//     noCollapse: true,
//     landing: false,
//     showInNav: false
//   }
// ];

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
      >
        <path
          d="M19.2546 9.39528V3.59745C19.2546 2.31852 18.7089 1.80695 17.3533 1.80695H13.9087C12.553 1.80695 12.0073 2.31852 12.0073 3.59745V9.39528C12.0073 10.6742 12.553 11.1858 13.9087 11.1858H17.3533C18.7089 11.1858 19.2546 10.6742 19.2546 9.39528Z"
          fill="white"
        />
        <path
          d="M10.3022 11.271V17.0689C10.3022 18.3478 9.75654 18.8594 8.40087 18.8594H4.95628C3.60061 18.8594 3.05493 18.3478 3.05493 17.0689V11.271C3.05493 9.9921 3.60061 9.48053 4.95628 9.48053H8.40087C9.75654 9.48053 10.3022 9.9921 10.3022 11.271Z"
          fill="white"
        />
        <path
          d="M19.2546 17.0689V14.6816C19.2546 13.4026 18.7089 12.8911 17.3533 12.8911H13.9087C12.553 12.8911 12.0073 13.4026 12.0073 14.6816V17.0689C12.0073 18.3478 12.553 18.8594 13.9087 18.8594H17.3533C18.7089 18.8594 19.2546 18.3478 19.2546 17.0689Z"
          fill="white"
        />
        <path
          d="M10.3022 5.98479V3.59745C10.3022 2.31852 9.75654 1.80695 8.40087 1.80695H4.95628C3.60061 1.80695 3.05493 2.31852 3.05493 3.59745V5.98479C3.05493 7.26372 3.60061 7.77529 4.95628 7.77529H8.40087C9.75654 7.77529 10.3022 7.26372 10.3022 5.98479Z"
          fill="white"
        />
      </svg>
    ),
    component: Dashboard,
    noCollapse: true,
    showInNav: true,
    landing: true,
  },

  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/sign-in",
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: SignIn,
    noCollapse: true,
    landing: false,
    showInNav: false,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/",
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: SignIn,
    noCollapse: true,
    landing: false,
    showInNav: false,
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "reset-password",
    route: "/authentication/reaet-password",
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: ResetPassword,
    noCollapse: true,
    landing: true,
    showInNav: false,
  },

  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
    ),
    component: Profile,
    showInNav: true,
    noCollapse: true,
    landing: true,
  },

  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: "/settings",
    icon: ( 
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="4" y1="21" x2="4" y2="14"></line><line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line><line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line><line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line></svg>     
    ),
    component: Billing,
    noCollapse: true,
    showInNav: true,
    landing: true,
  },
  {
    type: "collapse",
    name: "Table",
    key: "table",
    route: "/table",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="27"
        viewBox="0 0 27 27"
        fill="none"
      >
        <path
          d="M3.26733 10.5694V16.8536C3.26733 19.1625 3.26733 19.1625 5.44555 20.6328L11.4357 24.0962C12.3396 24.6189 13.8099 24.6189 14.703 24.0962L20.6931 20.6328C22.8713 19.1625 22.8713 19.1625 22.8713 16.8645V10.5694C22.8713 8.27142 22.8713 8.27142 20.6931 6.80112L14.703 3.33776C13.8099 2.81499 12.3396 2.81499 11.4357 3.33776L5.44555 6.80112C3.26733 8.27142 3.26733 8.27142 3.26733 10.5694Z"
          stroke="white"
          stroke-width="1.63366"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.0693 16.9844C14.8738 16.9844 16.3367 15.5215 16.3367 13.717C16.3367 11.9125 14.8738 10.4497 13.0693 10.4497C11.2648 10.4497 9.802 11.9125 9.802 13.717C9.802 15.5215 11.2648 16.9844 13.0693 16.9844Z"
          stroke="white"
          stroke-width="1.63366"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    component: Tables,
    noCollapse: true,
    showInNav: false,
    landing: true,
  },

  {
    type: "collapse",
    name: "Dashboard",
    key: "venuedetail",
    route: "/venuedetail/:_id",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
      >
        <path
          d="M19.2546 9.39528V3.59745C19.2546 2.31852 18.7089 1.80695 17.3533 1.80695H13.9087C12.553 1.80695 12.0073 2.31852 12.0073 3.59745V9.39528C12.0073 10.6742 12.553 11.1858 13.9087 11.1858H17.3533C18.7089 11.1858 19.2546 10.6742 19.2546 9.39528Z"
          fill="white"
        />
        <path
          d="M10.3022 11.271V17.0689C10.3022 18.3478 9.75654 18.8594 8.40087 18.8594H4.95628C3.60061 18.8594 3.05493 18.3478 3.05493 17.0689V11.271C3.05493 9.9921 3.60061 9.48053 4.95628 9.48053H8.40087C9.75654 9.48053 10.3022 9.9921 10.3022 11.271Z"
          fill="white"
        />
        <path
          d="M19.2546 17.0689V14.6816C19.2546 13.4026 18.7089 12.8911 17.3533 12.8911H13.9087C12.553 12.8911 12.0073 13.4026 12.0073 14.6816V17.0689C12.0073 18.3478 12.553 18.8594 13.9087 18.8594H17.3533C18.7089 18.8594 19.2546 18.3478 19.2546 17.0689Z"
          fill="white"
        />
        <path
          d="M10.3022 5.98479V3.59745C10.3022 2.31852 9.75654 1.80695 8.40087 1.80695H4.95628C3.60061 1.80695 3.05493 2.31852 3.05493 3.59745V5.98479C3.05493 7.26372 3.60061 7.77529 4.95628 7.77529H8.40087C9.75654 7.77529 10.3022 7.26372 10.3022 5.98479Z"
          fill="white"
        />
      </svg>
    ),
    component: Venuedetail,
    noCollapse: true,
    showInNav: true,
    landing: false,
  },

  {
    name: "Settings",
    type: "collapse",
    key: "updateSetting",
    route: "/updateSetting",
    component: VenueSetting,
    noCollapse: true,
    landing: false,
    showInNav: true,
    icon: ( 
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="4" y1="21" x2="4" y2="14"></line><line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line><line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line><line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line></svg>     
    ),
  },

  {
    name: "Features",
    type: "collapse",
    key: "updateFeatures",
    route: "/updateFeatures",
    component: VenueFeature,
    noCollapse: true,
    landing: false,
    showInNav: true,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
    ),
  },

  {
    type: "collapse",
    name: "Audience",
    key: "audience",
    route: "/audience",
    component: Tab,
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
    ),
    noCollapse: true,
    landing: false,
    showInNav: true,
  },
  {
    type: "collapse",
    name: "Editprofile",
    key: "Editprofile",
    route: "/Editprofile",
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: Editprofile,
    showInNav: false,
    noCollapse: true,
    landing: true,
  },
  {
    type: "collapse",
    name: "Offer",
    key: "offer",
    route: "/offer",
    component: Offer,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M9.42973 22.9648H15.4637C20.4919 22.9648 22.5033 20.9535 22.5033 15.9252V9.89128C22.5033 4.863 20.4919 2.85168 15.4637 2.85168H9.42973C4.40145 2.85168 2.39014 4.863 2.39014 9.89128V15.9252C2.39014 20.9535 4.40145 22.9648 9.42973 22.9648Z"
          stroke="white"
          stroke-width="1.50848"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.9666 19.4449C17.0729 19.4449 17.9779 18.5398 17.9779 17.4336V8.38271C17.9779 7.27649 17.0729 6.3714 15.9666 6.3714C14.8604 6.3714 13.9553 7.27649 13.9553 8.38271V17.4336C13.9553 18.5398 14.8504 19.4449 15.9666 19.4449Z"
          stroke="white"
          stroke-width="1.50848"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.92708 19.445C10.0333 19.445 10.9384 18.5399 10.9384 17.4337V13.9139C10.9384 12.8077 10.0333 11.9026 8.92708 11.9026C7.82086 11.9026 6.91577 12.8077 6.91577 13.9139V17.4337C6.91577 18.5399 7.81081 19.445 8.92708 19.445Z"
          stroke="white"
          stroke-width="1.50848"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    noCollapse: true,
    landing: false,
    showInNav: true,
  },

  {
    type: "collapse",
    name: "Analytics",
    key: "analytics",
    route: "/analytics",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M18.4236 12.8126C21.0383 12.8126 22.1244 11.8069 21.159 8.5084C20.5053 6.2859 18.5946 4.37515 16.3721 3.72147C13.0735 2.75604 12.0679 3.84215 12.0679 6.45686V9.35315C12.0679 11.8069 13.0735 12.8126 15.0848 12.8126H18.4236Z"
          stroke="white"
          stroke-width="1.50848"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.1132 15.5278C19.178 20.184 14.7128 23.563 9.63428 22.7384C5.82285 22.1249 2.7556 19.0577 2.13209 15.2462C1.31751 10.1878 4.6764 5.72266 9.31247 4.77734"
          stroke="white"
          stroke-width="1.50848"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    component: Analytics,
    noCollapse: true,
    showInNav: true,
    landing: false,
  },

  {
    name: "Add Gallery",
    key: "addGallery",
    route: "/addGallery",
    component: AddGallery,
    noCollapse: true,
    landing: false,
    showInNav: false,
  },

  {
    type: "collapse",
    name: "Gallery",
    key: "gallery",
    route: "/gallery",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="3" width="18" height="18" rx="2"/><circle cx="8.5" cy="8.5" r="1.5"/><path d="M20.4 14.5L16 10 4 20"/></svg>
    ),
    component: Gallery,
    noCollapse: true,
    showInNav: true,
    landing: false,
  },

  // {
  //   type: "collapse",
  //   name: "Insights",
  //   key: "Insights",
  //   route: "/dashboard",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="25"
  //       height="25"
  //       viewBox="0 0 25 25"
  //       fill="none"
  //     >
  //       <path
  //         d="M9.42973 22.9648H15.4637C20.4919 22.9648 22.5033 20.9535 22.5033 15.9252V9.89128C22.5033 4.863 20.4919 2.85168 15.4637 2.85168H9.42973C4.40145 2.85168 2.39014 4.863 2.39014 9.89128V15.9252C2.39014 20.9535 4.40145 22.9648 9.42973 22.9648Z"
  //         stroke="white"
  //         stroke-width="1.50848"
  //         stroke-linecap="round"
  //         stroke-linejoin="round"
  //       />
  //       <path
  //         d="M15.9666 19.4449C17.0729 19.4449 17.9779 18.5398 17.9779 17.4336V8.38271C17.9779 7.27649 17.0729 6.3714 15.9666 6.3714C14.8604 6.3714 13.9553 7.27649 13.9553 8.38271V17.4336C13.9553 18.5398 14.8504 19.4449 15.9666 19.4449Z"
  //         stroke="white"
  //         stroke-width="1.50848"
  //         stroke-linecap="round"
  //         stroke-linejoin="round"
  //       />
  //       <path
  //         d="M8.92708 19.445C10.0333 19.445 10.9384 18.5399 10.9384 17.4337V13.9139C10.9384 12.8077 10.0333 11.9026 8.92708 11.9026C7.82086 11.9026 6.91577 12.8077 6.91577 13.9139V17.4337C6.91577 18.5399 7.81081 19.445 8.92708 19.445Z"
  //         stroke="white"
  //         stroke-width="1.50848"
  //         stroke-linecap="round"
  //         stroke-linejoin="round"
  //       />
  //     </svg>
  //   ),
  //   component: Dashboard,
  //   noCollapse: true,
  //   showInNav: true,
  //   landing: false,
  // },



  {
    type: "collapse",
    name: "Create Offer",
    key: "create-offer",
    route: "/create-offer",   
    component: CreateOffer,
    noCollapse: true,
    showInNav: false,
    landing: false,
  },

  {
    type: "collapse",
    name: "Update Offer",
    key: "update-offer",
    route: "/update-offer",   
    component: UpdateOffer,
    noCollapse: true,
    showInNav: false,
    landing: false,
  },

  {
    type: "collapse",
    name: "Update Special Offer",
    key: "update-specialOffer",
    route: "/update-specialOffer",   
    component: UpdateSpecialOffer,
    noCollapse: true,
    showInNav: false,
    landing: false, 
  },



  {
    type: "collapse",
    name: "Create Special Offer",
    key: "create-specialOffer",
    route: "/create-specialOffer",   
    component: CreateSpecialOffer,
    noCollapse: true,
    showInNav: false,
    landing: false, 
  },

  

  {
    type: "collapse",
    name: "Back to Business Ac",
    key: "dashboard",
    route: "/dashboard",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M2.39014 2.30377V19.3999C2.39014 21.0693 3.73772 22.4169 5.4071 22.4169H22.5033"
          stroke="white"
          stroke-width="1.50848"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.40723 17.3887L10.0232 11.9984C10.7875 11.1134 12.1451 11.053 12.9698 11.8877L13.9251 12.8431C14.7498 13.6677 16.1074 13.6174 16.8717 12.7325L21.4977 7.33209"
          stroke="white"
          stroke-width="1.50848"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    component: Dashboard,
    noCollapse: true,
    showInNav: true,
    landing: false,
  },

  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: "/reports",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M2.39014 2.30377V19.3999C2.39014 21.0693 3.73772 22.4169 5.4071 22.4169H22.5033"
          stroke="white"
          stroke-width="1.50848"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.40723 17.3887L10.0232 11.9984C10.7875 11.1134 12.1451 11.053 12.9698 11.8877L13.9251 12.8431C14.7498 13.6677 16.1074 13.6174 16.8717 12.7325L21.4977 7.33209"
          stroke="white"
          stroke-width="1.50848"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    component: Reports,
    noCollapse: true,
    showInNav: false,
    landing: false,
  },

  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: "/reports",
    component: Venue,
    noCollapse: true,
    landing: false,
    showInNav: false,
  },

  {
    name: "Create Venue",
    key: "createVenue",
    route: "/createVenue",
    component: VenueCreate,
    noCollapse: true,
    landing: true,
    showInNav: false,
  },
  {
    name: "Update Venue",
    key: "updateVenue",
    route: "/updateVenue/:_id",
    component: VenueUpdate,
    noCollapse: true,
    landing: false,
    showInNav: false,
  },

  


];

export default routes;
